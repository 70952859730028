import React from 'react';

import { getTimezoneDate, formatDateTime, formatShortAddress } from '../../../../utilities/helpers'

import Stack from 'react-bootstrap/Stack';

export default function Event({ event, timezone, children }) {
    return (
        <Stack direction="horizontal" gap={3} className='align-items-center justify-content-between flex-grow-1'>
            <img src={event?.image?.url} width={64} height={64} alt={`Cover art for ${event?.name} event`} className='event-image flex-shrink-0' />
            <Stack className='justify-content-center'>
                <span className="normal normal-bold">{event?.name}</span>
                <span className="small text-muted">
                    <span>{formatDateTime(getTimezoneDate(event?.start, timezone))}</span>
                    <span className='venue'>{event?.venue?.name}</span>
                    <span className='loc'>{formatShortAddress(event?.venue)}</span>
                </span>
            </Stack>
            {children}
        </Stack>
    );
}
