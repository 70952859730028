import React from "react";

import {
    formatDateTime,
    formatAddress,
    formatCurrency,
    getTimezoneDate,
    sortAttractions,
} from "../../../../utilities/helpers";

import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";

import { WarningCard } from "../../../../components/WarningCard";

import placeholder from "../../../../assets/placeholder.png";

export default function Info({ obj, start, isScheduled, isEvent }) {
    return (
        <Stack gap={3}>
            <div className=''>
                <h2 className='vjx-pm-subheading'>{isEvent ? 'Event' : 'Package'} information</h2>
                <small className='vjx-pm-paragraph'>
                    Review your {isEvent ? 'event' : 'package'} information before publishing the {isEvent ? 'event' : 'package'}
                </small>
            </div>
            <Image
                src={obj?.image?.url || placeholder}
                rounded
                alt={`Cover art for ${obj?.name} ${isEvent ? 'event' : 'package'}`}
                width='160'
                height='160'
                className='event-image'
            />
            <Stack as='ul' gap={3} className='mt-3 small'>
                <li>
                    <p className='vjx-pm-subheading-two'>{isEvent ? 'Event' : 'Package'} date</p>
                    <span className='vjx-pm-paragraph'>
                        <span>
                            {formatDateTime(
                                getTimezoneDate(start, obj?.timezone),
                                "dateOnly"
                            )}
                        </span>
                        <span className='time'>
                            {formatDateTime(
                                getTimezoneDate(start, obj?.timezone),
                                "timeOnly"
                            )}
                        </span>
                    </span>
                </li>
                <li>
                    <p className='vjx-pm-subheading-two'>Venue</p>
                    <span className='vjx-pm-paragraph'>
                        <span>
                            {obj?.venue?.name}
                        </span>
                        <span className='loc'>{formatAddress(obj?.venue)}</span>
                    </span>
                </li>
                {isEvent ? (
                    <>
                        <li>
                            <p className='vjx-pm-subheading-two'>Attractions</p>
                            <Stack as='ul' className='vjx-pm-paragraph'>
                                {sortAttractions(obj?.attractions)?.map((attraction, idx) => (
                                    <li key={idx}>{attraction.name}</li>
                                ))}
                            </Stack>
                        </li>
                        {obj?.pricingLevels && (
                            <li>
                                <p className='vjx-pm-subheading-two'>Base price</p>
                                <span className='vjx-pm-paragraph'>
                                    {formatCurrency(
                                        Math.min(
                                            ...Object.values(obj?.pricingLevels)?.map((pl) => pl.price)
                                        )
                                    )}
                                    <span className='to'>
                                        {formatCurrency(
                                            Math.max(
                                                ...Object.values(obj?.pricingLevels)?.map(
                                                    (pl) => pl.price
                                                )
                                            )
                                        )}
                                    </span>
                                </span>
                            </li>
                        )}
                        <li>
                            <p className='vjx-pm-subheading-two'>Event ticket limit</p>
                            <span className='vjx-pm-paragraph'>
                                Limit:{" "}
                                <span className='text-muted'>
                                    {obj?.globalTicketLimit || "None"}
                                </span>
                            </span>
                        </li>
                    </>
                ) : (
                    <li>
                        <p className='vjx-pm-subheading-two'>Price</p>
                        <span className='vjx-pm-paragraph'>
                            Price:{" "}
                            <span className='text-muted'>
                                {formatCurrency(obj?.price)}
                            </span>
                        </span>
                    </li>
                )}
            </Stack>
            {!isScheduled && (
                <WarningCard text={'Warning: Publishing is permanent and cannot be undone. Please review all details carefully'} variant="pale" marginValue="0" />
            )}
        </Stack>
    );
}
