import { useEffect, useState, useRef } from "react";
import { Stage, Rect, Layer } from "react-konva";
import useImage from "use-image";

import { useActions } from "../ActionsProvider";
import { Sections } from "./Sections";
import { Seats } from "./Seats";

export default function SeatMap({
    data,
    isMapDisabled,
    selectRegularSeats,
    displayInventoryInfo,
    background,
    handleSectionSelect,
    deriveInventoryInfoFrom,
    getSectionState,
    getSeatState
}) {
    const {
        stageRef,
        setScale,
        activeMapAction,
        calculateScalePercentage,
        originalScaleRef,
        SCALE_FACTOR,
        SCALE_LIMITS,
        setLoading
    } = useActions();
    const [image, status] = useImage(background?.url);

    const originalWidth = background?.width; // Original content width
    const originalHeight = background?.height; // Original content height
    const aspectRatio = originalWidth / originalHeight;

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const resizeStage = () => {
            const map = document.getElementById("map");
            const containerWidth = map.clientWidth;
            const containerHeight = map.clientHeight;
            const maxByWidth = containerWidth;
            const maxByHeight = containerHeight * aspectRatio; // because we divide by height we multiply here

            let newWidth, newHeight;
            if (maxByWidth < maxByHeight) {
                // If width is the limiting factor
                newWidth = containerWidth;
                newHeight = containerWidth / aspectRatio;
            } else {
                // If height is the limiting factor or if they are equal
                newWidth = containerHeight * aspectRatio;
                newHeight = containerHeight;
            }

            const scaleX = newWidth / originalWidth;
            const scaleY = newHeight / originalHeight;
            const scale = Math.min(scaleX, scaleY) * 0.8;
            originalScaleRef.current = scale;

            const stage = stageRef.current;
            stage.setAttrs({
                scaleX: scale,
                scaleY: scale,
            });

            setWidth(containerWidth);
            setHeight(containerHeight);
            setScale(scale);

            const adjustedOffsetX = (originalWidth - containerWidth / scale) / 2;
            const adjustedOffsetY = (originalHeight - containerHeight / scale) / 2;
            setOffset({ x: adjustedOffsetX, y: adjustedOffsetY });

            stage.batchDraw();
        };

        resizeStage();
        window.addEventListener("resize", resizeStage);

        return () => window.removeEventListener("resize", resizeStage);
    }, []);

    // Less expensive to redraw canvas is we're not listening
    const [isInteracting, setIsInteracting] = useState(false);
    const interactionTimeout = useRef(null);
    const wheelTimeout = useRef(null);
    const imageLayerRef = useRef(null);

    // Reset interaction state after delay
    const resetInteractionState = () => {
        if (interactionTimeout.current) {
            clearTimeout(interactionTimeout.current);
        }

        interactionTimeout.current = setTimeout(() => {
            setIsInteracting(false);
        }, 150); // Adjust delay as needed
    };

    const THROTTLE_INTERVAL = 32; // ms - roughly one frame
    const MAX_WHEEL_DELTA = 100; // Prevent extreme zoom jumps

    // Add these to your component state/refs
    const accumulatedDelta = useRef(0);
    const lastWheelTime = useRef(0);
    const isZooming = useRef(false);

    const handleWheel = (e) => {
        e.evt.preventDefault();
        setIsInteracting(true);

        // Accumulate the delta, but cap it to prevent extreme values
        accumulatedDelta.current += Math.min(
            Math.max(e.evt.deltaY, -MAX_WHEEL_DELTA),
            MAX_WHEEL_DELTA
        );

        const now = performance.now();

        // If we're not already processing a frame and enough time has passed
        if (
            !isZooming.current &&
            now - lastWheelTime.current >= THROTTLE_INTERVAL
        ) {
            isZooming.current = true;

            wheelTimeout.current = requestAnimationFrame(() => {
                const stage = stageRef.current;
                const oldScale = stage.scaleX();
                const pointer = stage.getPointerPosition();

                // Use the accumulated delta for direction
                const direction = accumulatedDelta.current > 0 ? -1 : 1;

                // Scale the zoom factor based on accumulated delta magnitude
                const zoomIntensity = Math.min(
                    Math.abs(accumulatedDelta.current) / 50,
                    1
                );
                const scaleBy = 1 + (SCALE_FACTOR - 1) * zoomIntensity;

                const mousePointTo = {
                    x: (pointer.x - stage.x()) / oldScale,
                    y: (pointer.y - stage.y()) / oldScale,
                };

                let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                // Cap scale
                const wouldBeScale = calculateScalePercentage(newScale);
                if (
                    wouldBeScale > SCALE_LIMITS.MAX ||
                    wouldBeScale < SCALE_LIMITS.MIN
                ) {
                    wheelTimeout.current = null;
                    isZooming.current = false;
                    accumulatedDelta.current = 0;
                    resetInteractionState();
                    return false;
                }

                const newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale,
                };

                stage.setAttrs({
                    scaleX: newScale,
                    scaleY: newScale,
                    x: newPos.x,
                    y: newPos.y,
                });

                stage.batchDraw();
                setScale(newScale);

                // Reset for next frame
                wheelTimeout.current = null;
                isZooming.current = false;
                lastWheelTime.current = now;
                accumulatedDelta.current = 0;

                resetInteractionState();
                return true;
            });
        }
    };

    // Handle drag start
    const handleDragStart = () => {
        setIsInteracting(true);
    };

    // Handle drag end
    const handleDragEnd = () => {
        resetInteractionState();
    };

    // Clean up function for your useEffect
    const cleanup = () => {
        if (wheelTimeout.current) {
            cancelAnimationFrame(wheelTimeout.current);
        }
        accumulatedDelta.current = 0;
        isZooming.current = false;
        lastWheelTime.current = 0;
    };

    // Cleanup
    useEffect(() => {
        return () => {
            cleanup();
        };
    }, []);

    // Cache the image so the canvas does not need to redraw this
    // Major improvement in time spent painting
    useEffect(() => {
        if (imageLayerRef.current && status === "loaded") {
            // Cache the entire layer
            imageLayerRef.current.cache({ pixelRatio: 0.5 });
            // If you need to update the cache after any changes
            imageLayerRef.current.batchDraw();
            setLoading(false);
        }
    }, [image, originalWidth, originalHeight, status]);

    // Possible perf imporovements:
    // 2. Subscribe to mini state manager for state updates on children
    // 3. Remove shapes that are not visible
    // 4. Cache GA sections? as there are fewer of them + they are more complex
    //

    return (
        <Stage
            listening={!isInteracting}
            draggable
            ref={stageRef}
            width={width}
            height={height}
            onWheel={handleWheel}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            offset={{ x: offset.x, y: offset.y }}
        >
            {/* Background image */}
            <Layer ref={imageLayerRef} listening={false}>
                <Rect
                    width={originalWidth}
                    height={originalHeight}
                    fillPatternImage={image}
                    fillPatternRepeat='no-repeat'
                    listening={false}
                />
            </Layer>
            <Seats
                data={data}
                isDisabled={isMapDisabled}
                selectRegularSeats={selectRegularSeats}
                activeMapAction={activeMapAction}
                getSeatState={getSeatState}
            />
            <Sections
                data={data}
                isDisabled={isMapDisabled}
                displayInventoryInfo={displayInventoryInfo}
                activeMapAction={activeMapAction}
                getSectionState={getSectionState}
                handleSectionSelect={handleSectionSelect}
                deriveInventoryInfoFrom={deriveInventoryInfoFrom}
            />
        </Stage>
    );
}
