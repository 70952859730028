import "./publishingDisclaimer.scss"


export default function PublishingDisclaimer({ isEventPublishing }) {

    return (
        <div className="publishingDisclaimer">
            <p>Cannot update map {isEventPublishing ? 'while event is publishing!' : 'at the moment!'}</p>
        </div>
    )
}