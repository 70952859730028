import React, { useEffect, useState } from 'react';

import { formatCurrency } from '../../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function SpecialtyOffer({ offer, status, isStandard, getPrice, handleClick }) {

    const [minPrice, setMinPrice] = useState(0)

    const [maxPrice, setMaxPrice] = useState(0)

    useEffect(() => {
        setMinPrice(Math.min(...Object.values(offer?.am_pricing_objects)?.map((_, idx, levels) => getPrice(levels, idx, offer?.pricing_option, offer?.discount))))
        setMaxPrice(Math.max(...Object.values(offer?.am_pricing_objects)?.map((_, idx, levels) => getPrice(levels, idx, offer?.pricing_option, offer?.discount))))
    }, [offer])

    return (
        <li className='specialty-offer'>
            <Button
                variant='default'
                className='list-item list-item-lg list-item--light btn-list-view btn-list-view-with-border btn-list-view-with-bg offer-row'
                onClick={(e) => handleClick(e, offer?.id, { ...offer, status: status?.type })}
            >
                <Stack direction='horizontal' gap={2} className='col-7'>
                    <h2 className='heading mb-0'>{offer?.name}</h2>
                </Stack>
                <Stack className='col justify-content-center'>
                    <span className='normal normal-bold'>{isStandard ? 'Base' : 'Offset'} Price: {formatCurrency(minPrice)}<span className="to">{formatCurrency(maxPrice)}</span></span>
                </Stack>
            </Button>
        </li>
    );
}