import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import isEqual from 'lodash/isEqual'

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { scrollToTop, timezones } from '../../utilities/helpers';
import { createEvent, getEvent, editEvent, getOrgVenues } from '../../utilities/api';

import { checkPermission, isEventPast, getFormattedTimezoneDate, getTimezoneDate } from '../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { BasicInfo } from './BasicInfo';
import { DateTime } from './DateTime';
import { EventDetails } from './EventDetails';
import { CreateEventButtons } from '../CreateEventButtons';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import useSafeAsync from '../../utilities/useSafeAsync';

export default function EventDetailsWrapper({ eventId }) {
    const safeSetState = useSafeAsync();
    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { isEventOnsale, storeEventStart, storeEventVisibility, storeGeneralOnsale, updateCanPublish, updateIsEventPublishing, updateIsEventPublished, updateIsEventOnsale, updateHasEventEnded, updateIsEventSoldout, updateEvent, isEventPublished, isEventSoldout } = useContext(EventDetailsContext)

    const organization = AuthService.getOrg()[0];

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [initialState, setInitialState] = useState();

    const [showFooter, setShowFooter] = useState(false)

    const [eventStart, setEventStart] = useState(new Date(moment('7:00 pm', 'h:mm a').format()));

    const [doorsOpen, setDoorsOpen] = useState(new Date(moment('5:00 pm', 'h:mm a').format()));

    // doors open offset
    const [doorsOpenOffset, setDoorsOpenOffset] = useState({
        time: '2',
        unit: 'hour'
    });

    const [eventVisibility, setEventVisibility] = useState(null);

    const [generalOnsale, setGeneralOnsale] = useState(null);

    const [generalOnsaleEnd, setGeneralOnsaleEnd] = useState(new Date(moment('10:00 pm', 'h:mm a').format()));

    const [venues, setVenues] = useState()

    const [event, setEvent] = useState({
        name: '',
        room: { uuid: '', timezone: '' },
        display_start_time: true
    })

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [isSaving, setIsSaving] = useState(false)

    const [hasRequiredFieldError, setHasRequiredFieldError] = useState(false)

    // required fields error status
    const [requiredFieldErrorStatus, setRequiredFieldErrorStatus] = useState({})

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));
    }, [orgPermissions])

    useEffect(() => {
        showLoading()
        getOrgVenues()
            .then((res) => {
                console.log("getOrgVenues: ", res)
                safeSetState(() => {
                    setVenues(res.data);
                })
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })

        if (!eventId) {
            safeSetState(() => {
                // save initial state to check whether to show save buttons
                saveInitialState(event, eventStart, doorsOpen, eventVisibility, generalOnsale)

                hideLoading()

                return
            })
        };

        getEvent(eventId)
            .then((res) => {
                safeSetState(() => {
                    setEvent(res?.data)
                    console.log('first render event', res?.data);
                    updateEvent(res?.data)
                    updateIsEventOnsale()
                    updateHasEventEnded(res.data)
                    updateIsEventSoldout(res.data)
                    updateIsEventPublishing(res?.data)
                    updateIsEventPublished()
                    setEventStart(new Date(getFormattedTimezoneDate(res?.data?.start, res?.data?.timezone)))
                    setDoorsOpen(new Date(getFormattedTimezoneDate(res?.data?.doorsOpen, res?.data?.timezone)))
                    setDoorsOpenOffset(getDoorsOpenOffset(res?.data?.start, res?.data?.doorsOpen))
                    // get event visibility  
                    const eventVisibility = res?.data?.eventVisibility
                    setEventVisibility(eventVisibility ? new Date(getFormattedTimezoneDate(eventVisibility, res?.data?.timezone)) : null)
                    const generalOnsale = res?.data?.generalOnsale;
                    setGeneralOnsale(generalOnsale ? new Date(getFormattedTimezoneDate(generalOnsale, res?.data?.timezone)) : null)

                    // save initial state again if editing to check whether to show save buttons
                    saveInitialState(
                        res?.data,
                        new Date(getFormattedTimezoneDate(res?.data?.start, res?.data?.timezone)),
                        new Date(getFormattedTimezoneDate(res?.data?.doorsOpen, res?.data?.timezone)),
                        eventVisibility ? new Date(getFormattedTimezoneDate(eventVisibility, res?.data?.timezone)) : null,
                        generalOnsale ? new Date(getFormattedTimezoneDate(generalOnsale, res?.data?.timezone)) : null
                    )
                    hideLoading()
                })
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [])

    // set event visibility to match event visibility in context
    // when changing event visibility in context
    // useEffect(() => {
    //     // only when editing
    //     if (eventId) {
    //         // if there is event visibility set in context 
    //         if (eventVisibilityContext && event?.timezone) {
    //             // if there is no event visibility but is set in context (when publishing event), update event visibility 
    //             // if there is event visibility and event visibility in context but they do not match, update event visibility 
    //             if ((((!eventVisibility && eventVisibilityContext) || (eventVisibility && eventVisibilityContext)) && !isEqual(new Date(eventVisibilityContext), new Date(eventVisibility)))) {
    //                 console.log('update local visibility from context', !isEqual(new Date(eventVisibilityContext), new Date(eventVisibility)));
    //                 console.log('context visibility', new Date(eventVisibilityContext));
    //                 console.log('local visibility', new Date(eventVisibility));
    //                 const updatedEventVisibility = new Date(getFormattedTimezoneDate(eventVisibilityContext, event?.timezone))
    //                 setEventVisibility(updatedEventVisibility)
    //                 setInitialState(
    //                     initialState?.event,
    //                     initialState?.eventStart,
    //                     initialState?.doorsOpen,
    //                     updatedEventVisibility,
    //                     initialState?.generalOnsale
    //                 )

    //                 // updateInitialEventVisibility(updatedEventVisibility)
    //             }
    //         }
    //     }
    // }, [event, eventVisibilityContext])

    useEffect(() => {
        // make doors open the same as event start when event start changes 
        // create a moment object
        const doorsOpenDateObj = moment(doorsOpen)

        // do changes on that object
        doorsOpenDateObj.set('year', moment(eventStart).year());
        doorsOpenDateObj.set('month', moment(eventStart).month());
        doorsOpenDateObj.set('date', moment(eventStart).date());
        setDoorsOpen(new Date(doorsOpenDateObj))

        // get new doors open time based on doors open offset
        const eventDoorsOpenTime = moment(eventStart).subtract(parseFloat(doorsOpenOffset.time), doorsOpenOffset.unit)
        setDoorsOpen(new Date(eventDoorsOpenTime))
    }, [eventStart, doorsOpenOffset])

    useEffect(() => {
        // set sales end date to 4 hours after event start date
        setGeneralOnsaleEnd(new Date(moment(eventStart).add(4, 'h')))
    }, [eventStart])

    // show/hide footer using lodash
    useEffect(() => {
        // close all alerts when fields are edited except for required fields alert 
        if (alert.show && !hasRequiredFieldError) {
            closeAlert()
        }

        setShowFooter((!isEqual(initialState?.event, event)) || !isEqual(new Date(initialState?.eventStart), new Date(eventStart)) || !isEqual(new Date(initialState?.doorsOpen), new Date(doorsOpen)) || !isEqual(new Date(initialState?.eventVisibility), new Date(eventVisibility)) || !isEqual(new Date(initialState?.generalOnsale), new Date(generalOnsale)))

    }, [event, initialState, event.name, event.venue, event.display_start_time, eventStart, doorsOpen, eventVisibility, generalOnsale])

    useEffect(() => {
        if (hasRequiredFieldError) {
            updateRequiredFieldErrorStatus()
        }
    }, [event?.name, event?.room?.uuid, generalOnsale])

    useEffect(() => {
        if (hasRequiredFieldError) {
            // if all fields are filled in: dismiss alert 
            if (Object.keys(requiredFieldErrorStatus).every(key => !requiredFieldErrorStatus[key])) {
                closeAlert()
                setHasRequiredFieldError(false)
                setRequiredFieldErrorStatus({})
            }
        }
    }, [hasRequiredFieldError, requiredFieldErrorStatus])

    const updateRequiredFieldErrorStatus = () => {
        setRequiredFieldErrorStatus({
            'name': !event?.name,
            'venue': !event?.room?.uuid,
            'generalOnsale': !generalOnsale
        })
    }

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    const handleChange = (e, val = e.target.value) => {
        setEvent({ ...event, [e.target.name]: val })
    }

    const handleVenue = (e) => {
        const uuid = e.target.value
        const timezone = venues?.find(venue => venue.rooms.find(room => room.uuid === uuid))?.timezone
        setEvent({ ...event, room: { ...event.room, timezone, uuid: e.target.value } })
    }

    const handleSave = (e) => {
        e.preventDefault();
        // if error, don't save 
        if (hasRequiredFieldError) {
            scrollToTop()
            return
        }
        const hasError = !event?.name || !event?.room.uuid || !generalOnsale
        if (hasError) {
            updateRequiredFieldErrorStatus()
            setHasRequiredFieldError(hasError)
            scrollToTop()
            setAlert({
                show: true,
                variant: 'danger',
                message: 'You are missing required subject fields. Please fill out all required fields before continuing.'
            })
            return
        }
        setIsSaving(true)

        const timezone = event?.timezone || timezones(event?.room?.timezone)
        // create event 
        const data = {};
        data['name'] = event.name;
        data['start'] = getTimezoneDate(eventStart, timezone, true).format();
        data['end'] = getTimezoneDate(generalOnsaleEnd, timezone, true).format();
        data['venue'] = event.room.uuid;
        data['status'] = 'unpublished';
        data['currency'] = 'usd';
        data['online_event'] = false;
        data['organizationId'] = organization?.id;
        data['doorsOpen'] = getTimezoneDate(doorsOpen, timezone, true).format();
        data['display_start_time'] = event.display_start_time;
        data['eventVisibility'] = eventVisibility ? getTimezoneDate(eventVisibility, timezone, true).format() : null
        data['generalOnsale'] = getTimezoneDate(generalOnsale, timezone, true).format();
        if (eventId) {
            data['uuid'] = eventId;
            editEvent({ data })
                .then((res) => {
                    setIsSaving(false)
                    scrollToTop()
                    // save initial state again if editing to check whether to show save buttons
                    saveInitialState(event, eventStart, doorsOpen, eventVisibility, generalOnsale)

                    // update context with updated event details 
                    updateEvent(res?.data)
                    updateCanPublish()
                    storeEventStart(res?.data?.start)
                    storeEventVisibility(res?.data?.eventVisibility)
                    storeGeneralOnsale(res?.data?.generalOnsale)
                    updateIsEventOnsale(res?.data)
                    updateHasEventEnded(res?.data)
                    updateIsEventSoldout(res?.data)
                    updateIsEventPublishing(res?.data)
                    updateIsEventPublished()

                    setAlert({
                        show: true,
                        variant: 'success',
                        message: 'Your info has been updated.'
                    })
                })
                .catch((err) => {
                    console.error(err)
                    scrollToTop()
                    setIsSaving(false)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info. Please try again.'
                    })
                })

        } else {
            createEvent({ data })
                .then((res) => {
                    // store state in context 
                    updateEvent(res?.data)
                    storeEventStart(eventStart)
                    storeEventVisibility(eventVisibility)
                    storeGeneralOnsale(generalOnsale)

                    // do these last...
                    setIsSaving(false)
                    navigate(`/myevent/${res.data?.uuid}/event-settings`)
                })
                .catch((err) => {
                    scrollToTop()
                    console.error(err)
                    setIsSaving(false)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info. Please try again.'
                    })
                })
        }
    }

    const saveInitialState = (event, eventStart, doorsOpen, eventVisibility, generalOnsale) => {
        // save initial state to check whether to show save buttons
        setInitialState({
            event,
            eventStart,
            doorsOpen,
            eventVisibility,
            generalOnsale
        })
    }

    const getDoorsOpenOffset = (eventStart, doorsOpen) => {
        const duration = moment.duration(moment(eventStart).diff(moment(doorsOpen)));
        const hours = duration.asHours();
        const minutes = duration.asMinutes();

        if (hours >= 1) {
            return { time: hours, unit: 'hour' }
        }
        else {
            return { time: minutes, unit: 'minute' }
        }
    }

    const isEditable = () => {
        // new event
        if (!eventId) return false

        // if event is past 
        else return isEventPast(event)
    }

    const handleGoBack = () => {
        return navigate(`/`)
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper event-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Event Details</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card body className='card--md'>
                                <BasicInfo
                                    isEditable={isEditable()}
                                    isEventPublished={isEventPublished}
                                    handleChange={handleChange}
                                    handleVenue={handleVenue}
                                    event={event}
                                    venues={venues}
                                    requiredFieldErrorStatus={requiredFieldErrorStatus}
                                />
                            </Card>
                            <Card body className='card--md'>
                                <DateTime
                                    event={event}
                                    timezone={event?.timezone || timezones(event?.room?.timezone)}
                                    isEditable={isEditable()}
                                    handleChange={handleChange}
                                    setEventStart={setEventStart}
                                    eventStart={eventStart}
                                    setDoorsOpen={setDoorsOpen}
                                    doorsOpen={doorsOpen}
                                />
                            </Card>
                            <Card body className='card--md'>
                                <EventDetails
                                    event={event}
                                    timezone={event?.timezone || timezones(event?.room?.timezone)}
                                    isEdit={eventId ? true : false}
                                    isEditable={isEditable()}
                                    isEventPublished={isEventPublished}
                                    isEventOnsale={isEventOnsale}
                                    isEventSoldout={isEventSoldout}
                                    eventStart={eventStart}
                                    setDoorsOpen={setDoorsOpen}
                                    doorsOpenOffset={doorsOpenOffset}
                                    setDoorsOpenOffset={setDoorsOpenOffset}
                                    eventVisibility={eventVisibility}
                                    setEventVisibility={setEventVisibility}
                                    generalOnsale={generalOnsale}
                                    setGeneralOnsale={setGeneralOnsale}
                                    generalOnsaleEnd={generalOnsaleEnd}
                                    requiredFieldErrorStatus={requiredFieldErrorStatus}
                                />
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        <CreateEventButtons
                            isEditing={eventId}
                            isSaving={isSaving}
                            showGoBack={!eventId}
                            styles={`${!eventId ? 'without-sidebar' : ' '} ${!hasPermission ? 'overlay' : ''} `}
                            handleSave={handleSave}
                            handleGoBack={handleGoBack}
                        />
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
        </>
    );
}
