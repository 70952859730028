import "./zoomLevel.scss";
import { useRef, useCallback } from "react";

export default function ZoomLevel({
  setScale,
  scale,
  calculateScalePercentage,
  stageRef,
  scaleFactor,
  scaleLimits
}) {
  const isAnimating = useRef(false);
  const lastClickTime = useRef(0);
  const CLICK_DELAY = 150; // ms between allowed clicks

  const handleZoom = useCallback(
    (zoomIn) => {
      const now = performance.now();

      // Prevent rapid clicks and concurrent animations
      if (isAnimating.current || now - lastClickTime.current < CLICK_DELAY) {
        return;
      }

      const stage = stageRef.current;
      if (!stage) return;

      const oldScale = stage.scaleX();
      const dynamicScaleFactor = zoomIn ? scaleFactor : 1 / scaleFactor;

      // Calculate new scale
      let newScale = oldScale * dynamicScaleFactor;

      // Check scale limits
      const wouldBeScale = calculateScalePercentage(newScale);
      if (wouldBeScale > scaleLimits.MAX || wouldBeScale < scaleLimits.MIN) {
        return;
      }

      // Cache viewport dimensions
      const stageWidth = stage.width();
      const stageHeight = stage.height();
      const centerX = stageWidth / 2;
      const centerY = stageHeight / 2;

      // Calculate center point at current scale
      const centerOnStageX = (centerX - stage.x()) / oldScale;
      const centerOnStageY = (centerY - stage.y()) / oldScale;

      // Calculate new position
      const newPos = {
        x: -(centerOnStageX * newScale - centerX),
        y: -(centerOnStageY * newScale - centerY),
      };

      // Set animating flag
      isAnimating.current = true;
      lastClickTime.current = now;

      // Perform animation
      stage.to({
        scaleX: newScale,
        scaleY: newScale,
        x: newPos.x,
        y: newPos.y,
        duration: 0.15,
        onFinish: () => {
          isAnimating.current = false;
          stage.batchDraw();
          setScale(newScale);
        },
      });
    },
    [setScale, calculateScalePercentage]
  );

  return (
    <div className='zoomContainer'>
      <button
        className='zoomButton'
        onClick={() => handleZoom(false)}
        disabled={isAnimating.current}
      >
        -
      </button>
      <div>{calculateScalePercentage(scale)}%</div>
      <button
        className='zoomButton'
        onClick={() => handleZoom(true)}
        disabled={isAnimating.current}
      >
        +
      </button>
    </div>
  );
}
