import React from 'react';

import { formatAddress, capitalizeString } from '../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { WarningCard } from '../../../components/WarningCard';
import { Spinner } from '../../../components/LoadingContainer/Spinner';

export default function CreatePackageModal({ show, venues, room, seatmapId, handleVenue, handleConfiguration, handleClose, handleNext, isSaving }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static">
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className='modal-body-heading-title'>Create Package</h1>
                </div>
                <Form.Group className="form-group" controlId="venue">
                    <div className="form-label--flex">
                        <Form.Label>Venue</Form.Label>
                    </div>
                    <Form.Select
                        name="venue"
                        onChange={handleVenue}
                        value={room?.uuid || ""}
                        autoFocus
                        required
                    >
                        <option disabled hidden value="">Select venue</option>
                        {venues && venues.map((option, index) => (
                            <optgroup label={`${option?.name} - ${formatAddress(option)}`} key={index}>
                                {option.rooms?.map((room, i) => (
                                    <option value={room.uuid} data-venue={option} key={i}>{capitalizeString(room.name)}</option>
                                ))}
                            </optgroup>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="form-group" controlId="configuration">
                    <div className="form-label--flex">
                        <Form.Label>Configuration</Form.Label>
                    </div>
                    <Form.Select
                        name="configuration"
                        value={seatmapId || ""}
                        onChange={handleConfiguration}
                        required
                    >
                        <option hidden value="">Select configuration</option>
                        {room?.seatmaps && room?.seatmaps.map((option, index) => (
                            <option value={option.id} key={index}>{capitalizeString(option.name)}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <WarningCard text="Events must share the same configurations to be assigned to a package" variant="primary" margin="mt" />
                <Stack direction="horizontal" className='justify-content-between btn-group-flex'>
                    <Button variant="default" size="lg" onClick={handleClose}>Cancel</Button>
                    <Button size="lg" className={`btn-width btn-width-xxl ${!isSaving ? "btn-next" : ""}`} disabled={!seatmapId || isSaving} onClick={handleNext}>
                        {isSaving ? (
                            <Spinner />
                        ) : 'Save and continue'
                        }
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
