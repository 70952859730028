import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function ConfirmLeaveModal({ show, handleGoBack, handleClose }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static">
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Are you sure you want leave?</h1>
                    <p>You have unsaved changes in this offer. Any progress you've made will be lost. Are you sure you want to proceed without saving?</p>
                </div>
                <Stack className="btn-group-flex">
                    <Button size="lg" onClick={handleGoBack}>
                        Leave without saving
                    </Button>
                    <Button size="lg" variant="outline-light" onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
