import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';

import LoadingContext from '../../context/Loading/Loading';

import { createGuestList, getEventTicketTypes } from '../../utilities/api';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { GuestInfo } from './GuestInfo';
import { Spinner } from '../LoadingContainer/Spinner'
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function GuestInformationWrapper({ eventId }) {

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [ticketTypes, setTicketType] = useState()

    const [valid, setValid] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    // demo purposes - will come from database - display error is ticket quantity is more than event max ticket quantity 
    const maxTicketQuantity = 50;

    const [guest, setGuest] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        quantity: '',
        ticketType: ''
    })

    const [
        phoneNumber,
        setValue
    ] = useState();

    const [isQuantityValid, setIsQuantityValid] = useState(true)

    const [isValidNumber, setIsValidNumber] = useState(true)

    // save phone number to guest object every time it changes
    useEffect(() => {
        setGuest({ ...guest, phoneNumber })
    }, [phoneNumber])

    useEffect(() => {
        if (!isQuantityValid) {
            setIsQuantityValid(true)
        }
    }, [guest.quantity])

    useEffect(() => {
        if (!isValidNumber) {
            setIsValidNumber(true)
        }
    }, [phoneNumber])

    useEffect(() => {
        if (guest.phoneNumber && guest.firstName && guest.lastName && guest.quantity && guest.ticketType && isQuantityValid && isValidNumber) return setValid(true)
        setValid(false)
    }, [guest.firstName, guest.lastName, guest.quantity, guest.ticketType, guest.phoneNumber, isQuantityValid, isValidNumber])

    useEffect(() => {
        showLoading()
        getEventTicketTypes(eventId)
            .then((res) => {
                setTicketType(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [])

    useEffect(() => {

    }, [ticketTypes])

    const validNumber = () => {
        return phoneNumber && isValidPhoneNumber(phoneNumber)
    }

    const handleValidNumber = (e) => {
        // check if valid 
        setIsValidNumber(validNumber(phoneNumber))
    }

    const validQuantity = e => {
        if (e.target.value > maxTicketQuantity) {
            setIsQuantityValid(false)
        }
    }

    const handleChange = e => {
        if (e.target) setGuest({ ...guest, [e.target.name]: e.target.value })
    }

    const handleSave = () => {
        guest['quantity'] = Number(guest.quantity)
        guest['event'] = eventId;

        if (validNumber()) {
            setIsSaving(true)
            createGuestList(guest)
                .then(() => {
                    setIsSaving(false)

                    if (!isSaving) {
                        navigate(-1)
                    }
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                })
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='wrapper'>
                    <section>
                        <header className="section-header section-heading">
                            <h1>Guest information</h1>
                        </header>
                        <Card body>
                            <GuestInfo
                                ticketTypes={ticketTypes}
                                guest={guest}
                                handleChange={handleChange}
                                phoneNumber={phoneNumber}
                                setPhoneNumber={setValue}
                                handleValidNumber={handleValidNumber}
                                isValidPhoneNumber={isValidNumber}
                                isQuantityValid={isQuantityValid}
                                validQuantity={validQuantity}
                            />
                        </Card>
                    </section>
                    <Stack direction="horizontal" className="btn-group-flex">
                        <Button variant="outline-light" size="lg" onClick={() => navigate(-1)}>Cancel</Button>
                        <Button size="lg" className="btn-width-md" onClick={handleSave} disabled={!valid || isSaving}>
                            {isSaving ? (
                                <Spinner />
                            ) :
                                "Add Guest"
                            }
                        </Button>
                    </Stack>
                </section>
            )}
        </>
    );
}
