import React, { useMemo } from "react";

import {
  formatCurrency,
  formatNumber,
} from "../../../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";

export default function FinancialInfo({
  priceLevels,
  sumRevenues,
  sumCapacity,
}) {
    // Memoize expensive calculations
  const totalRevenue = useMemo(() => sumRevenues(priceLevels), [sumRevenues, priceLevels]);
  const totalCapacity = useMemo(() => sumCapacity(), [sumCapacity]);
  const avgPerSeat = useMemo(
    () => totalRevenue / totalCapacity,
    [totalRevenue, totalCapacity]
  );
  return (
    <>
      <Stack direction='horizontal'>
        <Stack as='ul' gap={2}>
          <p className='caption fw-normal'>Potential revenue</p>
          <li className='small small-bold'>
            {formatCurrency(totalRevenue)}
          </li>
        </Stack>
        <Stack as='ul' gap={2}>
          <p className='caption fw-normal'>Sellable capacity</p>
          <li className='small small-bold'>{formatNumber(totalCapacity)}</li>
        </Stack>
        <Stack as='ul' gap={2}>
          <p className='caption fw-normal'>Avg per seat</p>
          <li className='small small-bold'>
            {formatCurrency(avgPerSeat)}
          </li>
        </Stack>
      </Stack>
      <span className='text-muted caption'>Kills and holds are excluded</span>
    </>
  );
}
