import React, { useContext } from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import EventDetailsContext from '../../context/EventDetails/EventDetails';

import Stack from 'react-bootstrap/Stack';
import Dropdown from 'react-bootstrap/Dropdown';

import { MoreIcon } from '../MoreIcon';

export default function EditDeleteDropdown({ pending, canDelete = true, canEdit = true, hasPermission = true, onClick, handleShow, handleResend, children }) {

    const { hasEventEnded } = useContext(EventDetailsContext)

    return (
        <Dropdown className='btn-more' drop="start">
            <Dropdown.Toggle variant="default" id="btn-more-toggle" disabled={hasEventEnded}>
                <MoreIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Stack as="ul" gap={2}>
                    {pending && (
                        <li className='w-100'>
                            <Dropdown.Item as="button" className="icon-button-lg btn-send" onClick={handleResend}>Resend invitation</Dropdown.Item>
                        </li>
                    )}
                    {canEdit && (
                        <li className='w-100' >
                            <Dropdown.Item as="button" disabled={!hasPermission} className="icon-button-lg btn-edit" onClick={onClick}>Edit</Dropdown.Item>
                        </li>
                    )}
                    {canDelete && (
                        <li className='w-100'>
                            <Dropdown.Item as="button" className="btn-delete" onClick={handleShow}>Delete</Dropdown.Item>
                        </li>
                    )}
                    {children}
                </Stack>
            </Dropdown.Menu>
        </Dropdown>
    );
}
