import React, { useContext, useEffect } from 'react';

import { useBanner } from '../../providers/BannerProvider/BannerProvider';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';

import { Banner } from '../Banner';

export default function PackageBanner() {

    const { setIsEvent, setObj, setPublishDate, setStart, setEnd } = useBanner()

    const { eventPackage, packageStart, packageEnd, packageVisibility } = useContext(PackageDetailsContext)

    useEffect(() => {
        setObj(eventPackage)
        setPublishDate(packageVisibility)
        setStart(packageStart)
        setEnd(packageEnd)
        setIsEvent(false)
    }, [eventPackage, packageStart, packageEnd, packageVisibility])

    return (
        <>
            <Banner />
        </>
    );
}
