import React from 'react';
import moment from 'moment';

import { formatDateTime, formatNumber, getTimezoneDate } from '../../../../utilities/helpers';

import { EditDeleteDropdown } from '../../../EditDeleteDropdown';

export default function Campaign({ timezone, campaign, handleShow, handleClick, show }) {

    let date = campaign?.scheduled ? campaign?.scheduledTime : campaign?.createdAt

    return (
        <div className='flex-row' role="rowgroup">
            <div className='list-table-col text-truncate' role="cell">
                <span>{campaign?.replyTo}</span>
            </div>
            <div className="list-table-col" role="cell">
                <span>{formatNumber(campaign?.contacts?.length)}</span>
            </div>
            <div className="list-table-col" role="cell">
                <span>{formatDateTime(getTimezoneDate(date, timezone))}</span>
            </div>
            {show && (
                <span className="btn-more-col" role="cell">
                    <EditDeleteDropdown handleShow={() => handleShow(campaign.id)} onClick={(e) => handleClick(e, campaign.id, campaign)} />
                </span>
            )}
        </div>
    );
}
