import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import { InfoIcon } from '../../../InfoIcon';
import { UploadImage } from '../../../UploadImage';
import { TrashButton } from "../../../TrashButton";

export default function AddAttractionModal({ show, handleClose, addManually, setAddManually, id, attraction, setAttraction, image, setImage, coordinates, setCoordinates, handleSubmit, isDisabled }) {

    const handleRemove = () => {
        setImage()
        setCoordinates()
    }

    return (
        <Modal show={show} animation={false} onHide={handleClose} centered backdrop='static' className='modal-lg'>
            <Modal.Body>
                <div className='modal-body-heading'>
                    <h1 className='modal-body-heading-title'>{id ? 'Edit' : 'Add'} attraction</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                    {/* <Form.Check
                        type="switch"
                        id="add-manually"
                        label="Add manually"
                        checked={addManually}
                        onChange={(e) => setAddManually(e.target.checked)}
                    /> */}
                    <>
                        <Form.Group className="form-group" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter attraction name"
                                value={attraction?.name || ''}
                                onChange={(e) => setAttraction({ ...attraction, [e.target.name]: e.target.value })}
                                autoFocus
                                required />
                        </Form.Group>
                        <Form.Group className="form-group" controlId="thumbnail">
                            <div className="form-label--flex">
                                <Form.Label>Event thumbnail</Form.Label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Use a high quality image in 1:1 ratio (e.g 1080 x 1080px).</Tooltip>}>
                                    <Button variant="link">
                                        <InfoIcon />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            <div className={`${image ? 'flex' : ''} `}>
                                <UploadImage selectedImage={image} setSelectedImage={setImage} coordinates={coordinates} setCoordinates={setCoordinates} altText={`Portrait of ${attraction?.name} `} />
                                {image && (
                                    <TrashButton
                                        onClick={handleRemove}
                                    />
                                )}
                            </div>
                        </Form.Group>
                    </>
                    <Stack direction="horizontal" className='btn-group-flex'
                    >
                        <Button variant="outline-light"
                            size="lg"
                            onClick={handleClose}>Cancel</Button>
                        <Button
                            disabled={isDisabled}
                            type="submit"
                            size="lg"
                        >
                            Apply
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
