import React, { useEffect, useState } from "react";

import { formatNumber } from "../../../../../../utilities/helpers";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Item } from "./Item";

export default function SelectGASeatsModal({
    show,
    handleSubmit,
    handleCancel,
    handleClose,
    offers,
    kills,
    holds,
    selectedGASectionId
}) {
    const [totalSelectedCount, setTotalSelectedCount] = useState(0);
    const [assigned, setAssigned] = useState([]);

    const findAssignedGASeats = () => {
        const assignedSeats = [];

        const processInventory = (items, type) => {
            Object.entries(items).forEach(([id, item]) => {
                const mainKey = `${type}-${id}`;

                if (item.gaSeats && item.gaSeats[selectedGASectionId]?.length > 0) {
                    assignedSeats.push({
                        id,
                        name: item.name,
                        gaSeats: item.gaSeats[selectedGASectionId],
                        color: item.color,
                        key: mainKey,
                        count: 0,
                    });
                }

                // Process categories
                if (item.categories) {
                    Object.entries(item.categories).forEach(([subId, subItem]) => {
                        const subKey = `${mainKey}-${subId}`;
                        if (subItem.gaSeats && subItem.gaSeats[selectedGASectionId]) {
                            assignedSeats.push({
                                id: `${id}-${subId}`,
                                name: `${item.name} - ${subItem.name}`,
                                gaSeats: subItem.gaSeats[selectedGASectionId],
                                color: item.color,
                                key: subKey,
                                count: 0,
                            });
                        }
                    });
                }
            });
        };

        processInventory(offers, 'o');
        processInventory(kills, 'k');
        processInventory(holds, 'h');

        setAssigned(assignedSeats);
    };


    useEffect(() => {
        const totalCount = Object.values(assigned).reduce((sum, inv) => sum + inv.count, 0);
        setTotalSelectedCount(totalCount);
    }, [assigned])

    useEffect(() => {
        findAssignedGASeats();
    }, [offers, kills, holds, selectedGASectionId]);

    const handleCount = (index, newCount) => {
        setAssigned(prevAssigned => {
            const updatedAssigned = [...prevAssigned];
            updatedAssigned[index] = { ...updatedAssigned[index], count: newCount };
            return updatedAssigned;
        });
    };

    const totalAssignedSeats = assigned.reduce((total, item) => total + item.gaSeats.length, 0);
    const totalSeats = totalAssignedSeats;

    return (
        <Modal
            show={show}
            animation={false}
            onHide={handleClose}
            centered
            backdrop='static'
            className='modal-sm'
        >
            <Modal.Body>
                <div className='modal-body-heading modal-body-heading-sm'>
                    <h1 className='modal-body-heading-title'>Seats from GA section</h1>
                </div>
                <Form onSubmit={(e) => handleSubmit(e, assigned, selectedGASectionId)}>
                    <div className='list'>
                        <Stack gap={5} as='ul'>
                            {assigned?.length > 0 && (
                                <li>
                                    <p className='mb-3'>Select number of seats to move from:</p>
                                    <Stack as='ul' gap={2}>
                                        {assigned.map((obj, idx) => (
                                            <li key={idx}>
                                                <Item
                                                    obj={obj}
                                                    numSeatsInitialState={obj.gaSeats.length}
                                                    handleChange={(newCount) => handleCount(idx, newCount)}
                                                />
                                            </li>
                                        ))}
                                    </Stack>
                                </li>
                            )}
                        </Stack>
                    </div>

                    <div className='seperator seperator-sm split-row split-row-lg'>
                        <span className='normal fw-bold'>Total</span>
                        <span className='num-seats-lg'>
                            {formatNumber(totalSelectedCount)} / {formatNumber(totalSeats)}
                        </span>
                    </div>

                    <Stack direction='horizontal' className='btn-group-flex'>
                        <Button variant='outline-light' size='lg' onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button size='lg' type='submit' disabled={totalSelectedCount === 0}>
                            Apply
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
