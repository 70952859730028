import React, { useRef, useState, useEffect } from "react";
import chroma from "chroma-js";
import { Path, Layer, Group } from "react-konva";
import { InventoryBar } from "./InventoryBar";
import { mapStateManager } from "../MapStateManager";

const Sections = React.memo(
    ({
        data,
        isDisabled,
        activeMapAction,
        displayInventoryInfo,
        getSectionState,
        handleSectionSelect,
        deriveInventoryInfoFrom
    }) => {
        const [hoveredSectionId, setHoveredSectionId] = useState(null);

        const handleSectionAction = (e, sectionId, hovering) => {
            // if section is disabled don't change hover of section
            if (isDisabled) return

            applyFilterToSection(sectionId, hovering);
        };

        const applyFilterToSection = (sectionId, hovering) => {
            const section = data.sections[sectionId];
            if (!section) return;

            // For non-zoomable sections, just update the section
            if (section.rows.length === 0) {
                mapStateManager.setState(sectionId, { selected: hovering });
                return;
            }

            setHoveredSectionId(hovering ? sectionId : null);
        };

        return (
            <Layer>
                {data?.sections &&
                    Object.values(data.sections).map((section) => {
                        return section?.zoomable ? (
                            <Path
                                key={section.sectionId}
                                data={section.path}
                                id={section.sectionId}
                                onClick={() => handleSectionSelect(section)}
                                onMouseOver={(e) =>
                                    handleSectionAction(e, section.sectionId, true)
                                }
                                onMouseOut={(e) =>
                                    handleSectionAction(e, section.sectionId, false)
                                }
                                listening={activeMapAction === 1}
                                opacity={hoveredSectionId === section.sectionId ? 0.2 : 0}
                                fill="#3E8BF7"
                            ></Path>
                        ) : (
                            <GASection
                                key={section?.sectionId}
                                section={section}
                                handleSectionAction={handleSectionAction}
                                handleSectionSelect={handleSectionSelect}
                                displayInventoryInfo={displayInventoryInfo}
                                getSectionState={getSectionState}
                                deriveInventoryInfoFrom={deriveInventoryInfoFrom}
                            />
                        );
                    })}
            </Layer>
        );
    }
);

function GASection({
    section,
    handleSectionAction,
    handleSectionSelect,
    displayInventoryInfo,
    getSectionState,
    deriveInventoryInfoFrom
}) {
    const visualRef = useRef();
    const sectionStatus = getSectionState(section);
    const [sectionState, setSectionState] = useState(mapStateManager.getState(section.sectionId));

    const darkenColor = (color, amount) => {
        return chroma(color).darken(amount).hex();
    };

    useEffect(() => {
        return mapStateManager.subscribe((updatedSectionId) => {
            if (updatedSectionId === section.sectionId) {
                setSectionState(mapStateManager.getState(section.sectionId))
            }
        })
    }, [section])

    return (
        <Group
            onClick={() => handleSectionSelect(section)}
            onMouseOver={(e) => handleSectionAction(e, section.sectionId, true)}
            onMouseOut={(e) => handleSectionAction(e, section.sectionId, false)}
            listening={!sectionStatus.disabled}
        >
            {/* Section */}
            <Path
                fill={sectionState?.selected ? darkenColor(sectionStatus.color) : sectionStatus.color}
                data={section.path}
                className={`path`}
                listening={!sectionStatus.disabled}
                transformsEnabled={"position"}
                perfectDrawEnabled={false}
                id={section.sectionId}
            ></Path>
            {/* Visuals, probably text */}
            <Path
                ref={visualRef}
                className='path_non_visual'
                data={section?.identifier?.path}
                listening={false}
            ></Path>
            <InventoryBar
                visualRef={visualRef}
                section={section}
                displayInventoryInfo={displayInventoryInfo}
                deriveInventoryInfoFrom={deriveInventoryInfoFrom}
            />
        </Group>
    );
}

export default Sections;
