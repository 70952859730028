import { useEffect, useCallback, useRef } from 'react';

/**
 * Hook to safely handle async operations and prevent memory leaks
 * from state updates on unmounted components.
 * 
 * @returns {Function} A callback that safely executes state updates
 * only if the component is still mounted.
 * 
 * @example
 * const safeSetState = useSafeAsync();
 * 
 * // Use in async operations
 * fetchData().then(data => {
 *   safeSetState(() => {
 *     setState(data);
 *   });
 * });
 */
const useSafeAsync = () => {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const safeSetState = useCallback((callback) => {
    if (mounted.current) {
      callback();
    }
  }, []);

  return safeSetState;
};

export default useSafeAsync;