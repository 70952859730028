import React, { useState, useEffect, useRef, useContext } from 'react';
import moment from 'moment';

import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { getTimezoneDate } from '../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import { TimeInput } from './TimeInput';

export default function TimeInputWrapper({ label, id, timezone, setDate, selectedDate, size, error, errorMsg, isReadOnly = false }) {

    const { event } = useContext(EventDetailsContext)

    const timeRef = useRef();

    // temporary state to store date in case of manually entering 
    const [chosenDate, setChosenDate] = useState(selectedDate)

    useEffect(() => {
        setDate(chosenDate)
    }, [chosenDate])

    useEffect(() => {
        // if current date is null before selecting date and selected date is midnight (12:00 AM) - default (first time changing date)
        if (!chosenDate && selectedDate?.getHours() === 0 && selectedDate?.getMinutes() === 0) {
            // set the time to noon (12:00 PM)
            selectedDate?.setHours(12);
            selectedDate?.setMinutes(0);
        }
    }, [selectedDate, chosenDate])

    // filter out past times based on timezone
    const filterPastTimes = (date) => {
        let currentDate = moment()
        let selectedDate = moment(date)

        if (event?.timezone || timezone) {
            currentDate = getTimezoneDate(moment(), (event?.timezone || timezone));
            selectedDate = getTimezoneDate(moment(date), (event?.timezone || timezone), true);
        }
        return currentDate.isBefore(selectedDate);
    };

    return (
        <Form.Group>
            <div className={`date-picker-card time-picker-card ${size ? `date-picker-card-${size}` : ''} ${error ? 'error' : ''}`} ref={timeRef} >
                <Stack>
                    {label && (<Form.Label htmlFor={id}>{label}</Form.Label>)}
                    <TimeInput
                        id={id}
                        selectedDate={selectedDate}
                        setChosenDate={setChosenDate}
                        filterPastTimes={filterPastTimes}
                        reference={timeRef}
                        isReadOnly={isReadOnly}
                    />
                </Stack>
            </div>
            {error && (
                <Form.Text id={id} className="error">{errorMsg}</Form.Text>
            )}
        </Form.Group>
    );
}
