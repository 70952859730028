import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';

import { Campaign } from './Campaign'
import { EmptyContainer } from '../../EmptyContainer';
import { DeleteModal } from '../../DeleteModal';

export default function ContactAttendees({ eventTimezone, campaigns, removeCampaigns, isRemoving, type, handleClick, hasEventEnded }) {

    const [
        key,
        setKey
    ] = useState(type)

    const [show, setShow] = useState(false)

    // id to remove 
    const [id, setId] = useState()

    const handleShow = (id) => { setShow(true); setId(id) };

    const handleClose = () => { setShow(false); setId() };

    const handleDelete = () => {
        removeCampaigns(id).then(() => {
            handleClose();
        })
    }

    if (key === "scheduled") {
        campaigns = campaigns.filter((campaign) => campaign.scheduled === true)
    } else {
        campaigns = campaigns.filter((campaign) => campaign.scheduled === false)
    }

    return (
        <>
            <Stack direction='horizontal' className='mb-5'>
                <Tab.Container defaultActiveKey={key} activeKey={key} onSelect={(k) => setKey(k)}>
                    <Nav as="ul" variant="pills" className='w-25' justify>
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="scheduled">
                                Scheduled
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link as="button" eventKey="sent">
                                Sent
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>
                <Button
                    size="lg"
                    className="ms-auto"
                    onClick={handleClick}
                    disabled={hasEventEnded}
                >Contact attendee</Button>
            </Stack>
            <Card body>
                {campaigns && campaigns.length > 0 ? (
                    <div className={`list-table ${key === 'scheduled' ? 'three-col' : 'two-col'}`} role="table">
                        <div className="flex-row list-table-header" role="rowgroup">
                            <div className='list-table-col list-table-col-header' role="columnheader">
                                <span>Email</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Recipients</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Date</span>
                            </div>
                        </div>
                        {campaigns.map((campaign, index) => (
                            <Campaign key={index} timezone={eventTimezone} campaign={campaign} handleShow={handleShow} handleClick={handleClick} show={key === 'scheduled'} />
                        ))}
                    </div>
                ) : (
                    <EmptyContainer>
                        <p>Nothing has been {key}, click Contact attendees to schedule or send an email.</p>
                    </EmptyContainer>
                )}
            </Card>

            <DeleteModal entity="contact" show={show} handleClose={handleClose} isRemoving={isRemoving} handleDelete={handleDelete} />
        </>

    );
}
