import React, { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';

export default function SelectColumnsCard({ columns, types, handleCheck, selectedColumns, selectedTypes, handleSelect, handleShow }) {

    const [open, setOpen] = useState(false)

    return (
        <>
            <Card body className='card--sm'>
                <Button variant="default" className="btn-toggle w-100"
                    onClick={() => setOpen(!open)}
                    aria-controls="select-columns"
                    aria-expanded={open}
                >
                    <h1 className='card-body-title'>Configure columns</h1>
                </Button>
                <Collapse in={open}>
                    <div>
                        <div className="card-section">
                            <div className='card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between'>
                                <p>Select the data you would like to export</p>
                                <Button variant="outline-light" size="lg" disabled={(selectedColumns?.length === 0 || selectedTypes?.length === 0)} onClick={handleShow}>Export data</Button>
                            </div>
                            <Row className='gap-5'>
                                <Col lg={8}>
                                    <div className='split-row header'>
                                        <div className="heading--flex">
                                            <h2 className='heading'>Orders</h2>
                                            <Button
                                                variant='link'
                                                onClick={() => handleSelect('columns', 'all')}
                                                disabled={columns?.every(col => selectedColumns?.includes(col.idx))}
                                                className="select-label">
                                                Select all
                                            </Button>
                                            <Button
                                                variant='link'
                                                onClick={() => handleSelect('columns')}
                                                disabled={selectedColumns?.length === 0}
                                                className="select-label">
                                                Deselect all
                                            </Button>
                                        </div>
                                    </div>
                                    <ul id='column-list'>
                                        <li>
                                            <ul>
                                                {columns.slice(0, 5)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(5, 10)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(10, 15)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(15, 20)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(20, 25)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <ul>
                                                {columns.slice(25)?.map((column, index) => (
                                                    <li key={index}>
                                                        <Form.Check
                                                            label={column.label}
                                                            type="checkbox"
                                                            checked={selectedColumns.includes(column.idx)}
                                                            id={column.idx}
                                                            name="column"
                                                            onChange={(e) => handleCheck(e, 'columns')}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </ul>
                                </Col>
                                <Col id="transaction-type-col">
                                    <div className='split-row'>
                                        <div className="heading--flex">
                                            <h2 className='normal'>Transaction type</h2>
                                            <Button
                                                variant='link'
                                                onClick={() => handleSelect('types', 'all')}
                                                disabled={types.every(type => selectedTypes.includes(type.value))}
                                                className="select-label">
                                                Select all
                                            </Button>
                                            <Button variant='link' className="select-label" disabled={selectedTypes?.length === 0} onClick={() => handleSelect('types')}>Deselect all</Button>
                                        </div>
                                    </div>
                                    <Stack as="ul">
                                        {types.map((type, index) => (
                                            <li key={index}>
                                                <Form.Check
                                                    label={type.label}
                                                    type="checkbox"
                                                    checked={selectedTypes.includes(type.value)}
                                                    id={type.value}
                                                    name="transaction_type"
                                                    onChange={(e) => handleCheck(e, 'types')}
                                                />
                                            </li>
                                        ))}
                                    </Stack>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Collapse>
            </Card>
        </>
    );
}
