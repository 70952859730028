import React from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { SuccessContainer } from '../../SuccessContainer'

export default function ConfirmationModal({ show, handleClose }) {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
        handleClose()
    }

    return (
        <Modal id="confirmation-modal" centered animation={false} fullscreen="md-down" backdrop='static' show={show} onHide={handleClose} className='modal--lg'>
            <Modal.Body>
                <>
                    <SuccessContainer>
                        <h4 className="modal-body-heading-title">Report has been sent!</h4>
                    </SuccessContainer>
                    <p className="small text-center">
                        The report has been sent to your email.
                    </p>
                    <Stack className="btn-group-flex">
                        <Button size="lg" onClick={handleClose}>Close</Button>
                    </Stack>
                </>
            </Modal.Body>
        </Modal>

    );
}
