import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { BannerProvider } from '../../providers/BannerProvider/BannerProvider';

import { PageLoadingContainer, Sidenav } from '../../components';
import { GroupPackageWrapper } from '../../components';

export default function GroupPackagePage() {
    const { uuid } = useParams()

    const [windowSize, setWindowSize] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            const el = document.querySelector("html")
            // Set window width/height to state
            setWindowSize(el.clientWidth);
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    })

    useLayoutEffect(() => {
        const el = document.querySelector("#banner")
        if (el) el.style.width = `${windowSize}px`
    }, [windowSize])

    useEffect(() => {
        // setIsLoading(true)
        console.log('Group Package event uuid ', uuid)
    }, [uuid])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidebar" />
            ) : (
                <>
                    
                        <BannerProvider>
                            <GroupPackageWrapper uuid={uuid} />
                        </BannerProvider>
                        <Sidenav />
                        <div className='spacer-md spacer-md--with-banner' id="main-content">
                            <Outlet />
                        </div>
                </>
            )}
        </>
    )
}