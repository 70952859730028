import React from "react";

import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";

import { ListItem } from "./ListItem";
import { Item } from "../Item";

export default function InventoryMenu({
    canEdit,
    isEventOnsale,
    holds,
    kills,
    offers,
    sold,
    handleRemove,
    handleAdd,
    getObjectTotalSeatCount,
    isDroppableInSelected
}) {
    return (
        <div className='list-container inventory-container'>
            <ListGroup as='ul' variant='flush'>
                <ListGroup.Item as='li'>
                    <ListItem
                        title='Offers'
                        data={offers}
                        getObjectTotalSeatCount={getObjectTotalSeatCount}
                        isDroppableInSelected={isDroppableInSelected}
                        canEdit={canEdit}
                    />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    <ListItem
                        title='Holds'
                        data={holds}
                        handleRemove={handleRemove}
                        handleAdd={handleAdd}
                        getObjectTotalSeatCount={getObjectTotalSeatCount}
                        isDroppableInSelected={isDroppableInSelected}
                        canEdit={canEdit}
                    />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    <ListItem
                        title='Kills'
                        data={kills}
                        handleRemove={handleRemove}
                        handleAdd={handleAdd}
                        getObjectTotalSeatCount={getObjectTotalSeatCount}
                        isDroppableInSelected={isDroppableInSelected}
                        canEdit={canEdit}
                    />
                </ListGroup.Item>
                {(isEventOnsale ||
                    (sold && Object.keys(sold).length > 0 &&
                        (sold[Object.keys(sold)[0]]?.seats?.length > 0 ||
                            Object.keys(sold[Object.keys(sold)[0]]?.gaSeats || {}).length > 0)
                    )
                ) && (
                        <ListGroup.Item as='li'>
                            <div className='heading--flex-space-between mb-2'>
                                <span className='fw-semi-bold'>Sold</span>
                            </div>
                            <Stack as='ul'>
                                <li>
                                    <Item
                                        el={sold[Object.keys(sold)[0]]}
                                        getObjectTotalSeatCount={getObjectTotalSeatCount}
                                    />
                                </li>
                            </Stack>
                        </ListGroup.Item>
                    )}
            </ListGroup>
        </div>
    );
}
