import React, { useState } from 'react';

import { usePackageConfig } from '../../providers/CreatePackageProvider/CreatePackageProvider';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { EventsList } from './EventsList';
import { PackagesList } from './PackagesList';

import './homeWrapper.scss';

export default function HomeWrapper() {

    const { handleShow } = usePackageConfig()

    const [key, setKey] = useState('events')

    return (
        <section>
            <header className='section-header'>
                <div className="section-heading">
                    <h1>Events</h1>
                </div>
            </header>
            <Card body>
                <Tabs
                    id="events"
                    variant="pills"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="w-25"
                    justify
                >
                    <Tab eventKey="events" title="All events">
                        <EventsList />
                    </Tab>
                    <Tab eventKey="packages" title="Packages">
                        <Button variant="outline-light" className='btn-plus align-self-end ms-auto mb-4' onClick={handleShow}>Create new package</Button>
                        <PackagesList />
                    </Tab>
                </Tabs>
            </Card>
        </section>
    );
}
