import React from 'react';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../LoadingContainer/Spinner';

export default function CreateEventButtons({ isEditing, isSaving, page, showGoBack = false, styles, handleSave, handleGoBack, }) {

    const isOffersPage = page === 'Offers'

    const getText = () => {
        if (isSaving) {
            return <Spinner />
        }
        else {
            if (isEditing) {
                if (isOffersPage) return 'Update offer'
                else return 'Save'
            }
            else {
                if (isOffersPage) {
                    return 'Create offer'
                }
                else return "Save and continue"
            }
        }
    }

    return (
        <div className={`btn-footer ${styles || ''}`}>
            <Stack direction="horizontal" className="wrapper btn-group-flex">

                {showGoBack && (
                    <Button variant="outline-light" size="lg" className="btn-back" onClick={handleGoBack}>Discard</Button>
                )}

                <Button size="lg" disabled={isSaving} className={`${!isEditing && !isSaving ? 'btn-next' : ''} ${!isOffersPage && isEditing ? 'btn-width-xs' : 'btn-width-xxl'} `} onClick={handleSave}>{getText()}</Button>

            </Stack>
        </div>
    )
}
