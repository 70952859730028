import React from 'react';

import { formatDateTime, getTimezoneDate } from '../../../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { DateInputWrapper } from '../../../../DateInputWrapper';
import { TimeInputWrapper } from '../../../../TimeInputWrapper';
import { MatchWarningError } from '../MatchWarningError';

export default function CreatePeriodModal({ show, id, eventStart, eventEnd, eventVisibility, openOffers, name, setName, starts, setStarts, ends, setEnds, startError, endError, errorMsg, endErrorMsg, timezone, isDisabled, handleSubmit, handleClose, isMatchingStandardOffer, willOverlapStandardAdmission, isMatchingOtherOffer, matchedOffers, hasExactMatchError, hasInBetweenMatchError }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static" dialogClassName='modal-800w'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">{id ? 'Edit' : 'Create New'} Period
                    </h1>
                </div>
                <div className="mb-3">
                    <span className='small small-bold'>Other Active Open Offers</span>
                    <ul className='d-flex-column gap-2 list list-item list-item-lg py-2 mt-2'>
                        {openOffers?.map((offer, idx) => (
                            <li key={idx}>
                                <span className='small-label'>{offer?.name}</span>
                                <small className='subtitle'>
                                    <span>{formatDateTime(getTimezoneDate(offer?.period?.starts, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(offer?.period?.starts, timezone), 'timeOnly')}</span></span><span className='to'>{formatDateTime(getTimezoneDate(offer?.period?.ends, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(offer?.period?.ends, timezone), 'timeOnly')}</span></span>
                                </small>
                            </li>
                        ))}
                    </ul>
                </div>
                {(isMatchingStandardOffer || isMatchingOtherOffer) && (<MatchWarningError periodStarts={starts} periodEnds={ends} willOverlapStandardAdmission={willOverlapStandardAdmission} hasExactError={hasExactMatchError} hasInBetweenError={hasInBetweenMatchError} matchedOffers={matchedOffers} timezone={timezone} />)}
                <Form onSubmit={(e) => handleSubmit(e, Boolean(id))}>
                    <Form.Group className="form-group" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            name="name"
                            placeholder='Period name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Stack direction="horizontal" gap={4} className='align-items-stretch'>
                            <fieldset>
                                <legend className='sm'>Starts</legend>
                                <Stack direction='horizontal' className="align-items-stretch" gap={3}>
                                    <DateInputWrapper
                                        id="period-start"
                                        setDate={setStarts}
                                        selectedDate={starts}
                                        minDate={eventVisibility || new Date()}
                                        maxDate={eventStart}
                                        startDate={starts}
                                        size="sm"
                                    />
                                    <TimeInputWrapper
                                        id="period-start-time"
                                        setDate={setStarts}
                                        selectedDate={starts}
                                        size="sm"
                                        error={startError}
                                        errorMsg={errorMsg}
                                    />
                                </Stack>
                            </fieldset>

                            <fieldset>
                                <legend className='sm'>Ends</legend>
                                <Stack direction='horizontal' className="align-items-stretch" gap={3}>
                                    <DateInputWrapper
                                        id="period-end"
                                        setDate={setEnds}
                                        selectedDate={ends}
                                        minDate={starts}
                                        maxDate={eventEnd}
                                        startDate={starts}
                                        endDate={ends}
                                        size="sm"
                                    />
                                    <TimeInputWrapper
                                        id="period-end-time"
                                        setDate={setEnds}
                                        selectedDate={ends}
                                        size="sm"
                                        width="87"
                                        error={endError}
                                        errorMsg={endErrorMsg}
                                    />
                                </Stack>
                            </fieldset>

                        </Stack>
                    </Form.Group>
                    <Stack direction="horizontal" className="btn-group-flex">
                        <Button variant="outline-light" size="lg" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            size="lg"
                            className='btn-width-xs'
                            disabled={isDisabled}
                        >
                            {id ? 'Update' : 'Save'}
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
