import React from "react";

import { formatNumber } from "../../../../../../utilities/helpers";

import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import { ItemName } from "../../../../../ItemName";
import { TrashButton } from "../../../../../TrashButton";

// reusable item for offers/holds/kills/sold/price level, moveSeats popup
export default function Item({
  index,
  entity,
  isSubItem = false,
  el,
  data,
  size,
  weight,
  canRemove = false,
  canAdd = false,
  handleRemove,
  handleAdd,
  getObjectTotalSeatCount,
  children,
}) {
  const { id } = el;

  return (
    <Stack>
      <div className={`split-row ${size === "lg" ? "split-row-lg" : ""}`}>
        <ItemName el={el} isSubItem={isSubItem} size={size} weight={weight} />
        <Stack direction='horizontal' gap={3}>
          <span className={`num-seats ${size ? `num-seats-${size}` : ""}`}>
            {formatNumber(getObjectTotalSeatCount(el))}
          </span>
          {(canAdd || canRemove) && (
            <Stack direction='horizontal' gap={2} className='btn-actions'>
              {canAdd && (
                <Button
                  variant='default'
                  onClick={() => handleAdd(entity, id)}
                  className='btn-icon btn-icon-xs btn-icon--square border-0'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M10.0003 16.6665C13.6822 16.6665 16.667 13.6817 16.667 9.99984C16.667 6.31794 13.6822 3.33317 10.0003 3.33317C6.31843 3.33317 3.33366 6.31794 3.33366 9.99984C3.33366 13.6817 6.31843 16.6665 10.0003 16.6665ZM10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332Z'
                      fill='#3E8BF7'
                    />
                    <path
                      d='M10.833 6.66683C10.833 6.20659 10.4599 5.8335 9.99967 5.8335C9.53944 5.8335 9.16634 6.20659 9.16634 6.66683L9.16634 9.16683H6.66634C6.2061 9.16683 5.83301 9.53993 5.83301 10.0002C5.83301 10.4604 6.2061 10.8335 6.66634 10.8335H9.16634L9.16634 13.3335C9.16634 13.7937 9.53944 14.1668 9.99967 14.1668C10.4599 14.1668 10.833 13.7937 10.833 13.3335L10.833 10.8335H13.333C13.7932 10.8335 14.1663 10.4604 14.1663 10.0002C14.1663 9.53993 13.7932 9.16683 13.333 9.16683H10.833L10.833 6.66683Z'
                      fill='#3E8BF7'
                    />
                  </svg>
                </Button>
              )}
              {canRemove && (
                <TrashButton
                  id={index}
                  removeFrom={data}
                  onClick={handleRemove}
                  size='xs'
                />
              )}
            </Stack>
          )}
        </Stack>
      </div>
      {children}
    </Stack>
  );
}
