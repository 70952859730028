import { useEffect, useState } from "react";
import { Rect, Group } from "react-konva";

export default function InventoryBar({
    visualRef,
    displayInventoryInfo,
    section,
    deriveInventoryInfoFrom
}) {
    const [bbox, setBbox] = useState();
    const [percentages, setPercentages] = useState({});

    useEffect(() => {
        const element = visualRef?.current;
        if (element) {
            let newBbox = element.getClientRect({ skipTransform: true });
            setBbox(newBbox);
        }
    }, [section]);

    const calculateInventoryPercentages = (deriveInventoryInfoFrom, section) => {
        if (!deriveInventoryInfoFrom) return null;

        const sectionId = section.sectionId;
        const totalSpots = section?.spots?.length;
        if (!totalSpots) return;

        let percentages = {};

        const processInventory = (items, type) => {
            Object.entries(items).forEach(([id, item]) => {
                const mainCount =
                    item.gaSeats && item.gaSeats[sectionId]
                        ? item.gaSeats[sectionId].length
                        : 0;

                // Unified handling for all types (offers, kills, holds)
                const key = type === "offers" ? id : type;
                if (!percentages[key]) {
                    percentages[key] = { count: 0, color: item.color };
                }
                percentages[key].count += mainCount;

                // Process categories
                if (item.categories) {
                    Object.entries(item.categories).forEach(([categoryId, subItem]) => {
                        const subCount =
                            subItem.gaSeats && subItem.gaSeats[sectionId]
                                ? subItem.gaSeats[sectionId].length
                                : 0;

                        const subKey = type === "offers"
                            ? `${id}_${categoryId}`
                            : `${type}_${categoryId}`;

                        if (!percentages[subKey]) {
                            percentages[subKey] = { count: 0, color: item.color };
                        }
                        percentages[subKey].count += subCount;
                    });
                }
            });
        };

        deriveInventoryInfoFrom?.map((inventory) => processInventory(inventory?.inventory, inventory.type));

        // Convert counts to percentages
        Object.keys(percentages).forEach((key) => {
            percentages[key].percentage = percentages[key].count / totalSpots;
            delete percentages[key].count; // Remove the count property as it's no longer needed
        });

        return percentages;
    };

    useEffect(() => {
        if (!deriveInventoryInfoFrom) return;

        const percentages = calculateInventoryPercentages(deriveInventoryInfoFrom, section);
        setPercentages(percentages);
    }, [deriveInventoryInfoFrom, section]);

    if (!(displayInventoryInfo && bbox)) {
        return null;
    }

    const getSpacingMultiplier = (obj) => {
        const values = Object.values(obj);
        const nonZeroValues = values.filter((value) => value?.percentage > 0);
        return nonZeroValues.length - 1;
    };

    const padding = 30;
    const spacing = 10;
    const spacingMultiplier = getSpacingMultiplier(percentages);

    const usableWidth = bbox.width - (2 * padding) - (spacingMultiplier * spacing);
    const startX = bbox.x + padding;
    const startY = bbox.y + bbox.height / 2 - 50 + padding;


    const getXPosition = (
        categories,
        currentCategory,
        inventoryData,
        startX,
        usableWidth,
        spacing
    ) => {
        let xPosition = startX;
        let previousNonZeroCategoryFound = false;

        for (const category of categories) {
            if (category === currentCategory) {
                if (previousNonZeroCategoryFound) {
                    xPosition += spacing;
                }
                break;
            }
            if (inventoryData[category].percentage > 0) {
                if (previousNonZeroCategoryFound) {
                    xPosition += spacing;
                }
                xPosition += usableWidth * inventoryData[category].percentage;
                previousNonZeroCategoryFound = true;
            }
        }

        return xPosition;
    };

    const categoriesOrder = Object.keys(percentages).sort((a, b) => {
        if (a === 'open') return -1;
        if (b === 'open') return 1;
        if (a === 'holds') return 1;
        if (b === 'holds') return -1;
        if (a === 'kills') return 1;
        if (b === 'kills') return -1;
        return a.localeCompare(b);
    });

    const categoryPositions = categoriesOrder.reduce((acc, category) => {
        acc[category] = getXPosition(
            categoriesOrder,
            category,
            percentages,
            startX,
            usableWidth,
            spacing
        );
        return acc;
    }, {});

    return (
        <Group listening={false}>
            <Rect
                listening={false}
                x={bbox.x}
                y={bbox.y + bbox.height / 2 - 50}
                width={bbox.width}
                height={150}
                fill='white'
                cornerRadius={20}
            />
            {categoriesOrder.map((category) => (
                <Rect
                    key={category}
                    x={categoryPositions[category]}
                    y={startY}
                    width={usableWidth * percentages[category].percentage}
                    fill={percentages[category].color}
                    cornerRadius={20}
                    height={90}
                    listening={false}
                />
            ))}
        </Group>
    );
}
