import React from "react";

import { getTimezoneDate } from "../../../utilities/helpers";

import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Review } from "./Review";
import { Visibility } from "./Visibility";
import { Info } from "./Info";
import { Spinner } from "../../../components/LoadingContainer/Spinner";

import "./publishModal.scss"

export default function PublishModal({
    show,
    isEvent,
    obj,
    step,
    isScheduled,
    start,
    publishDate,
    setPublishDate,
    error,
    errorMsg,
    alert,
    isDisabled,
    isSaving,
    handleVisibility,
    handleEdit,
    handleCancel,
    handleNext
}) {

    return (
        <Modal
            centered
            animation={false}
            fullscreen='md-down'
            show={show}
            onHide={handleCancel}
            backdrop='static'
        >
            <Modal.Body>
                <h1 className='vjx-pm-heading'>Publish {isEvent ? 'event' : 'package'}</h1>
                {alert.show && (
                    <>
                        <Alert variant={alert.variant} className='mb-5'>
                            <p>{alert.message}</p>
                        </Alert>
                    </>
                )}
                {step === 1 && <Review obj={obj} isEvent={isEvent} handleEdit={handleEdit} />}
                {step === 2 && (
                    <Visibility
                        isEvent={isEvent}
                        visibility={publishDate}
                        setVisibility={setPublishDate}
                        generalOnsale={
                            isEvent ? new Date(getTimezoneDate(obj?.generalOnsale, obj?.timezone)) : undefined
                        }
                        start={!isEvent ? new Date(getTimezoneDate(obj?.start, obj?.timezone)) : undefined}
                        isScheduled={isScheduled}
                        error={error}
                        errorMsg={errorMsg}
                        handleVisibility={handleVisibility}
                    />
                )}
                {step === 3 && (
                    <Info
                        obj={obj}
                        start={start}
                        isScheduled={isScheduled}
                        isEvent={isEvent}
                    />
                )}
                <Stack className='btn-group-flex' direction='horizontal'>
                    <div className='me-auto'>
                        <span className='text-muted fw-medium '>
                            <span className='text-body'>{step}</span> / 3
                        </span>
                    </div>
                    <Button variant='outline-light' size='lg' onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        size='lg'
                        className={`btn-width-sm ${step !== 3 && !isSaving ? "btn-next" : ""
                            }`}
                        disabled={isDisabled || error || isSaving}
                        onClick={handleNext}
                    >
                        {isSaving ? (
                            <Spinner />
                        ) : (
                            <>
                                {step == "3" ? (isScheduled ? "Schedule" : "Publish") : "Next"}
                            </>
                        )}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
