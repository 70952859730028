import React, {
    Suspense,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
    lazy,
} from "react";
import { useNavigate } from "react-router-dom";

import { getOrgVenues, createPackage } from "../../utilities/api";

import { CreatePackageModal } from "./CreatePackageModal";

// Create the context
const CreatePackageContext = createContext(undefined);

export const usePackageConfig = () => {
    const context = useContext(CreatePackageContext);
    if (!context) {
        throw new Error("Context must be used within a usePackageConfig");
    }
    return context;
};

export const CreatePackageProvider = ({ children }) => {

    const navigate = useNavigate()

    const [show, setShow] = useState(null);

    const [venues, setVenues] = useState()

    const [seatmapId, setSeatmapId] = useState(null)

    const [room, setRoom] = useState({ uuid: '' })

    const [initialState, setInitialState] = useState()

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        getOrgVenues()
            .then((res) => setVenues(res?.data))
            .catch((err) => console.error(err))

        setInitialState({ room, seatmapId })
    }, [])

    useEffect(() => {
        setSeatmapId(null)
    }, [room])

    const handleVenue = (e) => {
        const uuid = e.target.value
        const timezone = venues?.find(venue => venue.rooms.find(room => room.uuid === uuid))?.timezone
        const venue = venues?.find(venue => venue.rooms.find(room => room.uuid === uuid))
        const room = venue.rooms.find(room => room.uuid === uuid)
        setRoom({ ...room, timezone })
    }

    const handleConfiguration = (e) => {
        setSeatmapId(e.target.value)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
        setRoom(initialState?.room)
        setSeatmapId(initialState?.seatmapId)
    }

    const handleNext = () => {
        setIsSaving(true)

        createPackage({ room, seatmapId })
            .then((res) => {
                handleClose()
                navigate(`mypackage/${res?.data?.uuid}/package-details`)
            })
            .catch((err) => console.error(err))
            .finally(() => setIsSaving(false))
    }

    return (
        <CreatePackageContext.Provider
            value={{
                show,
                handleClose,
                handleShow,
                seatmapId,
                room
            }}
        >
            <CreatePackageModal show={show} venues={venues} room={room} seatmapId={seatmapId} handleVenue={handleVenue} handleConfiguration={handleConfiguration} handleClose={handleClose} handleNext={handleNext} isSaving={isSaving} />
            {children}
        </CreatePackageContext.Provider>
    );
};
