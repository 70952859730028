import React, { useEffect, useState } from 'react';

import { formatDateTime, getTimezoneDate } from '../../../../utilities/helpers';

import Card from 'react-bootstrap/Card';

import { Offer } from './Offer';
import { WarningCard } from '../../../WarningCard';

export default function OpenOffers({ isEditing, offers, baseOffer, setBaseOffer, eventTimezone, getAvailabilityPeriods }) {

    const [updatedOffers, setUpdatedOffers] = useState()

    // change default periods in offer object 
    // get default periods from Standard Admission offer
    useEffect(() => {
        const offersExcludingStandard = offers.slice(1)?.map(offer => {
            return {
                ...offer,
                availability: getAvailabilityPeriods(offer)
            }
        })
        console.log(offers, offers.slice(0, 1));
        console.log(offersExcludingStandard);
        setUpdatedOffers([...offers.slice(0, 1), ...offersExcludingStandard])
    }, [offers])

    const getSaleDate = (offer, property, timezone, formatter) => {
        const selectedPeriod = offer?.availability.find(availability => availability.active)
        return formatDateTime(getTimezoneDate(selectedPeriod[property], timezone), formatter)
    }

    return (
        <>
            {!isEditing && (
                <WarningCard text="After creating this offer, it is not possible to change the associated open offer" variant="primary" margin="mb" />
            )}
            <div className="card-body-heading--sm card-body-heading--flex card-body-heading--flex-space-between">
                <div className='d-flex-column'>
                    <Card.Title as="h5" className='card-title-sm'>{isEditing ? 'Associated Offer' : 'Existing Offers'}</Card.Title>
                    <Card.Subtitle as="h6" className="subtitle--dark">{isEditing ? 'Open offer this offer is associated with' : 'Select which open offer to associate this offer with'}</Card.Subtitle>
                </div>
            </div>
            {isEditing ? (
                <div className="offset-container-sm">
                    <Offer offer={baseOffer} timezone={eventTimezone} isEditing={isEditing} isDisabled={isEditing} getSaleDate={getSaleDate} />
                </div>
            ) : (
                <ul className='offset-container-sm'>
                    {updatedOffers?.map((offer, i) => (
                        <Offer key={i} offer={offer} baseOffer={baseOffer} timezone={eventTimezone} isEditing={isEditing} getSaleDate={getSaleDate} setBaseOffer={setBaseOffer} />
                    ))}
                </ul>
            )}
            <WarningCard margin="mt" text={`This offer will be available for the whole duration of the ${baseOffer?.name} offer`} variant="primary" />
        </>
    );
}
