import { Spinner } from "../../../../../LoadingContainer/Spinner";
import "./loadingScreen.scss";

const LoadingScreen = ({ loading }) => {

  if (!loading) return null;
  return (
    <div className='vjx-loading-screen'>
        <Spinner variant="dark" size={"lg"} />
    </div>
  );
};

export default LoadingScreen;