import React, { createContext, useContext, useRef, useState } from 'react';

import { ZoomLevel } from './ZoomLevel';
import { ToolBar } from './ToolBar';

import '../SeatMap/seatMap.scss'
import { LoadingScreen } from './LoadingScreen';
import { Legend } from './Legend';

// Create the context
export const ActionsContext = createContext(undefined);

export const useActions = () => {
    const context = useContext(ActionsContext);
    if (!context) {
        throw new Error('Context must be used within a ActionsProvider');
    }
    return context;
}

// Create the provider component
export const ActionsProvider = ({ canEdit, legendItems, children }) => {
    const stageRef = useRef(null);
    const mapRef = useRef(null);
    const originalScaleRef = useRef(1);
    const [activeMapAction, setActiveMapAction] = useState(3);
    const [scale, setScale] = useState(1);
    const [loading, setLoading] = useState(true);
    const SCALE_FACTOR = 1.08; // Increased for more responsive feel
    const SCALE_LIMITS = { MIN: 0, MAX: 150 };

    const toolbarSelect = (action) => {
        if (activeMapAction === action) {
            setActiveMapAction(0);
            return;
        }

        setActiveMapAction(action);
    }

    function calculateScalePercentage(scaleToUse) {
        const ratio = scaleToUse / originalScaleRef.current;
        const percentage = (((ratio * 100) - 100) / 10);
        return percentage.toFixed(0);
    }

    return (
        <ActionsContext.Provider value={{ stageRef, scale, setScale, activeMapAction, calculateScalePercentage, originalScaleRef, SCALE_FACTOR, SCALE_LIMITS, setLoading }}>
            <div id="map" ref={mapRef} className={`${!canEdit ? 'map--disabled' : ''}`}>
                {children}
                <LoadingScreen loading={loading} />
                { legendItems && <Legend items={legendItems} />}
                <div className={`controls-container ${!canEdit ? 'd-none' : ''}`}>
                    <ZoomLevel setScale={setScale} scale={scale} calculateScalePercentage={calculateScalePercentage} stageRef={stageRef} scaleFactor={SCALE_FACTOR} scaleLimits={SCALE_LIMITS} />
                </div>
                <div className={`toolbar ${!canEdit ? 'd-none' : ''}`}>
                    <ToolBar activeMapAction={activeMapAction} toolbarSelect={toolbarSelect} />
                </div>
            </div>

        </ActionsContext.Provider>
    );
};