import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import isEqual from 'lodash/isEqual'

import LoadingContext from '../../context/Loading/Loading';

import { eventOrdersForReport, eventsForReport } from '../../utilities/api';

import { getTransactionTypes, getOfferName, getTicket, formatDateTime, getTimezoneDate, capitalizeString, formatPhoneNumber, formatCurrency, timezones, calculateFees, formatNumber } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { SelectEventsCard } from './SelectEventsCard';
import { SelectColumnsCard } from './SelectColumnsCard';
import { EmptyContainer } from '../EmptyContainer';
import { ExportModal } from './ExportModal';
import { ConfirmationModal } from './ConfirmationModal';

import "./reportsWrapper.scss"

export default function ReportsWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const columns = [
        {
            idx: 1,
            label: 'Order #',
        },
        {
            idx: 2,
            label: 'Order date',
        },
        {
            idx: 3,
            label: 'Event name',
        },
        {
            idx: 4,
            label: 'First name',
        },
        {
            idx: 5,
            label: 'Last name',
        },
        {
            idx: 6,
            label: 'Email',
        },
        {
            idx: 7,
            label: 'Phone number',
        },
        {
            idx: 8,
            label: 'Ticket quantity',
        },
        {
            idx: 9,
            label: 'Offer Type',
        },
        {
            idx: 10,
            label: 'Gross',
        },
        {
            idx: 11,
            label: 'Net',
        },
        {
            idx: 12,
            label: 'Facility fee',
        },
        {
            idx: 13,
            label: 'Service fee',
        },
        {
            idx: 14,
            label: 'Proccessing fee',
        },
        {
            idx: 15,
            label: 'Tax',
        },
        {
            idx: 16,
            label: 'Attendee status',
        },
        {
            idx: 17,
            label: 'Payment method',
        },
        {
            idx: 18,
            label: 'Last 4 digits'
        }
    ]

    const dateRangeOpts = [
        {
            label: "All",
            value: 'all'
        },
        {
            label: "Today",
            value: 'today'
        },
        {
            label: "Current month",
            value: 'cur_month'
        },
        {
            label: "Last 7 days",
            value: '7_days'
        },
        {
            label: "Last month",
            value: 'last_month'
        },
        {
            label: "Custom",
            value: 'custom'
        },
    ]

    const eventStatusOpts = [
        {
            label: "All events",
            value: "all"
        },
        {
            label: "Upcoming events",
            value: "upcoming"
        },
        {
            label: "Past events",
            value: "past"
        }
    ]

    const types = getTransactionTypes()

    const [events, setEvents] = useState([])

    const [eventStatus, setEventStatus] = useState(eventStatusOpts[0].value)

    const [query, setQuery] = useState('')

    const [isEventsLoading, setIsEventsLoading] = useState(true)

    const [filteredEvents, setFilteredEvents] = useState(events)

    const [selectedEvents, setSelectedEvents] = useState([])

    const [selectedColumns, setSelectedColumns] = useState([])

    const [selectedTypes, setSelectedTypes] = useState([])

    const [dateRange, setDateRange] = useState(null)

    const [initialDateRange, setInitialDateRange] = useState(null)

    const [show, setShow] = useState(false)

    const [showConfirm, setShowConfirm] = useState(false)

    const [startDate, setStartDate] = useState(null)

    const [initialStartDate, setInitialStartDate] = useState(null)

    const [endDate, setEndDate] = useState(null)

    const [initialEndDate, setInitialEndDate] = useState(null)

    const [focusedInput, setFocusedInput] = useState(null)

    const [isSameAsInitial, setIsSameAsInitial] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    const [alert, setAlert] = useState({
        message: '',
        show: false,
        varant: ''
    })

    useEffect(() => {
        // true => same date range as initial with same start and end date (all of them have to be same)
        // false => different date range as initial with different same start or end date (one of them can be different)
        setIsSameAsInitial(isEqual(initialDateRange, dateRange) && isEqual(new Date(initialStartDate), new Date(startDate)) && isEqual(new Date(initialEndDate), new Date(endDate)))
    }, [initialDateRange, dateRange, startDate, initialStartDate, endDate, initialEndDate])

    useEffect(() => {
        // reset start and end dates when date range changes to custom 
        if (dateRange === 'custom') {
            setStartDate(null)
            setEndDate(null)
        }
    }, [dateRange])

    useEffect(() => {
        // close alert when date range, start date, end date changes
        if (alert.show) {
            closeAlert()
        }
    }, [dateRange, startDate, endDate])

    // get all events 
    useEffect(() => {
        showLoading()
        loadEvents('all', '', setEvents)
    }, [])

    // get filter events 
    useEffect(() => {
        setIsEventsLoading(true)
        loadEvents(eventStatus, query, setFilteredEvents)
    }, [eventStatus, query])

    const loadEvents = (eventStatus, query, setter) => {
        eventsForReport(eventStatus, query)
            .then((res) => {
                setter(res.data)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                hideLoading()
                setIsEventsLoading(false)
            })
    }

    const getState = flag => {
        let setter;
        let state;
        let data;

        switch (flag) {
            case 'events':
                data = filteredEvents
                setter = setSelectedEvents
                state = selectedEvents
                break;
            case 'columns':
                data = columns
                setter = setSelectedColumns
                state = selectedColumns;
                break;
            case 'types':
                data = types
                setter = setSelectedTypes
                state = selectedTypes
                break;
        }
        return { setter, state, data }
    }

    const handleCheck = (e, flag) => {
        const { id, checked } = e.target;

        // get state objects
        const { setter, state, _ } = getState(flag)
        // set checkbox's id to state 
        setter([...state, Number.isInteger(Number(id)) ? Number(id) : id])

        if (!checked) {
            setter(state.filter(item => item != id));
        }
    }

    const handleSelect = (flag, action) => {
        const { setter, _, data } = getState(flag)

        // set state using either events, columns, transaction type state 
        if (action === 'all')
            setter([...data?.map(obj => obj?.idx || obj?.uuid || obj?.value)])

        else setter([])
    }

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    // export modal
    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
        setDateRange(null)
        setInitialDateRange(null)
        setStartDate(null)
        setInitialStartDate(null)
        setEndDate(null)
        setInitialEndDate(null)
        closeAlert()
    }

    // confirmation modal 
    const handleCloseConfirm = () => {
        setShowConfirm(false)
        setSelectedEvents([])
        setSelectedColumns([])
        setSelectedTypes([])
    }

    const handleRange = e => {
        const { value } = e.target;
        // TODO: start and end will need to be converted to user's timezone
        let startDate;
        let endDate;
        setDateRange(value)
        switch (value) {
            case 'today':
                startDate = moment().startOf('day')
                endDate = moment()
                break;
            // last 7 days
            case '7_days':
                startDate = moment().subtract(7, 'days').startOf('day'); // 7 days ago from now
                endDate = moment() // Today
                break;

            // current month
            case 'cur_month':
                startDate = moment().startOf('month')
                endDate = moment()
                break;

            // last month
            case 'last_month':
                startDate = moment().subtract(1, 'month').startOf('month'); // Start of last month
                endDate = moment().subtract(1, 'month').endOf('month'); // End of last month
                break;

            default:
                startDate = moment().subtract(1, 'month').startOf('month'); // Start of last month
                endDate = moment() // Today
                break;
        }

        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleDates = (start, end) => {
        let adjustedStart = start;
        if (start) {
            // Set start time to the start of the day: 00:00:00
            adjustedStart = moment(new Date(start).setHours(0, 0, 0, 0))
        }
        let adjustedEnd = end;
        if (end) {
            // Set end time to the current time
            const now = new Date();
            adjustedEnd = moment(new Date(end).setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
        }
        console.log('handle dates', adjustedStart, adjustedEnd);
        setStartDate(adjustedStart)
        setEndDate(adjustedEnd)
    }

    // get columns user selected
    const getColumns = (columns, selectedColumns) => {
        let tableColumns = [];
        // get columns user selected - use column idx to find table column
        columns?.map(column => {
            if (selectedColumns?.includes(column.idx)) {
                tableColumns = [...tableColumns, column]
            }
        })

        return tableColumns
    }

    const getTimezone = (orderId, orders) => {
        return orders?.find(order => order?.orderId === orderId)?.details?.event?.venue?.timezone
    }

    const format = (orderId, value, index, orders) => {
        let formattedValue;
        switch (index) {
            case 2:
                formattedValue = formatDateTime(getTimezoneDate(value, timezones(getTimezone(orderId, orders))))
                break;

            case 3:
            case 4:
            case 5:
                formattedValue = capitalizeString(value)
                break;

            case 7:
                formattedValue = formatPhoneNumber(value)
                break;

            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                formattedValue = formatCurrency(value)
                break;
            case 8:
                formattedValue = formatNumber(value)
                break;
            default:
                break
        }

        if (formattedValue) return formattedValue
        else return value
    }

    // build rows - get row data - each row is an array  
    const buildRows = (orders, columns) => {
        let rows = [];
        let row;
        orders?.map(order => {
            const { orderId, createdAt, type, total, tickets, gross, net, users_permissions_user, intentDetails } = order;
            const { name } = order?.details?.event;

            const { email, firstName, lastName, phoneNumber, } = users_permissions_user;

            const fees = calculateFees(order?.tickets[0], order?.details?.event?.fee_structure, order?.taxRates)
            row = [orderId, createdAt, name, firstName, lastName, email, phoneNumber, tickets?.length, getOfferName(getTicket(order)[0]), gross, net, order?.tickets[0]?.facilityFee, fees?.serviceFees, fees?.paymentProcessingFee, fees?.tax, 'Attending', (total !== 0 ? intentDetails?.charges?.data[0]?.payment_method_details?.card?.brand : 'N/A'), (total !== 0 ? intentDetails?.charges?.data[0]?.payment_method_details?.card?.last4 : 'N/A')]

            rows = [...rows, row]
        })

        return getRows(rows, columns, orders)
    }

    // get rows 
    const getRows = (rows, columns, orders) => {
        let tableRows = [];
        let currRow = [];
        // for each row
        rows?.map(row => {
            currRow = [];
            // get the data for each column 
            columns.map(column => {
                currRow = [...currRow, format(row[0], row[column.idx - 1], column.idx, orders)]
            })

            return tableRows = [...tableRows, currRow]
        })

        return tableRows;
    }

    const handleExport = () => {
        setIsSaving(true)
        eventOrdersForReport(selectedEvents, selectedTypes, startDate.format(), endDate.format()).then(res => {
            if (res?.data?.orders.length > 0) {
                let tableColumns = [];
                let tableRows = [];
                tableColumns = getColumns(columns, selectedColumns)
                if (tableColumns?.length > 0) {
                    tableRows = buildRows(res?.data?.orders, tableColumns)
                }

                const format = string => {
                    const lowerCaseString = string.toLowerCase()
                    return lowerCaseString.replaceAll(" ", "_")
                }
                const result = tableRows.map(row => {
                    return tableColumns.reduce((acc, column, index) => {
                        let formattedLabel = format(column.label)
                        acc[formattedLabel] = row[index]
                        return acc;
                    }, {});
                });

                // TODO: save data 
                console.log('export', result);
                handleClose()
                setShowConfirm(true)
            } else {
                setAlert({
                    show: true,
                    variant: 'danger',
                    message: 'There is no data to export within the selected date range. Please choose a different date range'
                })
                setInitialDateRange(dateRange)
                setInitialStartDate(startDate)
                setInitialEndDate(endDate)
            }
        }).catch((err) => console.error(err))
            .finally(() => setIsSaving(false))
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidebar" />
            ) : (
                <>
                    {events?.length > 0 ? (
                        <section id="custom-reports">
                            <header className='section-header'>
                                <div className="section-heading">
                                    <h1>Reports</h1>
                                </div>
                            </header>
                            <section>
                                <Stack gap={4}>
                                    {events?.length > 0 && (
                                        <SelectEventsCard isLoading={isEventsLoading} events={events} filteredEvents={filteredEvents} eventStatusOpts={eventStatusOpts} eventStatus={eventStatus} setEventStatus={setEventStatus} query={query} setQuery={setQuery} handleCheck={handleCheck} selected={selectedEvents} handleSelect={handleSelect} />
                                    )}
                                    {selectedEvents?.length > 0 && (
                                        <SelectColumnsCard columns={columns} types={types} handleCheck={handleCheck} selectedColumns={selectedColumns} selectedTypes={selectedTypes} handleSelect={handleSelect} handleShow={handleShow} />
                                    )}
                                </Stack>
                            </section>
                        </section>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No event created. Create one or more events to build data report</p>
                            </EmptyContainer>
                        </Card>
                    )}
                </>
            )}

            <ExportModal show={show} opts={dateRangeOpts} isSameAsInitial={isSameAsInitial} dateRange={dateRange} handleRange={handleRange} startDate={startDate} endDate={endDate} handleDates={handleDates} focusedInput={focusedInput} setFocusedInput={setFocusedInput} alert={alert} isSaving=
                {isSaving} handleSave={handleExport} handleClose={handleClose} />

            <ConfirmationModal show={showConfirm} handleClose={handleCloseConfirm} />
        </>
    );
}
