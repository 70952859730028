import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';
import { FromPageProvider } from '../../providers/FromPageProvider';
import { AssignToFanProvider } from '../../providers/AssignToFanProvider/AssignToFanProvider';

import useSafeAsync from '../../utilities/useSafeAsync';

import { getPackage, getInvoices, releasePackageSeats } from '../../utilities/api';

import Card from 'react-bootstrap/Card'

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { FansList } from './FansList';
import { FanProfileWrapper } from '../FanProfileWrapper';
import { ReleaseTicketsModal } from './ReleaseTicketsModal';

export default function AssignPackagesWrapper({ uuid, fanId }) {
    const safeSetState = useSafeAsync();
    const location = useLocation()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { updatePackage, updateCanPublish, updateIsPackagePublished, updateIsPackageOnsale, updateIsPackageSoldout, isPackageSoldout, updateHasPackageEnded, hasLastEventInPackageEnded } = useContext(PackageDetailsContext)

    const [hasPermission, setHasPermission] = useState(true);
    const [invoices, setInvoices] = useState({})

    // invoice to release tickets from 
    const [invoice, setInvoice] = useState()

    // Release Tickets popup
    const [show, setShow] = useState(false)

    const [isReleasing, setIsReleasing] = useState(false)

    const [releaseAlert, setReleaseAlert] = useState({
        show: false,
        variant: 'danger',
        message: ''
    })

    useLayoutEffect(() => {
        const assignPackagesNavItem = document.getElementById('assign-packages-nav-item')
        if (!assignPackagesNavItem.classList.contains("active")) assignPackagesNavItem.classList.add('active')

        return () => {
            assignPackagesNavItem.classList.remove("active")
        }
    }, [])

    useEffect(() => {
        showLoading()
        loadInvoices()
    }, [])

    const loadInvoices = async () => {
        try {
            const packageData = await getPackage(uuid);

            safeSetState(() => {
                updatePackage(packageData.data);
                updateCanPublish(packageData?.data?.name, packageData?.data?.package_tickets)
            });

            const invoices = await getInvoices(uuid, '', 'all')

            safeSetState(() => {
                setInvoices(invoices.data)
                // update context
                updateIsPackageOnsale()
                updateHasPackageEnded(packageData?.data)
                updateIsPackageSoldout(packageData?.data)
                updateIsPackagePublished(packageData?.data)
                hideLoading()
            })
        } catch (err) {
            console.error(err)
            hideLoading()
        }
    }

    const releaseInvoiceTickets = async () => {
        setIsReleasing(true)
        closeAlert()
        await releasePackageSeats({ invoice }).then(() => {
            console.log("success")
            setIsReleasing(false)

            if (!isReleasing) handleClose()
        })
            .catch((error) => {
                console.log("Error", error)
                setReleaseAlert({
                    show: true,
                    variant: 'danger',
                    message: "Something went wrong. Unable to release the tickets. Please try again"
                });
                setIsReleasing(false)

            });
    }

    const closeAlert = () => {
        setReleaseAlert({ show: false, variant: 'danger', message: '' })
    }

    const handleShow = (invoice) => {
        setShow(true)
        setInvoice(invoice)
    }

    const handleClose = () => {
        setShow(false)
        setInvoice()
        closeAlert()
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className='position-relative'>
                        <section
                            className={`max-width-wrapper ${!hasPermission ? "overlay" : ""
                                }`}
                        >
                            {(!location.pathname.includes('/profile')) ? (
                                <section>
                                    <header className='section-header section-heading--flex section-heading section-heading--secondary'>
                                        <div className="section-heading">
                                            <h1>Assign Package</h1>
                                            <p className='section-header-desc'>Manually add your fan information for package tickets</p>
                                        </div>
                                        <Link className={`btn btn-outline-light btn-lg btn-plus ${isPackageSoldout || hasLastEventInPackageEnded ? 'btn-link-disabled' : ''}`} to={`/mypackage/${uuid}/seatmap/assign`}>Assign package</Link>
                                    </header>
                                    <section>
                                        {invoices?.length > 0 ? (
                                            <FansList packageId={uuid} invoices={invoices} hasLastEventInPackageEnded={hasLastEventInPackageEnded} isPackageSoldout={isPackageSoldout} handleShow={handleShow} />
                                        ) : (
                                            <Card body>
                                                <EmptyContainer style='center lg'>
                                                    <p>
                                                        No list created
                                                    </p>
                                                    <p className='subtitle'>
                                                        Click on Assign package above to add your first collection
                                                    </p>
                                                </EmptyContainer>
                                            </Card>
                                        )}
                                    </section>
                                </section>
                            ) : (
                                <AssignToFanProvider>
                                    <FromPageProvider>
                                        <FanProfileWrapper id={fanId} />
                                    </FromPageProvider>
                                </AssignToFanProvider>
                            )}
                        </section>

                        {!hasPermission && <NoPermissionsContainer />}
                    </div>

                    <ReleaseTicketsModal show={show} isReleasing={isReleasing} alert={releaseAlert} handleClose={handleClose} handleRelease={releaseInvoiceTickets} />
                </>
            )}
        </>
    )
}