import React, { useEffect, useState } from "react";
import { Rect } from "react-konva";
import chroma from "chroma-js";
import { mapStateManager } from "../../MapStateManager";

function Seat({ seat, getSeatState }) {
  const seatStatus = getSeatState(seat.seatId);
  const [seatState, setSeatState] = useState(mapStateManager.getState(seat.seatId));

  const darkenColor = (color, amount) => {
    return chroma(color).darken(amount).hex();
  };

  useEffect(() => {
    return mapStateManager.subscribe((updatedSeatId) => {
      if (updatedSeatId === seat.seatId) {
        setSeatState(mapStateManager.getState(seat.seatId))
      }
    })
  }, [seat])

  return (
    <Rect
      x={seat.cx}
      y={seat.cy}
      id={seat.seatId}
      cornerRadius={20}
      className='seat'
      width={seat.w}
      height={seat.h}
      fill={seatState?.selected && !seatStatus?.disabled ? darkenColor(seatStatus.color) : seatStatus.color}
      // Improved perf, hopefully
      transformsEnabled={"position"}
      perfectDrawEnabled={false}
      listening={!seatStatus?.disabled}
    ></Rect>
  );
}

export default React.memo(Seat);
