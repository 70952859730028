import React, { useState, useEffect, useContext } from 'react';

import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import {
    createOrEditRole, createOrEditMember, emailValid
} from '../../utilities/api'
import { checkPermission, formatMembers } from '../../utilities/helpers'

import { Roles } from "./Roles";
import { Team } from "./Team";

// wrapper for setting team management and sign up page 
export default function TeamRoleWrapper({ showRoles = true, showTeam = true, roles, updateRoles, members, updateMembers, permissions }) {

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [isCreating, setIsCreating] = useState(false)

    const [isInviting, setIsInviting] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 1));

    }, [orgPermissions])

    // create function that is a promise so button spinner works 
    const createRoles = (data) => {
        return new Promise((resolve, reject) => {
            setIsCreating(true)
            createOrEditRole({ data })
                .then((res) => {
                    updateRoles(res.data)
                    setIsCreating(false)
                    resolve()
                })
                .catch((err) => {
                    console.error(err)
                    setIsCreating(false)
                    reject();
                })
        })
    }

    // create function that is a promise so button spinner works 
    const inviteMember = (member) => {
        return new Promise((resolve, reject) => {
            setIsInviting(true)
            createOrEditMember({ member })
                .then((res) => {
                    updateMembers(formatMembers(res.data))
                    setIsInviting(false)
                    resolve()
                })
                .catch((err) => {
                    console.error(err)
                    setIsInviting(false)
                    reject()
                })
        })
    }

    return (
        <>
            {showRoles && (
                <Roles roles={roles} permissions={permissions} createRoles={createRoles} updateRoles={updateRoles}
                    hasPermission={hasPermission} isCreating={isCreating}
                />
            )}
            {showTeam && (
                <Team members={members} updateMembers={updateMembers} roles={roles} inviteMember={inviteMember}
                    hasPermission={hasPermission} emailValid={emailValid} isInviting={isInviting}
                />
            )}
        </>
    )
}
