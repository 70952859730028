import React, { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";

import "./updatingAlert.scss";

export default function UpdatingAlert({ isEventPublished, eventUUID }) {
    const ALERT_DURATION = 20000; // 20 seconds in milliseconds
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (!isEventPublished || !eventUUID) {
            return;
        }

    }, [isEventPublished, eventUUID]);

    if (!showAlert) {
        return null;
    }

    return (
        <Alert variant={"primary"} className='vjx-updating-alert align-items-start'>
            <p>
                Updating your event! Updates might take a few minutes until visible to
                users.
            </p>
        </Alert>
    );
}
