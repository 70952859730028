import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function ErrorModal({ show, handleClose }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" backdrop='static' show={show} onHide={handleClose} className='modal-sm'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Cannot Assign Seats</h1>
                    <p>You can only assign seats from one row or one section at a time...</p>
                </div>
                <Stack direction="horizontal" className="btn-group-flex btn-group-flex-justify">
                    <Button size="lg" onClick={handleClose}>Close</Button>
                </Stack>
            </Modal.Body>
        </Modal>

    );
}
