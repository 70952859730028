import React from 'react'

import Stack from 'react-bootstrap/Stack';

import { Seats } from './Seats'

export default function Panel({ selectedSeatIds, active, handleRemove }) {

    return (
        <div className='sidebar sidebar-sm sidebar-reset'>
            <div className="sidebar-wrapper sidebar-wrapper-xs">
                <h1 className='text-upper mb-3'>Selected</h1>
                <Stack as="ul">
                    <Seats selectedSeatIds={selectedSeatIds} handleRemove={handleRemove} />
                </Stack>
            </div>
        </div>
    )
}