import React from 'react'

import { DragOverlay } from '@dnd-kit/core';

import Stack from 'react-bootstrap/Stack';

import { DraggableSeats } from './DraggableSeats'
import { SeatIcon } from '../../../../../SeatIcon'

export default function Panel({ selectedSeatIds, active, handleRemove }) {

    return (
        <div className='sidebar sidebar-sm'>
            <div className="sidebar-wrapper sidebar-wrapper-xs">
                <h1 className='text-upper mb-3'>Selected</h1>
                <Stack as="ul">
                    <DraggableSeats selectedSeatIds={selectedSeatIds} handleRemove={handleRemove} />
                </Stack>

                <DragOverlay zIndex="200" dropAnimation={null}>
                    {active ? (
                        <SeatIcon />
                    ) : null}
                </DragOverlay>
            </div>

        </div>
    )
}