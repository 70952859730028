import React from 'react';

import { formatDateTime, getTimezoneDate } from '../../../../../utilities/helpers'

import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'

import { EditButton } from '../../../../EditButton';
import { TrashButton } from '../../../../TrashButton';

export default function Period({ period, timezone, selected, setSelected, isEditing, isDisabled, status, canEdit, canDelete, handleShow, handleShowDelete }) {

    const { id, name, starts, ends } = period;

    return (
        <>
            {isEditing ? (
                <Button
                    variant='default'
                    disabled={isDisabled}
                    className={`btn-list-view list-item list-item-xs ${canEdit ? 'btn-edit-after' : ''}`}
                    {...(canEdit && { onClick: (e) => handleShow(e, period) })}
                >
                    <div className={`lh-sm info-text info-text-sm info-text-${status}`}>
                        <Stack>
                            <span className='small-label lh-lg'>{name}</span>
                            <small className='subtitle'>
                                <span>{formatDateTime(getTimezoneDate(starts, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(starts, timezone), 'timeOnly')}</span></span><span className='to'>{formatDateTime(getTimezoneDate(ends, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(ends, timezone), 'timeOnly')}</span></span>
                            </small>
                        </Stack>
                    </div>
                </Button>
            ) : (
                <Stack as="li" direction='horizontal' className='justify-content-between list-item list-item-xs list-item--without-bg list-item--hover list-item--hover-lg'>
                    <div className='form-check--with-desc text-left'>
                        <Form.Check
                            name="availability_periods"
                            id={id}
                            type='radio'
                            label={name}
                            checked={id == selected}
                            onChange={() => setSelected(id)}
                        />
                        <div className="mt-2">
                            <small className='subtitle'>
                                <span>{formatDateTime(getTimezoneDate(starts, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(starts, timezone), 'timeOnly')}</span></span><span className='to'>{formatDateTime(getTimezoneDate(ends, timezone), 'dateOnly')}<span className='time'>{formatDateTime(getTimezoneDate(ends, timezone), 'timeOnly')}</span></span>
                            </small>
                        </div>
                    </div>
                    {(canEdit || canDelete) && (
                        <Stack direction='horizontal' gap={3} className='btn-actions'>
                            {canEdit && (
                                <EditButton
                                    obj={period}
                                    onClick={handleShow}
                                />
                            )}
                            {canDelete && (
                                <TrashButton
                                    id={period?.id}
                                    onClick={handleShowDelete}
                                />
                            )}
                        </Stack>
                    )}
                </Stack>
            )}
        </>
    );
}