import React, { useEffect, useLayoutEffect, useState } from 'react';

import { useFromPage } from '../../providers/FromPageProvider';
import { useAssignToFan } from '../../providers/AssignToFanProvider';

import Card from 'react-bootstrap/Card'
import Stack from 'react-bootstrap/Stack'

import { PageLoadingContainer } from '../PageLoadingContainer';
import { BackButton } from '../BackButton';
import { Details } from './Details';
import { TicketsPayments } from './TicketsPayments';
import { History } from './History';
import { getFanProfile } from '../../utilities/api';

export default function FanProfileWrapper({ id }) {

    const { fromAssignPackages } = useFromPage()

    const { getSeatsLocation } = useAssignToFan()

    const [isLoading, setIsLoading] = useState(false)

    const [fan, setFan] = useState({})

    useLayoutEffect(() => {
        const packageHoldersNavItem = document.getElementById('package-holders-nav-item')
        const assignPackagesNavItem = document.getElementById('assign-packages-nav-item')
        let navItem;
        if (fromAssignPackages) {
            navItem = assignPackagesNavItem
        } else {
            navItem = packageHoldersNavItem
        }
        if (!navItem.classList.contains("active")) navItem.classList.add('active')
    }, [])

    useEffect(() => {
        setIsLoading(true)
        loadFanProfile()
    }, [])

    const loadFanProfile = () => {
        getFanProfile(id)
            .then((res) => {
                setFan(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <Stack gap={5}>
                        <div className="align-self-start">
                            <BackButton size="sm" />
                        </div>
                        <header className='section-header mb-0 section-heading section-heading--secondary'>
                            <div className="section-heading">
                                <h1>Fan profile</h1>
                            </div>
                        </header>
                        <Card body className='card--md card--light'>
                            <Details fan={fan} />
                        </Card>
                        <Card body className='card--md card--light'>
                            <TicketsPayments data={fan} getSeatsLocation={getSeatsLocation} />
                        </Card>
                        {/* <Card body className='card--md card--light'>
                            <History history={fan} />
                        </Card> */}
                    </Stack>
                </>
            )}
        </>
    )
}