import React from "react";
import ReactPaginate from 'react-paginate';

import Stack from "react-bootstrap/Stack"
import Form from "react-bootstrap/Form"

import chevron from '../../assets/icons/chevron.svg'

import './pagination.scss';

export default function Pagination({ pageCount, perPage, currentPage, resultsPerPageOpts, handleClick, handleChange }) {

    return (
        <>
            <Stack direction="horizontal" className="mt-4 position-relative">
                <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={3}
                    nextLabel={<img src={chevron} alt="" />}
                    previousLabel={<img src={chevron} alt="" />}
                    pageCount={pageCount}
                    forcePage={currentPage}
                    onPageChange={handleClick}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination-container"
                    pageClassName="page-item"
                    pageLinkClassName="page-item__link"
                    activeClassName="page-item-active"
                    activeLinkClassName="page-item__link-active"
                    nextClassName="navigation-button"
                    previousClassName="navigation-button"
                    nextLinkClassName="navigation-button__link next"
                    previousLinkClassName="navigation-button__link prev"
                    disabledLinkClassName="navigation-button__link-disabled"
                />
                <Stack direction="horizontal" gap={3} id="results-per-page-container">
                    <span className="fw-medium small flex-shrink-0">Results per page</span>
                    <Form.Select value={perPage} onChange={(e) => handleChange(parseInt(e.target.value))} aria-label="Orders per page">
                        {resultsPerPageOpts.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </Form.Select>
                </Stack>
            </Stack>
        </>
    )
}