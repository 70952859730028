import React from 'react';
import moment from 'moment'

import { getTimezoneDate, getFormattedTimezoneDate, formatShortDate, isISOString } from '../../../../../utilities/helpers';

import { WarningCard } from '../../../../WarningCard';

export default function MatchWarningError({ periodStarts, periodEnds, willOverlapStandardAdmission, hasExactError, hasInBetweenError, matchedOffers, timezone }) {

    // start and end date can either be in ISO format or string (availability section) or a date object (create period modal)
    const getPeriodDate = (date) => {
        if (isISOString(date) || typeof date === 'string') {
            return getTimezoneDate(date, timezone)
        } else {
            return moment(date)
        }
    }

    const getStart = (matchedOfferStarts, periodStarts) => {
        // if start is before matched start, take matched start 
        if (moment(getPeriodDate(periodStarts)).isBefore(moment(getFormattedTimezoneDate(matchedOfferStarts, timezone)))) {
            return matchedOfferStarts
        } else {
            return periodStarts
        }
    }
    // Find the largest end date 
    const largestEndDate = matchedOffers?.map(offer => getPeriodDate(offer?.period?.ends)) // Convert each end date to a Date object (in timezone)
        .reduce((max, date) => (date > max ? date : max), new Date(0)); // Find the latest date - initially comparing it to the earliest date 

    // Find the smallest start date 
    const smallestStartDate = matchedOffers
        ?.map(offer => getPeriodDate(offer?.period?.starts)) // Convert from each start date (in timezone)
        .reduce((min, date) => (date < min ? date : min), new Date(8640000000000000)); // "Infinity" future date as initial comparison

    const getText = (hasExactError, hasInBetweenError, willOverlapStandardAdmission) => {
        // has errors 
        if (hasInBetweenError || hasExactError) {
            if (hasExactError) {
                return `Period cannot be the same as ${matchedOffers[0]?.name} period`
            } else {
                return `Period cannot be between ${matchedOffers?.map(offer => offer.name).join(', ')} offer${matchedOffers?.length > 1 ? 's' : ''}: ${formatShortDate(moment(smallestStartDate))} and ${formatShortDate(moment(largestEndDate))}`
            }
            // no errors
        } else {
            // overlap with Standard Admission 
            if (willOverlapStandardAdmission) {
                return `Period will overlap ${matchedOffers[0]?.name} between ${formatShortDate(getPeriodDate(getStart(matchedOffers[0]?.period?.starts, periodStarts)))} and ${formatShortDate(getPeriodDate(periodEnds))}`
            }
        }
    }

    return (
        <WarningCard text={getText(hasExactError, hasInBetweenError, willOverlapStandardAdmission)} variant={(hasExactError || hasInBetweenError) ? 'danger' : 'primary'} />
    );
}