import React from 'react';
import { isEqual } from 'lodash';

import { getFormattedTimezoneDate } from '../../../utilities/helpers';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { EventVisibility } from '../../EventVisibility';
import { Spinner } from '../../LoadingContainer/Spinner';

export default function EventVisibilityModal({ show, event, initialEventVisibility, eventVisibility, setEventVisibility, generalOnsale, eventStart, error, errorMsg, isSaving, handleSubmit, handleCancel }) {

    const checkIsDisabled = () => {
        if (event?.timezone && initialEventVisibility) {
            return (isEqual(eventVisibility, initialEventVisibility) || isEqual(new Date(getFormattedTimezoneDate(initialEventVisibility, event?.timezone)), eventVisibility))
        }
    }

    return (
        <Modal centered animation={false} show={show} onHide={handleCancel} backdrop="static" className='modal-lg'>
            <Modal.Body>
                <EventVisibility event={event} eventVisibility={eventVisibility} setEventVisibility={setEventVisibility} generalOnsale={generalOnsale} eventStart={eventStart} error={error} errorMsg={errorMsg} />
                <Stack direction="horizontal" className='btn-group-flex'
                >
                    <Button variant="outline-light"
                        size="lg"
                        onClick={handleCancel}>Cancel</Button>
                    <Button
                        disabled={checkIsDisabled() || error || isSaving}
                        size="lg"
                        className='btn-width-xs'
                        onClick={handleSubmit}

                    >{isSaving ? (
                        <Spinner />
                    ) : 'Apply'
                        }
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
