import React from "react";

import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Spinner } from "../../../../../LoadingContainer/Spinner";

export default function DeleteModal({
  fromObj,
  show,
  handleClose,
  isRemoving,
  handleDelete,
}) {
  return (
    <Modal
      className='delete-modal'
      centered
      animation={false}
      fullscreen='md-down'
      show={show}
      onHide={handleClose}
      backdrop='static'
    >
      <Modal.Body>
        <h1 className='modal-body-heading-title'>Delete</h1>
        <p>
          Are you sure you want to delete <strong>{fromObj?.name}</strong>? This
          action cannot be undone.
        </p>
        <Stack className='btn-group-flex'>
          <Button
            variant='outline-light'
            size='lg'
            className='text-danger'
            onClick={() => handleDelete()}
            disabled={isRemoving}
          >
            {isRemoving ? <Spinner variant='dark' /> : "Delete"}
          </Button>
          <Button size='lg' onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
