import React from 'react';

import { formatDateTime, formatNumber, getSinglarOrPluralWord, getTimezoneDate } from '../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';

export default function BannerInfo({ obj, start, end, isEvent }) {

    return (
        <>
            {(obj?.name || start) && (
                <Stack gap={1} className="event-details">
                    <h1 className='normal m-0 event-title'>{obj?.name}</h1>
                    <span className='subtitle subtitle--dark m-0'>
                        {obj?.events && (
                            <span>{formatNumber(obj?.events?.length)} {getSinglarOrPluralWord(obj?.events?.length, 'Event')}</span>
                        )}
                        <span className={`${!isEvent ? 'start' : ''}`}>{formatDateTime(getTimezoneDate(start, obj?.timezone), 'dateOnly')}</span>
                        {isEvent && (<span className='time'>{formatDateTime(getTimezoneDate(start, obj?.timezone), 'timeOnly')}</span>)}
                        {!isEvent && (<span className='to'>{formatDateTime(getTimezoneDate(end, obj?.timezone), 'dateOnly')}</span>)}
                    </span>
                </Stack>
            )}
        </>
    );
}
