import React, { useState, useEffect } from 'react';

import { usePagination } from '../../../providers/PaginationProvider/PaginationProvider';

import { getPackageHolders } from '../../../utilities/api';

import { checkPermission } from "../../../utilities/helpers";

import Card from 'react-bootstrap/Card';

import { SearchBar } from '../../SearchBar';
import { ExportBtn } from "../../ExportBtn";
import { LoadingContainer } from '../../LoadingContainer';
import { Holders } from './Holders';
import { Pagination } from '../../Pagination';
import { EmptyContainer } from '../../EmptyContainer';

export default function HoldersList({ packageId, packageHolders }) {

    const { start, setStart, end, setEnd, currentPage, setCurrentPage, perPage, pageCount, setPageCount, resultsPerPageOpts, handleChange } = usePagination()

    const [isLoading, setIsLoading] = useState(false)

    // search query
    const [
        query,
        setQuery
    ] = useState('');

    // package holders with filters applied
    const [filteredPackageHolders, setFilteredPackageHolders] = useState(packageHolders);

    // set total amount of pages and data 
    useEffect(() => {
        const end = start + perPage;
        setEnd(end)
        setFilteredPackageHolders(packageHolders?.slice(start, end))
        setPageCount(Math.ceil(packageHolders?.length / perPage))
    }, [packageHolders, start, perPage])

    useEffect(() => {
        setIsLoading(true)
        getFilteredPackageHolders()
    }, [query])

    const getFilteredPackageHolders = () => {
        getPackageHolders(packageId, query)
            .then((res) => {
                setFilteredPackageHolders(res.data.slice(start, end))
                setIsLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }

    // Invoke when user click to request another page.
    const handlePageClick = (e) => {
        const newStart = (e.selected * perPage) % packageHolders?.data?.length;
        setStart(newStart);
        setCurrentPage(e.selected)
    };

    return (
        <>
            <header className='section-header'>
                <div className="actions-group-flex">
                    <SearchBar getQuery={setQuery} placeholder="Search by phone number or email" />
                    <ExportBtn data={filteredPackageHolders} exportedData='package-holders' />
                </div>
            </header>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    {filteredPackageHolders?.length > 0 ? (
                        <>
                            <Holders packageHolders={packageHolders} />
                            <Pagination pageCount={pageCount} perPage={perPage} currentPage={currentPage} resultsPerPageOpts={resultsPerPageOpts} handleClick={handlePageClick} handleChange={handleChange} />
                        </>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No package holders found{query && ` matching '${query}'`}. Please try again.</p>
                            </EmptyContainer>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}
