import { Layer, Path, Group } from "react-konva";
import { Seat } from "./Seat";
import React, { useCallback, useState } from "react";
import { mapStateManager } from "../MapStateManager";

const Seats = React.memo(
    ({ data, isDisabled, selectRegularSeats, activeMapAction, getSeatState }) => {
        const [hoveredRowId, setHoveredRowId] = useState(null);
        const handleRowAction = (rowId, hovering) => {
            if (activeMapAction !== 2) return;
            setHoveredRowId(hovering ? rowId : null);
        };

        const selectSeat = (id) => {
            if (data.seats[id]) {
                selectRegularSeats(id);
            }
        };

        const seatMouseToggle = useCallback((seatId, hovering) => {
            mapStateManager.setState(seatId, { selected: hovering });
        }, []);

        const handleRowSelect = (rowId) => {
            if (activeMapAction !== 2) return;

            let rowSeatIds = getSeatIdsForRow(rowId, data.rows, data.seats);

            selectRegularSeats(undefined, rowSeatIds);
        };

        const getSeatIdsForRow = (rowId, rows, seats) => {
            const targetRow = rows[rowId];
            if (!targetRow) return []; // Return empty array if the row is not found

            return targetRow.seats.filter((seatId) => {
                if (!seats[seatId]) {
                    return false;
                }
                return true;
            });
        };

        const handleLayerMouseEnter = (e) => {
            // if seat is disabled don't change hover of seat
            if (isDisabled) return

            if (activeMapAction === 2 || activeMapAction === 1) return;
            const shape = e.target;

            // Clear all hovers if we're not over a seat
            if (!shape || shape.attrs?.className !== "seat") {
                mapStateManager.resetState();
                return;
            }

            if (shape.attrs?.className === "seat") {
                const seatId = shape.attrs.id;
                seatMouseToggle(seatId, true);
            }
        };

        const handleLayerMouseLeave = () => {
            // if seat is disabled don't reset state
            if (isDisabled) return

            if (activeMapAction === 2 || activeMapAction === 1) return;
            mapStateManager.resetState();
        };

        const handleLayerClick = (e) => {
            if (activeMapAction === 2 || activeMapAction === 1) return;
            const shape = e.target;
            if (shape.attrs?.className === "seat") {
                const seatId = shape.attrs.id;
                selectSeat(seatId);
            }
        };

        return (
            <Layer
                className='seats'
                onMouseEnter={handleLayerMouseEnter}
                onMouseLeave={handleLayerMouseLeave}
                onClick={handleLayerClick}
            >
                {data?.rows &&
                    Object.values(data.rows).map((row) => (
                        <Group key={row.rowId}>
                            {row.seats.map((seatId) => {
                                const seat = data.seats[seatId];
                                return (
                                    <Seat
                                        key={seat.seatId}
                                        seat={seat}
                                        getSeatState={getSeatState}
                                    />
                                );
                            })}
                            {/* This is for selecting by row, could still delegate the events to the layer, but its fine for now */}
                            {row?.path && (
                                <Path
                                    data={row.path}
                                    listening={activeMapAction === 2}
                                    onClick={() => handleRowSelect(row.rowId)}
                                    onMouseOver={(e) => handleRowAction(row.rowId, true)}
                                    onMouseOut={(e) => handleRowAction(row.rowId, false)}
                                    opacity={hoveredRowId === row.rowId ? 0.2 : 0}
                                    fill='#3E8BF7'
                                />
                            )}
                        </Group>
                    ))}
            </Layer>
        );
    }
);

export default Seats;
