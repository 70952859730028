import React, {
    Suspense,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
    lazy,
} from "react";
import { useNavigate } from "react-router-dom";
import isEqual from 'lodash/isEqual'
import moment from "moment";

import EventDetailsContext from "../../context/EventDetails/EventDetails";
import PackageDetailsContext from "../../context/PackageDetails/PackageDetails";

import { publishEvent, publishPackage } from "../../utilities/api";

import { getNowInTimezone, getTimezoneDate, getFormattedTimezoneDate, isISOString, isTimeAfterGeneralOnsale, getIsTimeAfterGeneralOnsaleErrorMsg, isTimeAfterStart, getIsTimeAfterStartErrorMsg } from "../../utilities/helpers";

import { PublishModal } from "./PublishModal";

// Create the context
const BannerContext = createContext(undefined);

export const useBanner = () => {
    const context = useContext(BannerContext);
    if (!context) {
        throw new Error("Context must be used within a useBanner");
    }
    return context;
};

// Reuse banner functionality 
export const BannerProvider = ({ children }) => {

    const { canPublish: canPublishEvent, isEventPublishing, updateIsEventPublishing, isEventPublished, updateIsEventPublished, updateEvent, updateIsEventOnsale, eventVisibility, storeEventVisibility, hasEventEnded, isEventSoldout, isEventOnsale } = useContext(EventDetailsContext)

    const { canPublish: canPublishPackage, packageVisibility, storePackageVisibility, isPackagePublished, updatePackage, updateIsPackagePublished, updateIsPackageOnsale, hasPackageEnded, isPackageSoldout, isPackageOnsale } = useContext(PackageDetailsContext)

    const navigate = useNavigate()

    const [initialState, setInitialState] = useState()

    const [isEvent, setIsEvent] = useState(false)

    const [start, setStart] = useState()

    const [end, setEnd] = useState()

    const [show, setShow] = useState(false)

    const [showSchedule, setShowSchedule] = useState(false)

    const [status, setStatus] = useState();

    const [step, setStep] = useState(1)

    const [isScheduled, setIsScheduled] = useState(false)

    // same as visibility date but only use visibility to show in banner 
    // publish date is used for logic 
    const [publishDate, setPublishDate] = useState()

    // local state for manipulation before publishing
    const [publishDateLocal, setPublishDateLocal] = useState(null)

    const [timeError, setTimeError] = useState(false)

    const [timeErrorMsg, setTimeErrorMsg] = useState('')

    const [isSaving, setIsSaving] = useState(false)

    const [obj, setObj] = useState()

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    // format publish date local to correct timezone only when date is in ISO format 
    // will only be set to IOS string when reset to initial state and on initial render - same as publish date 
    // to diplay in date and time fields 
    // useEffect(() => {
    //     if (publishDate && isISOString(publishDate) && obj?.timezone) {
    //         console.log('reset', publishDate, initialState?.publishDate, obj?.timezone, new Date(getFormattedTimezoneDate(initialState?.publishDate, obj?.timezone)));
    //         setPublishDateLocal(new Date(getFormattedTimezoneDate(initialState?.publishDate, obj?.timezone)))
    //     }
    // }, [initialState, publishDate, obj])

    // validation for publish date
    // event visibility - cannot be after general onsale date
    // package visibility - cannot be after start date
    useEffect(() => {
        if (!isEventPublished && !isPackagePublished) {
            setTimeError(isEvent ? isTimeAfterGeneralOnsale(publishDateLocal, obj) : isTimeAfterStart(publishDateLocal, obj))
            setTimeErrorMsg(getErrorMsg())
        } else {
            setTimeError(false)
            setTimeErrorMsg('')
        }
    }, [isEvent, publishDateLocal, isEventPublished, isPackagePublished])

    // validation for event visibility:
    // event visibility - cannot be after general onsale start date
    // package visibility - cannot be after start date
    const getErrorMsg = () => {
        if (isEvent) {
            if (isTimeAfterGeneralOnsale(publishDateLocal, obj)) {
                return getIsTimeAfterGeneralOnsaleErrorMsg(obj)
            }
            else return ''
        } else {
            if (isTimeAfterStart(publishDateLocal, obj)) {
                return getIsTimeAfterStartErrorMsg(obj, undefined, isEvent)
            } else return ''
        }
    }

    const isAfter = (date = publishDate) => {
        // is after today's date - not visible yet 
        if (date) {
            let publishDate;

            // date is sometimes already in UTC format (already converted to timezone) eg. when scheduling publishing from event details
            // so check date format 
            if (isISOString(date)) {
                publishDate = moment(date);

            } else {
                publishDate = getTimezoneDate(date, obj?.timezone, true)
            }
            return publishDate.isAfter(getNowInTimezone(obj?.timezone))
        } else {
            return false
        }
    }

    // get obj status: published, onsale, scheduled
    const getStatus = (obj, isScheduled) => {
        const getActiveAvailabilityPeriod = (offers) => {
            let activePeriods = [];
            offers?.map(offer => {
                activePeriods = [...activePeriods, ...offer?.availability?.filter(period => period.active)]
            })
            return activePeriods;
        }

        // not scheduled - either published or onsale  
        if (!isScheduled) {
            let isOnsale;

            if (isEvent) {
                const activePeriods = getActiveAvailabilityPeriod(obj?.offers);

                // check to see if any event offers are onsale (same or before now)
                isOnsale = activePeriods.some(period => getTimezoneDate(period.starts, obj.timezone).isSameOrBefore(getNowInTimezone(obj.timezone)))
            } else {
                // check if start date is same or before now 
                isOnsale = getTimezoneDate(obj.start, obj.timezone).isSameOrBefore(getNowInTimezone(obj.timezone))
            }

            return isOnsale ? 'on_sale' : 'published'
        }

        return 'scheduled'
    }

    const updateContext = (obj) => {
        if (isEvent) {
            updateEvent(obj)
            updateIsEventPublishing(obj)
            updateIsEventOnsale(obj)
            updateIsEventPublished(obj)
            storeEventVisibility(obj.eventVisibility)
        } else {
            updatePackage(obj)
            updateIsPackageOnsale(obj)
            updateIsPackagePublished(obj)
            storePackageVisibility(obj.visibility)
        }
    }

    // publish 
    const handlePublish = (publishDate = publishDateLocal, isScheduledArg = isScheduled, isPublishNow = false) => {
        publish(publishDate, isScheduledArg, isPublishNow).then((res) => {
            updateContext(res?.data)
        })
    }

    const publish = (publishDate, isScheduled, isPublishNow) => {
        return new Promise((resolve, reject) => {
            setIsSaving(true)
            let data = {};
            // if not scheduled and no visibility date or set to publish now ('as soon as publish'), set visibility to current date 
            if (!isScheduled) {
                if (!publishDate || isPublishNow) {
                    publishDate = getNowInTimezone(obj?.timezone)
                }
            }

            let date;
            // publish date is sometimes already in UTC format (already converted to timezone) eg. date is set from event/package details 
            // so check date format 
            if (isISOString(publishDate)) {
                date = moment(publishDate)
            } else {
                date = getTimezoneDate(publishDate, obj?.timezone, !isPublishNow).format();
            }
            data['status'] = getStatus(obj, isScheduled)
            // Remove tickets so payload isn't too large
            // delete event?.tickets;

            let handler;

            if (isEvent) {
                data['event'] = {
                    ...obj,
                    eventVisibility: date
                };
                handler = publishEvent
            } else {
                data['eventPackage'] = {
                    ...obj,
                    visibility: date
                }
                handler = publishPackage
            }

            handler({ data })
                .then((res) => {
                    if (!isSaving) {
                        setIsSaving(false)
                        handleClose()
                        handleCloseSchedule()
                        resolve(res)
                    }
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info. Please try again.'
                    })
                })
        })
    }

    // publish modal 
    const handleClose = () => {
        setShow(false)
        setStep(1)
        // reset alert
        if (alert.show) {
            setAlert({
                show: false,
                variant: '',
                message: ''
            })
        }
    }

    const handleCancel = () => {
        handleClose()
        setPublishDateLocal(resetPublishDate())
        setIsScheduled(initialState?.isScheduled)
    }

    const handleEdit = () => {
        navigate(`/my${isEvent ? 'event' : 'package'}/${obj?.uuid}/seatmap`)
        setShow(false)
    }

    const checkDisabled = () => {
        // step 2 and scheduled
        // is disabled if null or visibility is same as in context 
        // visibility in context is utc (convert to timezone) and visibility is in local time (already started from timezone)
        if (step === 2 && isScheduled) {
            return (isEqual(publishDate, publishDateLocal) || isEqual(new Date(getFormattedTimezoneDate(publishDate, obj?.timezone)), publishDateLocal))
        } return false
    }

    // format publish date local to correct timezone only when date is in ISO format to display in date and time fields
    const resetPublishDate = () => {
        return isISOString(initialState?.publishDate) ? new Date(getFormattedTimezoneDate(initialState?.publishDate, obj?.timezone)) : initialState?.publishDate
    }

    const handleVisibility = (isScheduled) => {
        setIsScheduled(isScheduled)
        // publish now option
        if (!isScheduled) {
            setPublishDateLocal(resetPublishDate())
        }
    }

    const handleNext = () => {
        if (step === 3) handlePublish(undefined, undefined, !isScheduled)
        else setStep((prevStep => parseInt(prevStep) + 1))
    }

    // schedule event visiblity modal 
    const handleCloseSchedule = () => {
        setShowSchedule(false)
    }

    return (
        <BannerContext.Provider
            value={{
                initialState,
                setInitialState,
                setShow,
                obj,
                setObj,
                start,
                setStart,
                end,
                setEnd,
                visibility: eventVisibility || packageVisibility,
                isEvent,
                setIsEvent,
                status,
                setStatus,
                isScheduled,
                setIsScheduled,
                publishDate,
                setPublishDate,
                publishDateLocal,
                setPublishDateLocal,
                isSaving,
                setIsSaving,
                isAfter,
                handlePublish,
                showSchedule,
                setShowSchedule,
                handleCloseSchedule,
                resetPublishDate,
                canPublish: canPublishEvent || canPublishPackage,
                isPublishing: isEventPublishing,
                isPublished: isEventPublished || isPackagePublished,
                hasEnded: hasEventEnded || hasPackageEnded,
                isSoldout: isEventSoldout || isPackageSoldout,
                isOnsale: isEventOnsale || isPackageOnsale,
                timeError,
                timeErrorMsg,
                update: isEvent ? updateEvent : updatePackage
            }}
        >
            {children}

            <PublishModal show={show} isEvent={isEvent} obj={obj} step={step} isScheduled={isScheduled} start={start} publishDate={publishDateLocal} setPublishDate={setPublishDateLocal} error={timeError} errorMsg={timeErrorMsg} isDisabled={checkDisabled()} isSaving={isSaving} alert={alert} handleVisibility={handleVisibility} handleEdit={handleEdit} handleNext={handleNext} handleCancel={handleCancel} />
        </BannerContext.Provider>
    );
};
