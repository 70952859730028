import React, { useContext, useEffect } from 'react';

import { useBanner } from '../../providers/BannerProvider/BannerProvider';
import EventDetailsContext from '../../context/EventDetails/EventDetails';

import { updateEventVisibility } from '../../utilities/api';

import { getTimezoneDate } from '../../utilities/helpers';

import { EventVisibilityModal } from './EventVisibilityModal';
import { Banner } from '../Banner';

export default function EventBanner({ eventId }) {

    const { setIsEvent, setStart, publishDate, publishDateLocal, setPublishDateLocal, showSchedule, isAfter, setObj, setPublishDate, handlePublish, timeError, timeErrorMsg, isSaving, setIsSaving, handleCloseSchedule, resetPublishDate } = useBanner()

    const { event, updateEvent, eventStart, eventVisibility, generalOnsale, canPublish, storeEventVisibility } = useContext(EventDetailsContext)

    useEffect(() => {
        setObj(event)
        setPublishDate(eventVisibility)
        setStart(eventStart)
        setIsEvent(Boolean(eventId))
    }, [event, eventVisibility, eventStart])

    // schedule event visiblity modal 
    const handleCancel = () => {
        handleCloseSchedule()
        setPublishDateLocal(resetPublishDate())
    }

    // schedule event visibility
    const handleSubmit = () => {
        const isScheduledLocal = isAfter(publishDateLocal)
        console.log('is scheduled', isScheduledLocal);
        // not scheduled
        if (!isScheduledLocal) {
            // not scheduled, if can publish, publish event - status will be published or on sale 
            if (canPublish) handlePublish(publishDateLocal, isScheduledLocal)
        }
        // scheduled 
        else {
            setIsSaving(true)
            // update event visibility 
            updateEventVisibility({
                data: {
                    id: event?.uuid,
                    visibility: getTimezoneDate(publishDateLocal, event?.timezone, true).format()
                }
            }).then(res => {
                // update context 
                updateEvent(res?.data)
                storeEventVisibility(publishDateLocal)
                setIsSaving(false)
                handleCloseSchedule()
            })
        }
    }

    return (
        <>
            <Banner />

            <EventVisibilityModal show={showSchedule} event={event} initialEventVisibility={publishDate} eventVisibility={publishDateLocal} setEventVisibility={setPublishDateLocal} generalOnsale={new Date(getTimezoneDate(generalOnsale, event?.timezone))} eventStart={new Date(getTimezoneDate(eventStart, event?.timezone))} error={timeError} errorMsg={timeErrorMsg} isSaving={isSaving} handleSubmit={handleSubmit} handleCancel={handleCancel} />
        </>
    );
}
