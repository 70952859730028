import React, { useState, useEffect } from 'react';
import moment from 'moment'

import { eventsForReport } from '../../../utilities/api';

import { formatDateTime, formatNumber } from '../../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';

import { SearchBar } from '../../SearchBar';
import { LoadingContainer } from '../../LoadingContainer';
import { EmptyContainer } from '../../EmptyContainer';

export default function SelectEventsCard({ isLoading, events, filteredEvents, eventStatusOpts, eventStatus, setEventStatus, query, setQuery, handleCheck, selected, handleSelect }) {

    const [open, setOpen] = useState(false)

    const getLabel = event => {
        return `${event?.name} \u2022 ${event?.venue?.name} `
    }

    const getText = () => {
        const obj = eventStatusOpts.find(opt => opt.value === eventStatus)

        if (eventStatus == 'all') {
            return
        } else {
            return obj.value
        }
    }

    return (
        <>
            <Card body id="events-card" className={`card--sm ${open ? 'card--open' : ''}`}>
                <Button variant="default" className="btn-toggle w-100"
                    onClick={() => setOpen(!open)}
                    aria-controls="select-events"
                    aria-expanded={open}
                >
                    <h1 className='card-body-title'>Events selected ({formatNumber(selected?.length) || 0})</h1>
                </Button>
                <Collapse in={open}>
                    <div>
                        <div className="card-section card-section--with-flex-header h-100">
                            <div className='split-row'>
                                <div className="heading--flex">
                                    <h2 className='heading'>Events</h2>
                                    <Button
                                        variant='link'
                                        onClick={() => handleSelect('events', 'all')}
                                        disabled={filteredEvents?.every(event => selected?.includes(event.uuid))}
                                        className="select-label">
                                        Select all
                                    </Button>
                                    <Button
                                        variant='link'
                                        onClick={() => handleSelect('events')}
                                        disabled={selected?.length === 0}
                                        className="select-label">
                                        Deselect all
                                    </Button>
                                </div>
                                <Form.Select id="events-select" onChange={(e) => setEventStatus(e.target.value)} aria-label="Filter events" defaultValue={eventStatus}>
                                    {eventStatusOpts.map((opt, index) => (
                                        <option key={index} value={opt.value}>{opt.label}</option>
                                    ))}
                                </Form.Select>
                            </div>

                            <div className="w-25 mb-3">
                                <SearchBar getQuery={setQuery} placeholder="Search for events" size="sm" />
                            </div>
                            <div id="event-list" className={`h-100 ${(!isLoading && filteredEvents?.length > 0) ? 'd-flex' : ''}`}>
                                {isLoading ? (
                                    <LoadingContainer />
                                ) : (
                                    <>
                                        {filteredEvents?.length > 0 ? (
                                            <ul>
                                                {filteredEvents.map(event => (
                                                    <li key={event.id} className="d-flex align-items-center gap-4">
                                                        <Form.Check
                                                            className='d-flex align-items-center mb-0 gap-2'
                                                            label={getLabel(event)}
                                                            type="checkbox"
                                                            checked={selected.includes(event?.uuid)}
                                                            id={event?.uuid}
                                                            name="event"
                                                            onChange={(e) => handleCheck(e, 'events')}
                                                        />
                                                        <small className='text-muted'>{formatDateTime(moment(event?.start), 'dateOnly')}</small>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <EmptyContainer style="center lg">
                                                <p>No {getText()} events found{query && ` matching '${query}'`}. Please try again.</p>
                                            </EmptyContainer>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Card>
        </>
    );
}
