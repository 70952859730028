import React from 'react';

import { formatCurrency, capitalizeString, getTicketTransactionType, getOfferName } from '../../../utilities/helpers';

export default function TicketRow({ ticket, ticketBuyer, ticketCount, total }) {

    return (
        <tr>
            <td>
                {capitalizeString(ticketBuyer)}
            </td>
            <td>
                1
            </td>
            <td className='text-capitalize'>{getTicketTransactionType(ticket)}</td>
            <td>
                {getOfferName(ticket)}
            </td>
            <td>
                {total === 0 ? formatCurrency(0) : formatCurrency(total / ticketCount)}
            </td>
        </tr>
    );
}
