import React from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DateInputWrapper } from '../DateInputWrapper';
import { TimeInputWrapper } from '../TimeInputWrapper';

// used in event modal -> event details and schedule/reschedule event visibility -> event banner 
export default function EventVisibility({ timezone, eventVisibility, setEventVisibility, generalOnsale, eventStart, error, errorMsg }) {

    return (
        <>
            <div className="modal-body-heading">
                <h1 className='modal-body-heading-title'>Edit event visibility</h1>
            </div>
            <Form>
                <fieldset className='form-group'>
                    <legend className='sm'>Start date and time</legend>
                    <Row>
                        <Col>
                            <DateInputWrapper
                                id="event-visibility-start"
                                setDate={setEventVisibility}
                                selectedDate={eventVisibility}
                                startDate={eventVisibility}
                                maxDate={generalOnsale || eventStart}
                                size="sm"
                            />
                        </Col>
                        <Col>
                            <TimeInputWrapper
                                id="event-visibility-start-time"
                                timezone={timezone}
                                setDate={setEventVisibility}
                                selectedDate={eventVisibility}
                                error={error}
                                errorMsg={errorMsg}
                                size="sm"
                            />
                        </Col>
                    </Row>
                </fieldset>
            </Form>
        </>
    );
}
