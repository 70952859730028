import React from "react";

import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { SeatMapNavigation } from "../../../SeatMapNavigation";

import "./navigation.scss";

export default function Navigation({
    event,
    isEventPublished,
    hasEventEnded,
    isSaved,
    configuration,
    templateName,
    activeTab,
    setActiveTab,
    unassignedSeats,
    handleShowResetConfiguration,
    handleShowSave,
    handleLeave,
}) {

    const handleClick = () => {
        setActiveTab("inventory");
    };

    return (
        <SeatMapNavigation event={event} configuration={configuration} templateName={templateName} isDisabled={(isEventPublished || hasEventEnded)} handleLeave={handleLeave} handleShowReset={handleShowResetConfiguration}>
            <Stack
                direction='horizontal'
                as='ul'
                className='gap-5 tabs small flex-grow-1 justify-content-center'
            >
                <li className={`tab ${activeTab === "scaling" ? "active" : ""}`}>
                    <Button
                        variant='link'
                        className='text-reset'
                        onClick={() => setActiveTab("scaling")}
                    >
                        Scaling
                    </Button>
                </li>
                <li className={`tab ${activeTab === "inventory" ? "active" : ""}`}>
                    <Button
                        variant='link'
                        className={`text-reset ${unassignedSeats && unassignedSeats?.seats.length > 0
                            ? "btn-link-disabled btn-link-disabled--with-cursor"
                            : ""
                            }`}
                        {...(unassignedSeats && unassignedSeats?.seats.length === 0 && {
                            onClick: handleClick,
                        })}
                    >
                        Inventory
                    </Button>
                </li>
            </Stack>
        </SeatMapNavigation>
    );
}
