import { useParams } from 'react-router-dom';

import { PackageDashboardWrapper } from "../../components";

export default function PackageDashboardPage() {

    let { uuid } = useParams();

    return (
        <PackageDashboardWrapper packageId={uuid} />
    )
}