import React, { useLayoutEffect, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';
import { FromPageProvider } from '../../providers/FromPageProvider';
import { AssignToFanProvider } from '../../providers/AssignToFanProvider/AssignToFanProvider';

import { getPackageHolders } from '../../utilities/api';

import Card from 'react-bootstrap/Card'

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';
import { HoldersList } from './HoldersList';
import { FanProfileWrapper } from '../FanProfileWrapper';
import useSafeAsync from '../../utilities/useSafeAsync';

export default function PackageHoldersWrapper({ uuid, fanId }) {
    const safeSetState = useSafeAsync();
    const location = useLocation()

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { updateIsPackagePublished, updateIsPackageOnsale, updateIsPackageSoldout, updateHasPackageEnded } = useContext(PackageDetailsContext)

    const [hasPermission, setHasPermission] = useState(true);

    const [packageHolders, setPackageHolders] = useState({})

    useLayoutEffect(() => {
        const packageHoldersNavItem = document.getElementById('package-holders-nav-item')
        if (!packageHoldersNavItem.classList.contains("active")) packageHoldersNavItem.classList.add('active')

        return () => {
            packageHoldersNavItem.classList.remove("active")
        }
    }, [])

    useEffect(() => {
        showLoading()
        loadPackageHolders()
    }, [])

    const loadPackageHolders = () => {
        getPackageHolders(uuid, '')
            .then((res) => {
                safeSetState(() => {
                    setPackageHolders(res.data)
                    console.log("packageHolders: ", res.data)
                    // update context 
                    updateIsPackageOnsale()
                    updateHasPackageEnded()
                    updateIsPackageSoldout()
                    updateIsPackagePublished()
                    hideLoading()
                })
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className='position-relative'>
                        <section
                            className={`max-width-wrapper ${!hasPermission ? "overlay" : ""
                                }`}
                        >
                            {(!location.pathname.includes('/profile')) ? (
                                <section>
                                    <header className='section-header section-heading section-heading--secondary'>
                                        <div className="section-heading">
                                            <h1>Package holders</h1>
                                            <p className='section-header-desc'>Comprehensive list of customers who have purchased season packages</p>
                                        </div>
                                    </header>
                                    {packageHolders?.length > 0 ? (
                                        <HoldersList packageId={uuid} packageHolders={packageHolders} />
                                    ) : (
                                        <Card body>
                                            <EmptyContainer style='center lg'>
                                                <p>
                                                    You have no package holders
                                                </p>
                                            </EmptyContainer>
                                        </Card>
                                    )}
                                </section>
                            ) : (
                                <AssignToFanProvider>
                                    <FromPageProvider>
                                        <FanProfileWrapper id={fanId} />
                                    </FromPageProvider>
                                </AssignToFanProvider>
                            )}
                        </section>

                        {!hasPermission && <NoPermissionsContainer />}
                    </div>
                </>
            )}
        </>
    )
}