import React, { useState, useEffect, useRef } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';

import { RequiredText } from '../../../RequiredText';
import { WarningCard } from '../../../WarningCard';
import { InputField } from './InputField';
import { NoCodeMsg } from './NoCodeMsg';

export default function AccessCode({ offer, setOffer, errors, canEdit, initialState, setHasAccessCode, requiredFieldErrorStatus, handleChange, handleValid, findError }) {

    const inputRef = useRef(null)

    const [key, setKey] = useState('none')

    const [error, setError] = useState()

    // set tab depending on initial state
    useEffect(() => {
        setKey(initialState ? 'single' : 'none')
    }, [initialState])

    // reset state when tab changes 
    useEffect(() => {
        let hasAccessCode;

        if (key === 'none') {
            // To ensure offer is updated with the latest accessCode
            setOffer((prevOffer) => ({ ...prevOffer, accessCode: '' }));
            hasAccessCode = false
        }

        else if (key === 'single') {
            hasAccessCode = true
        }
        setHasAccessCode(hasAccessCode)
    }, [key])

    useEffect(() => {
        setError(findError('accessCode'))
    }, [errors])

    // focus input field whenever key changes only if no access code is set 
    useEffect(() => {
        if (!offer?.accessCode) {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [key, offer?.accessCode])

    const getText = () => {

        if (canEdit) {
            return 'Assign an access code to this offer to make it require special access.'
        }
        else {
            if (offer?.accessCode) return 'Single access code'
            else return 'None'
        }
    }

    return (
        <>
            <div className="card-body-heading--sm">
                <div className="flex">
                    <Card.Title as="h5" className='card-title-sm'>Access Code</Card.Title>
                    {(error || requiredFieldErrorStatus?.code) && (<RequiredText />)}
                </div>
                <Card.Subtitle as="h6" className="subtitle--dark">{getText()}</Card.Subtitle>
            </div>
            {canEdit ? (
                <Tabs
                    id="access-code"
                    variant="pills"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="w-25"
                    justify
                >
                    <Tab eventKey="none" title="None">
                        <Card body className="card--sm card-with-border">
                            <NoCodeMsg />
                        </Card>
                    </Tab>
                    <Tab eventKey="single" title="Single">
                        <Card body className="card--sm card-with-border">
                            <div className="card-body-heading card-body-heading--xs">
                                <Card.Title as="h5" className='card-title-xs card-title-thin'>Enter access code</Card.Title>
                            </div>
                            <InputField offer={offer} error={error || requiredFieldErrorStatus?.code} inputRef={inputRef} handleChange={handleChange} handleValid={handleValid} />
                            <WarningCard text="The access code must be entered in uppercase, lowercase, or sentence case, must not contain any symbols or spaces, and should not exceed 15 characters in length" variant={`${error || requiredFieldErrorStatus?.code ? 'danger' : 'primary'}`} margin="mt" />
                        </Card>
                    </Tab>
                </Tabs>
            ) : (
                <Card body className="card--sm card-with-border">
                    {offer?.accessCode ? (
                        <InputField offer={offer} isDisabled={true} />
                    ) : (
                        <NoCodeMsg />
                    )}
                </Card>
            )}
        </>
    );
}
