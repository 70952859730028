import React from 'react';

import { formatString } from '../../../../../utilities/helpers'

import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';

import { Event } from '../../Event';

export default function CheckboxEvent({ event, timezone, isChecked, handleCheck }) {

    const getBadgeType = (status) => {
        switch (status) {
            case 'on_sale':
                return 'onsale'

            case 'scheduled':
                return 'scheduled'

            case 'published':
                return 'published'

            default:
                return;
        }
    }
    return (
        <li className='list-item list-item-lg list-item--light'>
            <Form.Check type='checkbox' className="form-check-display form-check-display--lg m-0" id={`event-type-display-checkbox-${event.id}`}>
                <Form.Check.Input
                    type='checkbox'
                    name="display-event"
                    checked={isChecked(event.id)}
                    onChange={(e) => handleCheck(event.id)}
                    className='flex-shrink-0'
                />
                <div className='gap-3 flex-grow-1'>
                    <Form.Check.Label id={`event-type-display-checkbox-${event.id}`} className="m-0 d-flex gap-3 w-100 h-100 align-items-center">
                        <Event event={event} timezone={timezone}>
                            <Badge bg="none" className={`badge--${getBadgeType(event?.status)}`}>{formatString(event?.status)}</Badge>
                        </Event>
                        {/* <Stack direction="horizontal" gap={3} className='align-items-center justify-content-between flex-grow-1'>
                            <img src={event?.image?.url} width={64} height={64} alt={`Cover art for ${event?.name} event`} className='event-image flex-shrink-0' />
                            <Stack className='justify-content-center'>
                                <span className="normal normal-bold">{event?.name}</span>
                                <span className="small text-muted">
                                    <span>{formatDateTime(getTimezoneDate(event?.start, timezone))}</span>
                                    <span className='venue'>{event?.venue?.name}</span>
                                    <span className='loc'>{formatShortAddress(event?.venue)}</span>
                                </span>
                            </Stack> */}
                    </Form.Check.Label>
                </div>
            </Form.Check>
        </li>
    );
}
