import React from 'react';

import { namePatternMatch } from '../../../utilities/helpers';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PhoneNumberInput } from '../../PhoneNumberInput';

export default function GuestInfo({ ticketTypes, guest, handleChange, phoneNumber, setPhoneNumber, isQuantityValid, validQuantity, handleValidNumber, isValidPhoneNumber }) {

    return (
        <Form>
            <Row className='form-group'>
                <Col>
                    <Form.Group controlId='firstName'>
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            pattern={namePatternMatch}
                            value={guest.firstName}
                            onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : guest.firstName)}
                            required
                            autoFocus
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="form-group" controlId='lastName'>
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                            pattern={namePatternMatch}
                            value={guest.lastName}
                            onChange={(e) => handleChange(e.target.validity.valid || e.target.value === '' ? e : guest.lastName)}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="form-group" controlId="number">
                <Form.Label>Phone Number</Form.Label>
                <PhoneNumberInput phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} onBlur={handleValidNumber} hasError={!isValidPhoneNumber} />
            </Form.Group>
            <Row className='form-group'>
                <Col>
                    <Form.Group controlId='quantity'>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type="text"
                            name="quantity"
                            pattern="^[0-9]*$"
                            placeholder="Enter quantity"
                            value={guest.quantity}
                            onBlur={validQuantity}
                            onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : guest.quantity)}
                            required
                            className={`${guest.quantity && !isQuantityValid ? 'error-border' : ''}`}
                        />
                        {guest.quantity && !isQuantityValid && (
                            <Form.Text className="text-danger">The requested ticket quantity is not available</Form.Text>
                        )}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId='ticketType'>
                        <Form.Label>Ticket type</Form.Label>
                        <Form.Select name="ticketType" onChange={handleChange} value={guest.ticketType}>
                            <option>Select Ticket Type</option>
                            {ticketTypes?.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}
