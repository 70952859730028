// Create a lightweight state manager (alternative to Context)
const createMapStateManager = () => {
  let listeners = new Set();
  let seatStates = new Map();

  return {
    getState: (seatId) => seatStates.get(seatId),
    setState: (seatId, newState) => {
      seatStates.set(seatId, newState);
      listeners.forEach((listener) => listener(seatId));
    },
    resetState: () => {
      // First store all seat IDs that had state
      const seatIds = Array.from(seatStates.keys());
      // Clear the states
      seatStates.clear();
      // Notify about each seat that was cleared
      seatIds.forEach((seatId) => {
        listeners.forEach((listener) => listener(seatId));
      });
    },
    subscribe: (listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };
};

// Create the state manager instance outside component
export const mapStateManager = createMapStateManager();
