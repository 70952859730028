import React from "react";

import { formatNumber } from "../../../../../../../utilities/helpers";

import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";

export default function Item({
    obj,
    numSeatsInitialState,
    autoFocused = false,
    handleChange,
}) {
    return (
        <div className='split-row'>
            <div className='flex flex-sm' style={{ 'flex': 1 }}>
                <div className='dot' style={{ backgroundColor: obj?.color }}></div>
                <span className='item-name'>{obj?.name}</span>
            </div>
            <Stack direction='horizontal' gap={1} className='justify-content-end' style={{ 'flex': 1 }}>
                <Form.Control
                    size='sm'
                    className='w-25'
                    name='seats'
                    pattern='^[0-9]*$'
                    min="0"
                    max={obj.gaSeats.length}
                    value={obj.count}
                    onChange={(e) => {
                        const newCount = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
                        if (!isNaN(newCount) && newCount >= 0 && newCount <= obj.gaSeats.length) {
                            handleChange(newCount);
                        }
                    }}
                    autoFocus={autoFocused}
                />
                <span>/</span>
                <span className='num-seats'>{formatNumber(numSeatsInitialState)}</span>
            </Stack>
        </div>
    );
}
