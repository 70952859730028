import React from "react";

import { DroppableItem } from "../../../DroppableItem";
import { Item } from "../../../Item";

export default function InventoryItem({
  index,
  entity,
  el,
  isSubItem = false,
  styles,
  data,
  canAdd,
  canRemove,
  handleRemove,
  handleAdd,
  getObjectTotalSeatCount,
  isDroppableInSelected
}) {
  return (
    <DroppableItem
      index={index}
      data={data}
      styles={`${styles || ""} ${isSubItem ? "sub-item" : ""}`}
      hasHover={canAdd || canRemove}
      isDroppableInSelected={isDroppableInSelected}
    >
      <Item
        index={index}
        entity={entity}
        data={data}
        el={el}
        isSubItem={isSubItem}
        canRemove={canRemove}
        canAdd={canAdd}
        handleRemove={handleRemove}
        handleAdd={handleAdd}
        getObjectTotalSeatCount={getObjectTotalSeatCount}
      />
    </DroppableItem>
  );
}
