import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Card from 'react-bootstrap/Card'

import LoadingContext from '../../context/Loading/Loading';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { EmptyContainer } from '../EmptyContainer';

export default function GroupPackageWrapper({ uuid }) {
  const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)
  const [hasPermission, setHasPermission] = useState(true);

  return (
    <>
                    <div className='position-relative'>
                        <section
                            className={`max-width-wrapper ${!hasPermission ? "overlay" : ""
                                }`}
                        >
                            <section>
                                <header className='section-header section-heading--flex section-heading section-heading--secondary'>
                                    <div className="section-heading">
                                        <h1>Create Group Purchase</h1>
                                        <p className='section-header-desc'>Assign tickets to a fan for group purchase</p>
                                    </div>
                                    <Link className={`btn btn-outline-light btn-lg btn-plus`} to={`/myevent/${uuid}/group-package/seatmap/assign`}>Assign Tickets</Link>
                                </header>
                                <section>
                                    
                                </section>
                            </section>
                        </section>

                        {!hasPermission && <NoPermissionsContainer />}
                    </div>
                </>
  )
}