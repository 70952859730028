import React from "react";

import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { Spinner } from "../../../LoadingContainer/Spinner";

export default function ResetMapModal({
  show,
  handleReset,
  handleClose,
  isSaving,
}) {
  return (
    <Modal
      show={show}
      animation={false}
      onHide={handleClose}
      centered
      backdrop='static'
      className='modal--lg'
    >
      <Modal.Body>
        <div className='modal-body-heading'>
          <h1 className='modal-body-heading-title'>Reset seat map?</h1>
          <p>
            Resetting the seat map will remove all your price levels, holds and
            kills. You will not be able to restore this action. Are you sure you
            want to proceed?
          </p>
        </div>
        <Stack className='btn-group-flex'>
          <Button variant='outline-light' disabled={isSaving} size='lg' onClick={handleReset}>
            {isSaving ? <Spinner variant="dark" /> : "Reset map"}
          </Button>
          <Button size='lg' onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
}
