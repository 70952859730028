import React, { useRef } from 'react';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import { DateInput } from './DateInput';

export default function DateInputWrapper({ label, id, size, styles, setDate, selectedDate, startDate, endDate, minDate, maxDate, isReadOnly = false, error }) {

    const dateRef = useRef();

    return (
        <Form.Group>
            <div className={`date-picker-card day-picker-card ${size ? `date-picker-card-${size}` : ''} ${styles || ''}`} ref={dateRef}>
                <Stack>
                    {label && (<Form.Label htmlFor={id} className={`${error ? 'error' : ''}`}>{label}</Form.Label>)}
                    <DateInput
                        id={id}
                        setDate={setDate}
                        selectedDate={selectedDate}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        reference={dateRef}
                        isReadOnly={isReadOnly}
                    />
                </Stack>
            </div>
        </Form.Group>
    );
}
