import { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";

import "./legend.scss";

export default function Legend({ items = [] }) {
  const [open, setOpen] = useState(false);
  return (
    <div className='vjx-legend' id='legend-container'>
      <Button
        variant='link'
        className='btn-toggle'
        onClick={() => setOpen(!open)}
        aria-controls='legend'
        aria-expanded={open}
      >
        Legend
      </Button>
      <Collapse in={open}>
        <div id='legend'>
          <ul id='legend-list'>
            {items.map((item, index) => (
              <li key={index} className={`list-item ${item.className}`}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
    </div>
  );
}
