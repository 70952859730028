import React, { useState, useEffect } from 'react';

import {
    DndContext,
    DragOverlay,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import { RequiredText } from '../../RequiredText';
import { SortableAttraction } from './SortableAttraction';
import { Attraction } from './Attraction';
import { AddAttractionModal } from './AddAttractionModal';
import { DeleteModal } from '../../DeleteModal';

import './addAttraction.scss';

export default function AddAttraction({ event, attractions, setAttractions, isDisabled, requiredFieldErrorStatus, toUpdate, setToUpdate, idsToRemove, setIdsToRemove }) {

    const [activeAttraction, setActiveAttraction] = useState(null)

    const [showCreate, setShowCreate] = useState(false)

    const [showDelete, setShowDelete] = useState(false)

    const [addManually, setAddManually] = useState(false)

    // local state in case attraction is discarded 
    // for editing
    const [attractionLocal, setAttractionLocal] = useState()

    // local state in case attraction image is discarded - need to be seperate state because there is a lot of manipulation on it 
    // for editing
    const [imageLocal, setImageLocal] = useState()

    const [coordinates, setCoordinates] = useState()

    // for editing - save attraction id for flag 
    // for deleting
    const [id, setId] = useState()

    // for editing
    const [initialEditState, setInitialEditState] = useState()

    const [isBtnDisabled, setIsBtnDisabled] = useState(false)

    useEffect(() => {
        // if editing set state
        if (id && attractionLocal) {
            setImageLocal(attractionLocal?.artwork.url)
            setInitialEditState(attractionLocal)
            setCoordinates({ width: attractionLocal.artwork.width, height: attractionLocal.artwork.height })
        }
    }, [id])

    // keep checking properties within initial state object when it changes 
    useEffect(() => {
        setIsBtnDisabled(initialEditState?.name === attractionLocal?.name && initialEditState?.artwork.url === imageLocal || !imageLocal || !attractionLocal?.name)
    }, [initialEditState, imageLocal, attractionLocal?.name])

    // check if attraction should be updated in db 
    const shouldUpdate = (attractions) => {
        // Function to check if an attraction should be added to the update array
        const shouldAddAttraction = (attraction) => {
            // If the attraction is in the event attractions, add it to the update array
            console.log(attraction);
            if (event?.attractions?.some(attr => attr.id === attraction.id)) {
                console.log('existingn', attraction);
                // if artwork has not changed, update attraction 
                // else remove attraction since a new one is going to be created 
                if (attraction.artwork.url.startsWith('https://')) {
                    console.log('artwork same', attraction);
                    // if attraction already exists in array, remove previous entry and take new entry 
                    if (toUpdate.some(attr => attr.id === attraction.id)) {
                        const duplicate = toUpdate.find(attr => attr.id === attraction.id)
                        console.log('duplicate', duplicate);
                        // Remove previous entry and take the new entry
                        const updatedToUpdate = toUpdate.filter(attr => attr.id !== attraction.id);
                        console.log(updatedToUpdate);

                        // Update state with the new attraction
                        setToUpdate([...updatedToUpdate, attraction]);
                    }
                    else {
                        // If the attraction is new, just add it to the toUpdate array
                        setToUpdate((prevState => [...prevState, attraction]));
                    }
                } else {
                    // if attraction id not already in the ids to remove, add attraction id to remove array
                    if (!idsToRemove?.some(id => attraction.id === id)) {
                        setIdsToRemove([...idsToRemove, attraction.id])
                    }
                }
            }
        };

        // If `attractions` is an array, iterate through each item
        if (Array.isArray(attractions)) {
            console.log(attractions);
            attractions.forEach(shouldAddAttraction);
        } else {
            // If `attractions` is a single item, process it directly
            shouldAddAttraction(attractions);
        }
    };

    const handleShowCreate = (_, attraction) => {
        setShowCreate(true)
        if (attraction) {
            setId(attraction?.id)
            setAttractionLocal(attraction)
            setImageLocal(attraction.artwork.url)
        }
    }

    const handleCloseCreate = () => {
        setAttractionLocal()
        setImageLocal()
        setCoordinates()
        setId()
        setAddManually(false)
        setShowCreate(false)
        setInitialEditState()
    }

    // create new or update attraction
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // update attraction
        if (id) {
            const updatedAttractions = attractions.map(attraction => {
                if (attraction.id === id) {
                    const updatedAttraction = {
                        ...attraction,
                        name: attractionLocal.name,
                        order: attractionLocal.order,
                        artwork: {
                            ...attraction.artwork,
                            url: imageLocal,
                            width: coordinates.width,
                            height: coordinates.height
                        }
                    }
                    shouldUpdate(updatedAttraction)
                    return updatedAttraction
                }
                return attraction
            });

            setAttractions(updatedAttractions)
        } else {
            // create new attraction 
            setAttractions([
                ...attractions,
                {
                    id: attractions.length + 1,
                    name: attractionLocal.name,
                    primary: attractions?.some(attraction => attraction?.primary) ? false : true,
                    order: attractions?.some(attraction => attraction?.primary) ? attractions.length + 1 : 1,
                    artwork: {
                        url: imageLocal,
                        width: coordinates.width,
                        height: coordinates.height
                    }
                }
            ])
        }
        handleCloseCreate()
    }

    const handleShowDelete = (id) => {
        setShowDelete(true);
        setId(id)
    }

    const handleCloseDelete = () => {
        setShowDelete(false);
        setId()
    }

    // remove attraction
    const handleRemove = () => {
        // if attraction to delete is found in event attractions, add its id to array to remove when saving 
        if (event?.attractions?.some(attraction => attraction.id === id)) {
            setIdsToRemove([...idsToRemove, id])
        }

        let updatedAttractions = attractions?.filter(attraction => attraction.id !== id)

        // Find the attraction to delete and check its order and primary status
        const deletedAttraction = attractions.find(attraction => attraction.id === id);
        const deletedOrder = deletedAttraction.order;
        const wasPrimary = deletedAttraction.primary;
        let updatedAttraction;
        let attractionsToUpdate = []
        let attractionsAfterDeleted = []

        // deleted attraction was primary or position was at start or middle of array
        if (deletedOrder < attractions?.length || wasPrimary) {
            attractionsAfterDeleted = updatedAttractions.filter(attraction => attraction.order > deletedOrder).map((attraction, index) => {
                // Decrease order by 1 for attractions after the deleted one
                updatedAttraction = { ...attraction, order: attraction.order - 1 }

                // If the deleted attraction was primary, set the new first item to primary
                if (wasPrimary && index === 0) {
                    updatedAttraction = { ...updatedAttraction, primary: true };
                }
                attractionsToUpdate = [...attractionsToUpdate, updatedAttraction];

                console.log(updatedAttraction);

                return updatedAttraction;
            });
            shouldUpdate(attractionsToUpdate)
        }

        if (attractionsAfterDeleted?.length > 0) {
            // Combine updated attractions, keeping the original order for those before the deleted item
            updatedAttractions = [
                ...updatedAttractions.filter(attraction => attraction.order < deletedOrder),
                ...attractionsAfterDeleted
            ];
        }

        console.log(updatedAttractions);
        setAttractions(updatedAttractions)
        handleCloseDelete()
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = (e) => {
        setActiveAttraction(attractions.find(attraction => attraction.id == e.active.id))
    }

    const handleDragEnd = (e) => {
        const { active, over } = e;

        // Check if the item has moved to a new position
        console.log(active.id, over?.id);
        if (active.id !== over?.id) {
            setAttractions((attractions) => {
                const oldIndex = attractions.findIndex(attraction => attraction.id === active.id);
                const newIndex = attractions.findIndex(attraction => attraction.id === over.id);
                console.log(newIndex, oldIndex);

                // Update primary and order of attractions 
                let updatedAttractions = arrayMove(attractions, oldIndex, newIndex);
                let foundFirst = false;
                let foundMoved = false;
                let updatedAttraction;
                let attractionsToUpdate = []
                const newUpdatedAttractions = updatedAttractions.map((attraction, index) => {
                    console.log(attraction, (foundFirst && foundMoved));
                    if (foundFirst && foundMoved) return attraction; // Skip further processing once the primary is found

                    // new position is at start
                    if (newIndex === 0) {
                        // for the first attraction, set it as primary and update its order
                        if (index === 0) {
                            foundFirst = true
                            updatedAttraction = { ...attraction, primary: true, order: 1 };
                            attractionsToUpdate = [...attractionsToUpdate, updatedAttraction];
                            return updatedAttraction;
                        }
                        // if an existing primary attraction is found, reset its primary flag and adjust its order
                        if (attraction.primary) {
                            console.log('found current');
                            foundMoved = true;
                            updatedAttraction = { ...attraction, primary: false, order: oldIndex + 1 };
                            attractionsToUpdate = [...attractionsToUpdate, updatedAttraction];
                            return updatedAttraction;
                        }
                        return attraction; // No changes to non-primary attractions
                    } else {
                        // Handle non-primary position swap
                        console.log(attraction, activeAttraction);
                        if (!foundFirst) {
                            if (attraction.id === activeAttraction.id) {
                                foundFirst = true
                                updatedAttraction = { ...attraction, order: newIndex + 1 };
                                attractionsToUpdate = [...attractionsToUpdate, updatedAttraction];
                                return updatedAttraction;
                            }
                        }
                        console.log('moved', attraction, newIndex);
                        // if the attraction's current order matches the new position (got moved from its current position), update it
                        if (!foundMoved) {
                            console.log('moved', attraction, newIndex);
                            if (attraction.order === newIndex + 1) {
                                foundMoved = true
                                updatedAttraction = { ...attraction, order: oldIndex + 1 };
                                attractionsToUpdate = [...attractionsToUpdate, updatedAttraction];
                                return updatedAttraction;
                            }
                        }
                    }
                    return attraction; // Return unchanged attraction if no condition matches
                });
                // Log the final result
                console.log(newUpdatedAttractions);
                shouldUpdate(attractionsToUpdate);
                return newUpdatedAttractions;
            });
        }
        setActiveAttraction(null)
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--flex">
                <Card.Title as="h5">Add Attraction</Card.Title>
                {requiredFieldErrorStatus?.attractions && (<RequiredText />)}
            </div>
            <Stack direction='horizontal' as="ul" gap={4} className='flex-wrap align-items-stretch'>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={attractions}>

                        {attractions.map((attraction, index, arr) => (
                            <SortableAttraction key={index} attraction={attraction} idx={index} isDraggingDisabled={arr.length < 2} isDisabled={isDisabled} handleShowCreate={handleShowCreate} handleShowDelete={handleShowDelete} />
                        ))}
                    </SortableContext>
                    <DragOverlay style={{ transformOrigin: '0 0 ' }} wrapperElement='ul'>
                        {activeAttraction ? (
                            <Attraction attraction={activeAttraction} />
                        ) : null}
                    </DragOverlay>
                </DndContext>
                {attractions?.length < 9 && (
                    <Button
                        variant="outline-light"
                        className={`btn--square btn-card btn-card--add ${requiredFieldErrorStatus?.attractions ? 'error' : ''}`}
                        onClick={handleShowCreate}
                        disabled={isDisabled}
                    >
                        <div className='btn-container'>
                            <span>Add Attraction</span>
                        </div>
                    </Button>
                )}

            </Stack>

            <AddAttractionModal show={showCreate} handleClose={handleCloseCreate} addManually={addManually} setAddManually={setAddManually} id={id} attraction={attractionLocal} setAttraction={setAttractionLocal} image={imageLocal} setImage={setImageLocal} coordinates={coordinates} setCoordinates={setCoordinates} handleSubmit={handleSubmit} isDisabled={isBtnDisabled} />

            <DeleteModal entity="attraction" show={showDelete} handleClose={handleCloseDelete} id={id} handleDelete={handleRemove} />
        </>
    );
}
