import { useGAModal } from "../GAModalProvider/GAModalProvider";

import { ActionsProvider } from "../../SeatMapWrapper/ConfigureMap/MapConfigurations/ActionsProvider/ActionsProvider";
import { SeatMap } from "../../SeatMapWrapper/ConfigureMap/MapConfigurations/SeatMap";

export default function PackageMap({
  data,
  background,
  seatsInPackage,
  selectedSeatIds,
  setSelectedSeatIds,
  isMapDisabled,
  isAssigningToFan,
  soldMap,
}) {
  const SELECTED_COLOR = "#141416"; // black - selecting seats on map
  const SOLD_COLOR = "#45B26B"; // green for sold
  const INCLUDED_COLOR = "#FFA500"; // orange color of seats included in package
  const DEFAULT_COLOR = "#c6c8cc";
  const { openModal } = useGAModal();

  // save regular seated seats,,,
  const selectRegularSeats = (seatId, seatIdArray) => {
    // if map is disabled don't select seats
    if (isMapDisabled) return;

    setSelectedSeatIds((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (!newSelected.seats) {
        newSelected.seats = {};
      }

      const addSeat = (id) => {
        if (!getSeatState(id).disabled) {
          if (newSelected.seats[id] === id) {
            delete newSelected.seats[id];
          } else {
            newSelected.seats[id] = id;
          }
        }
      };

      if (seatId) {
        // Handle single seat selection
        addSeat(seatId);
      } else if (seatIdArray) {
        // Handle multiple seat selection
        seatIdArray.forEach(addSeat);
      }

      // If empty dont return .seats
      if (Object.keys(newSelected.seats).length === 0) {
        delete newSelected.seats;
      }

      return newSelected;
    });
  };

  // GA sections use this to get their color
  const getSectionState = (section) => {
    if (
      selectedSeatIds &&
      selectedSeatIds.gaSeats &&
      selectedSeatIds.gaSeats.hasOwnProperty(section.sectionId)
    ) {
      return { disabled: false, color: SELECTED_COLOR };
    } else if (
      seatsInPackage?.gaSeats &&
      seatsInPackage.gaSeats.hasOwnProperty(section.sectionId)
    ) {
      return { disabled: false, color: INCLUDED_COLOR };
    }

    // Disable the rest of the sections if isAssigningToFan
    return { disabled: isAssigningToFan, color: DEFAULT_COLOR };
  };

  // for sold seats return sold color + disabled
  const getSeatState = (seatId) => {
    if (soldMap?.get(seatId)) {
      return { disabled: true, color: SOLD_COLOR };
    }

    if (selectedSeatIds?.seats && selectedSeatIds.seats[seatId] === seatId) {
      return { disabled: false, color: SELECTED_COLOR };
    } else if (
      seatsInPackage?.seats &&
      seatsInPackage.seats[seatId] === seatId
    ) {
      return { disabled: false, color: INCLUDED_COLOR };
    }

    // Disable the rest of the seats if isAssigningToFan
    return { disabled: isAssigningToFan, color: DEFAULT_COLOR };
  };

  // This is triggered for BOTH GA and seated:
  // ( since you can select seated by selecting the entire section )
  const handleSectionSelect = (section) => {
    // if map is disabled don't select sections
    if (isMapDisabled) return;

    if (section?.zoomable) {
      // Seated-section
      const seatIds = getAllSeatsInSeatedSection(
        section,
        data.rows,
        data.seats
      );
      selectRegularSeats(undefined, seatIds);
    } else {
      // GA-section
      openModal(section);
    }
  };

  const getAllSeatsInSeatedSection = (section, rows, seats) => {
    return Object.values(rows)
      .filter((row) => row.sectionId === section.sectionId)
      .flatMap((row) => row.seats)
      .filter((seatId) => {
        if (!seats[seatId]) return false;
        return true;
      });
  };

  const legendItems = isAssigningToFan
    ? [
        { label: "Sold", className: "sold" },
        { label: "Selectable", className: "selectable" },
        { label: "Selected", className: "selected" },
        { label: "Unselected", className: "unselected" },
      ]
    : [
        { label: "Sold", className: "sold" },
        { label: "Selected", className: "selected" },
        { label: "Unselected", className: "unselected" },
      ];

  {
    /* 
  
    Customize this file ( or the files above ) in any which way that suits your purposes
    Just DO-NOT customize anything lower than this 
    
    */
  }

  const inventoryInfoBasedOnMapType = isAssigningToFan
    ? { displayInventoryInfo: false, deriveInventoryInfoFrom: [] }
    : {
        displayInventoryInfo: true,
        deriveInventoryInfoFrom: [
          {
            inventory: [{ ...selectedSeatIds, color: SELECTED_COLOR }],
            type: "offers",
          },
        ],
      };

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      {/* Seatmap fills all available space, so its also behind the Navigation now */}
      <ActionsProvider canEdit={!isMapDisabled} legendItems={legendItems}>
        <SeatMap
          data={data}
          background={background}
          isMapDisabled={isMapDisabled}
          // Handling clicks
          selectRegularSeats={selectRegularSeats}
          handleSectionSelect={handleSectionSelect}
          // Fill Colors
          getSectionState={getSectionState}
          getSeatState={getSeatState}
          // For inventory "bar"
          displayInventoryInfo={
            inventoryInfoBasedOnMapType.displayInventoryInfo
          }
          deriveInventoryInfoFrom={
            inventoryInfoBasedOnMapType.deriveInventoryInfoFrom
          }
        />
      </ActionsProvider>
    </div>
  );
}
