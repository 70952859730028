import React from 'react';

import { formatDateTime, getTimezoneDate } from '../../../../../utilities/helpers'

import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'

export default function Offer({ offer, baseOffer, timezone, isEditing, isDisabled, getSaleDate, setBaseOffer }) {

    return (
        <>
            {isEditing ? (
                <Button
                    variant='default'
                    disabled={isDisabled}
                    className='btn-list-view list-item list-item-xs'
                >
                    <div className='lh-sm text-wrap'>
                        <span className='small-label lh-lg'>{offer?.name}</span>
                        <small className='subtitle'>
                            <span>{getSaleDate(offer, 'starts', timezone, 'dateOnly')}
                                <span className='time'>{getSaleDate(offer, 'starts', timezone, 'timeOnly')}</span></span><span className='to'>{getSaleDate(offer, 'ends', timezone, 'dateOnly')}<span className='time'>{getSaleDate(offer, 'ends', timezone, 'timeOnly')}</span>
                            </span>
                        </small>
                    </div>
                </Button>
            ) : (
                <Stack as="li" direction='horizontal' className='justify-content-between list-item list-item-xs list-item--without-bg list-item--hover list-item--hover-lg'>
                    <div className='form-check--with-desc text-left'>
                        <Form.Check
                            name={offer?.name}
                            id={offer?.id}
                            type='radio'
                            label={offer?.name}
                            checked={offer.id === baseOffer?.id}
                            onChange={() => setBaseOffer(offer)}
                        />
                        <div className="mt-2">
                            <small className='subtitle'>
                                <span>{getSaleDate(offer, 'starts', timezone, 'dateOnly')}
                                    <span className='time'>{getSaleDate(offer, 'starts', timezone, 'timeOnly')}</span></span><span className='to'>{getSaleDate(offer, 'ends', timezone, 'dateOnly')}<span className='time'>{getSaleDate(offer, 'ends', timezone, 'timeOnly')}</span>
                                </span>
                            </small>
                        </div>
                    </div>
                </Stack>
            )}
        </>
    );
}