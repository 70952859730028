import { useParams } from "react-router-dom";

import { PaginationProvider } from "../../providers/PaginationProvider/PaginationProvider";

import { PackageHoldersWrapper } from "../../components"

export default function PackageHoldersPage() {

    const { uuid, id } = useParams()

    return (
        <PaginationProvider>
            <PackageHoldersWrapper uuid={uuid} fanId={id} />
        </PaginationProvider>
    )
}