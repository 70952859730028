import React from 'react';

import { formatNumber } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';

import { RequiredText } from '../../RequiredText';

export default function Inventory({ eventPackage, inventory, handleChange, requiredFieldErrorStatus, isEditable }) {

    const getText = () => {
        const numSeats = inventory?.seats?.length
        return `${numSeats > 0 ? `${formatNumber(numSeats)} ${numSeats === 1 ? 'seat' : 'seats'}` : 'No seats assigned'}`
    }

    return (
        <>
            <div className="card-body-heading card-body-heading--sm">
                <Card.Title as="h5">Inventory</Card.Title>
            </div>
            <Stack gap={4}>
                <Form.Group className="form-group">
                    <div className="form-label--flex">
                        <Form.Label>Price of package</Form.Label>
                        {requiredFieldErrorStatus?.price && (<RequiredText />)}
                    </div>
                    <InputGroup className={`w-25 ${requiredFieldErrorStatus.price ? 'input-group-error' : ''}`}>
                        <InputGroup.Text id="price">$</InputGroup.Text>
                        <Form.Control
                            placeholder="Amount"
                            id="price"
                            name="price"
                            aria-describedby="price"
                            pattern="^[0-9.]*$"
                            value={eventPackage?.price || ''}
                            onChange={(e) =>
                                handleChange(
                                    (e.target.value === '' || e.target.validity.valid
                                        ? e
                                        : eventPackage?.price)
                                )
                            }
                            required
                            disabled={isEditable}
                        />
                    </InputGroup>
                </Form.Group>
                <Card body className="card--sm card-with-border">
                    <Stack direction='horizontal' className='heading--flex-space-between'>
                        <Stack direction="horizontal" className='flex-sm'>
                            <div className='dot align-self-start' style={{
                                backgroundColor: inventory?.color
                            }}>
                            </div>
                            <Stack>
                                <span className="item-name">{`${eventPackage?.name || '(Name needs to be assigned)'}`}</span>
                                <span className='subtitle'>{getText()}</span>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </>
    )
}