import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../../../LoadingContainer/Spinner';

export default function MarkAsPaidModal({ show, paymentOption, setPaymentOption, paymentMethod, setPaymentMethod, isSaving, handleClick, handleClose }) {

    return (
        <Modal centered animation={false} fullscreen="md-down" backdrop='static' show={show} onHide={handleClose} className='modal--lg'>
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Mark payment as complete?</h1>
                </div>
                <>
                    <h5 id="accepting payment" className='mb-3 small small-bold'>Accepting payment</h5>
                    <Form.Select
                        name="accepting-payment"
                        onChange={(e) => setPaymentOption(e.target.value)}
                        value={paymentOption}
                        required
                        aria-labelledby='accepting-payment'
                    >
                        <option disabled hidden value="">Select payment form</option>
                        <option value="online">Online payment</option>
                        <option value="in-person">In-person payment</option>
                    </Form.Select>
                    <>
                        {paymentOption === 'in-person' && (
                            <div className='mt-3'>
                                <h5 className='mb-3 small small-bold'>Paid by</h5>
                                <Form.Select
                                    name="paidBy"
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                    value={paymentMethod}
                                    required
                                    aria-label='Payment method'
                                >
                                    <option disabled hidden value="">Payment method</option>
                                    <option value="cash">Cash</option>
                                    <option value="cheque">Cheque</option>
                                </Form.Select>
                            </div>
                        )}
                    </>
                    <Alert className='p-0 mt-4' variant="default">This will override the active payment method and mark the order as complete. Proceed at your own risk, this action cannot be undone.</Alert>
                </>
                <Stack className="btn-group-flex">
                    <Button size="lg" className="btn-width" onClick={handleClick} disabled={!paymentOption}>{isSaving ?
                        <Spinner /> :
                        'Mark payment as complete'
                    }
                    </Button>
                    <Button size="lg" variant="outline-light" onClick={handleClose}>Cancel</Button>
                </Stack>
            </Modal.Body>
        </Modal>

    );
}
