import React from "react";

import { Droppable } from "../Droppable";

// reusable item for holds/kills/pricing level
export default function DroppableItem({
  index,
  data,
  isSubItem,
  styles,
  hasSubSeatsAssigned,
  hasNoPrice,
  hasHover = true,
  isDroppableInSelected,
  children,
}) {

  return (
    <Droppable
      index={index}
      data={data}
      isDisabled={hasSubSeatsAssigned || hasNoPrice || isDroppableInSelected(index)}
      styles={`${styles || ""} ${isSubItem ? "sub-item" : ""}`}
      hasHover={hasHover}
    >
      {children}
    </Droppable>
  );
}
