import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

export default function ScalingIncompleteModal({ show, leaveAnyway, continueEditing }) {
    return (
        <Modal
            show={show}
            animation={false}
            onHide={continueEditing}
            centered
            backdrop='static'
        >
            <Modal.Body>
                <div className='modal-body-heading modal-body-heading-sm'>
                    <h1 className='modal-body-heading-title'>
                        Incomplete scaling...
                    </h1>
                </div>
                <div className='split-row split-row-lg mb-3'>
                    <span>We cannot save your selection unless you have added all seats to pricing levels.</span>
                </div>
                <Stack className='btn-group-flex'>
                    <Button size='lg' onClick={leaveAnyway}>
                        Leave anyway
                    </Button>
                    <Button size='lg' variant='outline-light' onClick={continueEditing}>Continue editing</Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
