import React, {
    createContext,
    useContext,
    useState,
} from "react";

// Create the context
const PaginationContext = createContext(undefined);

export const usePagination = () => {
    const context = useContext(PaginationContext);
    if (!context) {
        throw new Error("Context must be used within a usePagination");
    }
    return context;
};

// Reuse pagination functionality 
export const PaginationProvider = ({ children }) => {

    const resultsPerPageOpts = [
        {
            label: "20",
            value: 20
        },
        {
            label: "50",
            value: 50
        },
        {
            label: "100",
            value: 100
        }
    ]

    const [start, setStart] = useState(0);

    const [end, setEnd] = useState(resultsPerPageOpts[0].value);

    const [currentPage, setCurrentPage] = useState(0)

    const [perPage, setPerPage] = useState(resultsPerPageOpts[0].value)

    const [pageCount, setPageCount] = useState(1)

    const handleChange = (count) => {
        setPerPage(count)
        setStart(0)
        setCurrentPage(0)
    }

    return (
        <PaginationContext.Provider
            value={{
                start,
                setStart,
                end,
                setEnd,
                currentPage,
                setCurrentPage,
                perPage,
                pageCount,
                resultsPerPageOpts,
                setPageCount,
                handleChange
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
};
