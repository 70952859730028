import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual'
import moment from 'moment';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';

import { checkPermission, getTimezoneDate, b64toBlob, convertBlobToDataURL, generateRandomColor, getFormattedTimezoneDate, scrollToTop } from '../../utilities/helpers';

import useSafeAsync from '../../utilities/useSafeAsync';

import { getPackage, updatePackageDetails, upload, getVenueEvents, getCategories } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

import { Details } from './Details';
import { EventsList } from './EventsList';
import { CreatePackageButtons } from '../CreatePackageButtons';
import { DateTime } from './DateTime';
import { Inventory } from './Inventory';
import { TicketLimits } from './TicketLimits';

export default function PackageDetailsWrapper({ packageId }) {

    const safeSetState = useSafeAsync();

    const { updatePackage, storePackageStart, storePackageEnd, updateCanPublish, updateIsPackagePublished, updateIsPackageOnsale, updateIsPackageSoldout, hasPackageEnded, updateHasPackageEnded, isPackageSoldout, isPackageOnsale, isPackagePublished, updateHasLastEventInPackageEnded } = useContext(PackageDetailsContext)

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const organization = AuthService.getOrg()[0];

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [initialState, setInitialState] = useState();

    const [showFooter, setShowFooter] = useState(false);

    const [allEvents, setAllEvents] = useState([])

    const [isLoadingEvents, setIsLoadingEvents] = useState(false)

    const [categories, setCategories] = useState([])

    const [selectedEventIds, setSelectedEventIds] = useState([])

    const [eventPackage, setEventPackage] = useState()

    const [inventory, setInventory] = useState()

    const [isSaving, setIsSaving] = useState(false)

    const [start, setStart] = useState(new Date(moment('7:00 pm', 'h:mm a').format()))

    const [end, setEnd] = useState(new Date(moment('11:00 pm', 'h:mm a').format()))

    const [endOfLastEvent, setEndOfLastEvent] = useState(new Date(moment('11:00 pm', 'h:mm a').format()))

    const [image, setImage] = useState()

    const [coordinates, setCoordinates] = useState()

    const [errors, setErrors] = useState([])

    const [isEditing, setIsEditing] = useState(false)

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [hasRequiredFieldError, setHasRequiredFieldError] = useState(false)

    // required fields error status
    const [requiredFieldErrorStatus, setRequiredFieldErrorStatus] = useState({})

    useEffect(() => {
        showLoading()
        loadPackage()
    }, [])

    // show/hide footer 
    useEffect(() => {
        // close all alerts when fields are edited except for required fields alert 
        if (alert.show && !hasRequiredFieldError) {
            closeAlert()
        }

        setShowFooter(
            (
                (
                    Boolean(eventPackage?.name) ||
                    Boolean(eventPackage?.description) ||
                    Boolean(image) ||
                    Boolean(eventPackage?.price) ||
                    Boolean(eventPackage?.minQuantity) ||
                    selectedEventIds?.length > 0 ||
                    Boolean(eventPackage?.maxQuantity)
                )
                && (
                    !isEqual(initialState?.eventPackage, eventPackage)
                    || !isEqual(initialState?.image, image)
                    || !isEqual(new Date(initialState?.start), new Date(start))
                    || !isEqual(initialState?.selectedEventIds, selectedEventIds)
                )
            )
        )

    }, [eventPackage, initialState, image, start, selectedEventIds])

    useEffect(() => {
        // set end date to 24 hours before the first event starts
        // take selected event id that matches first event 
        const firstEvent = sort(allEvents)?.find(event => selectedEventIds.includes(event.id))
        const endDateObj = moment(firstEvent?.start)
        endDateObj.subtract(24, 'hours')
        setEnd(new Date(endDateObj))
        // take selected event id that matches last event
        // reverse allEvents without mutating original array  
        const lastEvent = [...sort(allEvents)].reverse()?.find(event => selectedEventIds.includes(event.id))
        const endDate = moment(lastEvent?.end)
        setEndOfLastEvent(new Date(endDate))
    }, [allEvents, selectedEventIds])

    useEffect(() => {
        if (hasRequiredFieldError) {
            updateRequiredFieldErrorStatus()
        }
    }, [eventPackage?.name, eventPackage?.category, eventPackage?.price, image, selectedEventIds, eventPackage?.minQuantity, eventPackage?.maxQuantity])

    useEffect(() => {
        if (hasRequiredFieldError) {
            // if all fields are filled in: reset required field state
            if (
                Object.keys(requiredFieldErrorStatus).every(
                    (key) => !requiredFieldErrorStatus[key]
                )
            ) {
                setHasRequiredFieldError(false);
                setRequiredFieldErrorStatus({});
            }
        }
    }, [hasRequiredFieldError, requiredFieldErrorStatus]);

    useEffect(() => {
        if (hasRequiredFieldError) {
            // if all fields are filled in: dismiss alert 
            if (Object.keys(requiredFieldErrorStatus).every(key => !requiredFieldErrorStatus[key])) {
                closeAlert()
                setHasRequiredFieldError(false)
                setRequiredFieldErrorStatus({})
            }
        }
    }, [hasRequiredFieldError, requiredFieldErrorStatus])

    useEffect(() => {
        if (errors) {
            resetError("minQuantity")
            // if min quantity is less than max quantity and max quantity has quantity error, remove max quantity error
            if (eventPackage?.minQuantity && eventPackage?.maxQuantity && errors.find(error => error.type === 'quantity') && (parseInt(eventPackage?.minQuantity) < parseInt(eventPackage?.maxQuantity))) {
                resetError("maxQuantity")
            }
        }
    }, [eventPackage?.minQuantity])

    useEffect(() => {
        if (errors) {
            resetError("maxQuantity")
            // if max quantity is less than min quantity and min quantity has quantity error, remove min quantity error
            if (eventPackage?.maxQuantity && eventPackage?.minQuantity && errors.find(error => error.type === 'quantity') && (parseInt(eventPackage?.maxQuantity) > parseInt(eventPackage?.minQuantity))) {
                resetError("minQuantity")
            }
        }
    }, [eventPackage?.maxQuantity])

    // find error in errors array
    const findError = (field) => {
        return errors.find(error => error.field === field)
    }

    // remove error
    const resetError = (fields) => {
        const foundErrors = findError(fields);
        if (Array.isArray(foundErrors)) {
            const filteredErrors = errors.filter(
                (error) => !foundErrors.some((obj) => obj.field === error.field)
            );
            setErrors(filteredErrors);
        } else {
            setErrors(errors?.filter((error) => error?.field !== foundErrors?.field));
        }
    };

    const updateRequiredFieldErrorStatus = () => {
        setRequiredFieldErrorStatus({
            'name': !eventPackage?.name,
            'category': !eventPackage?.category,
            'image': !image,
            'price': !eventPackage?.price,
            'events': selectedEventIds?.length === 0,
            'minQuantity': !eventPackage?.minQuantity,
            'maxQuantity': !eventPackage?.maxQuantity,
        })
    }

    const closeAlert = () => {
        setAlert({ show: false, variant: '', message: '' })
    }

    // sort events by the earliest start date in ascending order
    const sort = events => {
        return events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    }

    const loadVenueEvents = async (roomUUID, seatmapUUID, organizationUUID) => {
        try {
            safeSetState(() => {
                setIsLoadingEvents(true);
            });

            const res = await getVenueEvents(roomUUID, seatmapUUID, organizationUUID);

            safeSetState(() => {
                const sortedEvents = sort(res?.data);
                setAllEvents(sortedEvents);
            });
        } catch (err) {
            console.error(err);
            throw err; // Re-throw the error if you need error handling in the calling function
        } finally {
            safeSetState(() => {
                setIsLoadingEvents(false);
            });
        }
    };

    const loadCategories = async () => {
        try {
            const res = await getCategories();
            safeSetState(() => {
                setCategories(res?.data);
            });
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const loadPackage = async () => {
        try {
            const res = await getPackage(packageId);
            if (!res?.data) return;

            safeSetState(() => {
                setEventPackage(res?.data);
            });

            // load at same time 
            await Promise.all([
                loadVenueEvents(res?.data?.room?.uuid, res?.data?.seatmap?.id, res?.data?.organization?.uuid),
                loadCategories()
            ]);

            await new Promise((resolve) => {
                safeSetState(() => {
                    setIsEditing(Boolean(res?.data?.name) || Boolean(res?.data?.package_tickets?.length > 0));

                    let inventory = { color: generateRandomColor() };

                    if (res?.data?.package_tickets) {
                        inventory = { ...inventory, seats: res?.data?.package_tickets };
                    } else {
                        inventory = { ...inventory, seats: [] };
                    }

                    if (res?.data?.name) {
                        setImage(res?.data?.image?.url);

                        const formattedStart = new Date(getFormattedTimezoneDate(res?.data?.start, res?.data?.timezone));
                        setStart(formattedStart);

                        const formattedEnd = new Date(getFormattedTimezoneDate(res?.data?.end, res?.data?.timezone));
                        setEnd(formattedEnd);

                        const eventIds = sort(res?.data?.events)?.map(event => event.id);
                        setSelectedEventIds(eventIds);

                        inventory = { ...inventory, color: res?.data?.color };

                        saveInitialState(res?.data, formattedStart, formattedEnd, res?.data?.image?.url, eventIds);
                    } else {
                        saveInitialState(res?.data, start, end, image, selectedEventIds);
                    }

                    setInventory(inventory);

                    // Update context
                    updatePackage(res?.data);
                    updateIsPackageOnsale();
                    updateHasPackageEnded(res.data);
                    updateIsPackageSoldout(res.data);
                    updateIsPackagePublished();

                    resolve(); // Wait for this block to complete 
                });
            });
        } catch (err) {
            console.error(err);
        } finally {
            hideLoading();
        }
    };

    const saveInitialState = (eventPackage, start, end, image, selectedEventIds) => {
        // save initial state to check whether to show save buttons
        setInitialState({
            eventPackage,
            image,
            start,
            end,
            selectedEventIds
        })
    }

    const handleChange = (e) => {
        if (e?.target) setEventPackage({ ...eventPackage, [e.target.name]: e.target.value })
    }

    const handleValid = (e) => {
        const { name } = e.target;

        switch (name) {
            case 'minQuantity':
                if (eventPackage?.minQuantity && (parseInt(eventPackage?.minQuantity) < 1 || parseInt(eventPackage?.minQuantity) > 50)) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: 'Minimum quantity must be a number between 1 and 50'
                        }
                    ])
                }
                else if (eventPackage?.minQuantity && (parseInt(eventPackage?.minQuantity) > parseInt(eventPackage?.maxQuantity))) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            type: 'quantity',
                            message: 'Minimum quantity must be less than maximum quantity'
                        }
                    ])
                }
                break;
            case 'maxQuantity':
                if (eventPackage?.maxQuantity && (parseInt(eventPackage?.maxQuantity) < 1 || parseInt(eventPackage?.maxQuantity) > 50)) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            message: 'Maximum quantity must be a number between 1 and 50'
                        }
                    ])
                }
                else if (eventPackage?.maxQuantity && (parseInt(eventPackage?.maxQuantity) < parseInt(eventPackage?.minQuantity))) {
                    setErrors([
                        ...errors,
                        {
                            field: name,
                            type: 'quantity',
                            message: 'Maximum quantity must be greater than minimum quantity'
                        }
                    ])
                }
                break;
            default:
                break;
        }
    }

    const updateDetails = (data) => {
        updatePackageDetails(data)
            .then((res) => {
                // save initial state again if editing to check whether to show save buttons
                saveInitialState(
                    eventPackage,
                    start,
                    end,
                    image,
                    selectedEventIds
                )
                scrollToTop()
                setAlert({
                    show: true,
                    variant: 'success',
                    message: 'Your details have been updated.'
                })

                // update state in context 
                updatePackage(res?.data)
                updateCanPublish(res?.data?.name, res?.data?.package_tickets)
                storePackageStart(res?.data?.start)
                storePackageEnd(res?.data?.end)
                updateIsPackageOnsale(res?.data)
                updateHasPackageEnded(res?.data)
                updateIsPackageSoldout(res?.data)
                updateIsPackagePublished()
                updateHasLastEventInPackageEnded(res?.data?.endOfLastEvent, res?.data?.timezone)

                setIsSaving(false)

                if (!isEditing) {
                    navigate(`/mypackage/${eventPackage?.uuid}/seatmap`)
                }
            })
            .catch((err) => {
                console.error(err)
                setIsSaving(false)
                scrollToTop()
                setAlert({
                    show: true,
                    variant: 'danger',
                    message: 'Unable to save info. Please try again.'
                })
            })
    }

    const handleSave = (e) => {
        e.preventDefault();
        // if error, don't save 
        if (hasRequiredFieldError) {
            scrollToTop()
            return
        }

        const hasError = !eventPackage?.name || !eventPackage?.category || !image || selectedEventIds?.length === 0 || !eventPackage?.minQuantity || !eventPackage?.maxQuantity

        if (hasError) {
            updateRequiredFieldErrorStatus()
            setHasRequiredFieldError(hasError)
            scrollToTop()
            setAlert({
                show: true,
                variant: 'danger',
                message: 'You are missing required subject fields. Please fill out all required fields before continuing.'
            })
            return
        }

        // if other errors
        if (errors?.length > 0) {
            scrollToTop();
            setAlert({
                show: true,
                variant: "danger",
                message:
                    "You have input errors. Please correct all input errors before continuing.",
            });
            return;
        }

        setIsSaving(true)
        const data = {};
        data['id'] = eventPackage.id
        data['name'] = eventPackage.name;
        data['category'] = eventPackage.category;
        data['description'] = eventPackage.description;
        data['events'] = selectedEventIds
        data['start'] = getTimezoneDate(start, eventPackage?.timezone, true).format();
        data['end'] = getTimezoneDate(end, eventPackage?.timezone).format();
        data['endOfLastEvent'] = getTimezoneDate(endOfLastEvent, eventPackage?.timezone, true).format();
        data['minQuantity'] = eventPackage?.minQuantity
        data['maxQuantity'] = eventPackage?.maxQuantity
        data['price'] = eventPackage?.price
        data['color'] = inventory?.color;
        data['orgUUID'] = organization?.uuid;

        // add image to package 
        if (image && !image.startsWith('https://')) { // new or updating image 
            convertBlobToDataURL(image).then((dataUrl) => {
                const formData = new FormData();
                const ImageURL = dataUrl;
                // Split the base64 string in data and contentType
                const block = ImageURL.split(";");
                // Get the content type of the image
                const contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

                // Convert it to a blob to upload
                const blob = b64toBlob(realData, contentType);
                formData.append(`files`, blob);
                upload(formData)
                    .then((res) => {
                        data['image'] = res?.data[0]?.id;
                        updateDetails(data)
                    })
            })
            // update with existing image 
        } else {
            data['image'] = eventPackage?.image?.id; // take existing image
            updateDetails(data)
        }
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper package-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5">
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Package Details</h1>
                        </header>
                        <Form onSubmit={handleSave}>
                            <Card body className='card--md card--light'>
                                <Details eventPackage={eventPackage} categories={categories} image={image} setImage={setImage} coordinates={coordinates} setCoordinates={setCoordinates} handleChange={handleChange} requiredFieldErrorStatus={requiredFieldErrorStatus} isEditable={hasPackageEnded} />
                            </Card>
                            <Card body className='card--md card--light'>
                                <DateTime start={start} setStart={setStart} isEditable={hasPackageEnded} />
                            </Card>
                            <Card body className='card--md card--light'>
                                <Inventory eventPackage={eventPackage} inventory={inventory} handleChange={handleChange} requiredFieldErrorStatus={requiredFieldErrorStatus} isEditable={isPackageOnsale || isPackageSoldout || hasPackageEnded} />
                            </Card>
                            <Card body className='card--md card--light' id="events-list">
                                <EventsList allEvents={sort(allEvents)} timezone={eventPackage?.timezone} selectedEventIds={selectedEventIds} setSelectedEventIds={setSelectedEventIds} requiredFieldErrorStatus={requiredFieldErrorStatus} isLoading={isLoadingEvents} isDisabled={isPackageOnsale || isPackageSoldout || hasPackageEnded} />
                            </Card>
                            <Card body className='card--md card--light'>
                                <TicketLimits eventPackage={eventPackage} handleChange={handleChange} handleValid={handleValid} errors={errors} findError={findError} requiredFieldErrorStatus={requiredFieldErrorStatus} isEditable={isPackagePublished || hasPackageEnded} />
                            </Card>
                        </Form>
                    </section>

                    {showFooter && (
                        <CreatePackageButtons
                            isEditing={isEditing}
                            isSaving={isSaving}
                            styles={`${!hasPermission ? 'overlay' : ''} `}
                            handleSave={handleSave}
                            handleGoBack={handleGoBack}
                        />
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )
            }
        </>
    )
}