import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DateInputWrapper } from '../../DateInputWrapper';
import { TimeInputWrapper } from '../../TimeInputWrapper';

export default function DateTime({ start, setStart, isEditable }) {
    return (
        <>
            <fieldset className='form-group'>
                <legend>Start Date</legend>
                <Row>
                    <Col>
                        <DateInputWrapper
                            id="package-start"
                            isReadOnly={isEditable}
                            setDate={setStart}
                            selectedDate={start}
                            startDate={start}
                            size="sm"
                        />
                    </Col>
                    <Col>
                        <TimeInputWrapper
                            id="package-start-time"
                            isReadOnly={isEditable}
                            setDate={setStart}
                            selectedDate={start}
                            size="sm"
                        />
                    </Col>
                </Row>
                <p className='mt-4 info-text info-text-md'>
                    <span>The package will no longer be available for purchase by fans 24 hours before the start of the first event in the package. However, tickets within the package can still be sold by assigning them to a fan through the creator panel</span>
                </p>
            </fieldset>
        </>
    )
}