import React from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { DateInputWrapper } from "../../../../components/DateInputWrapper";
import { TimeInputWrapper } from "../../../../components/TimeInputWrapper";

export default function Visibility({
    isEvent,
    visibility,
    setVisibility,
    generalOnsale,
    start,
    isScheduled,
    error,
    errorMsg,
    handleVisibility,
}) {
    return (
        <>
            <div className='mb-3'>
                <h2 className='vjx-pm-subheading'>Visibility</h2>
                <small className='vjx-pm-paragraph'>
                    Your {isEvent ? 'event' : 'package'} will be listed on all Blocktickets channels. Please set the
                    visibility date and time
                </small>
            </div>
            <Form.Check
                type='radio'
                id='visibility-asap'
                name='visibility'
                label='As soon as published'
                checked={!isScheduled}
                onChange={(e) => handleVisibility(!e.target.checked)}
            />
            <Form.Check
                type='radio'
                id='visibility-schedule'
                name='visibility'
                label='Scheduled'
                checked={isScheduled}
                onChange={(e) => handleVisibility(e.target.checked)}
            />
            {isScheduled && (
                <Row>
                    <Col>
                        <DateInputWrapper
                            id='event-visibility-start'
                            setDate={setVisibility}
                            selectedDate={visibility}
                            startDate={visibility}
                            maxDate={generalOnsale || start}
                            size='sm'
                        />
                    </Col>
                    <Col>
                        <TimeInputWrapper
                            id='event-visibility-start-time'
                            setDate={setVisibility}
                            selectedDate={visibility}
                            error={error}
                            errorMsg={errorMsg}
                            size='sm'
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}
