import React from "react"
import { useParams } from "react-router-dom";

import { PackageDetailsWrapper } from "../../components"

export default function PackageDetailsPage() {
    const { uuid } = useParams()
    return (
        <>
            <PackageDetailsWrapper packageId={uuid} />
        </>
    )
}