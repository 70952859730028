import React, { useLayoutEffect, useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import isEqual from "lodash/isEqual";

import LoadingContext from "../../context/Loading/Loading";
import PackageDetailsContext from "../../context/PackageDetails/PackageDetails";

import { useFromPage } from "../../providers/FromPageProvider";
import { GAModalProvider } from "./GAModalProvider/GAModalProvider";
import { AssignToFanProvider } from "../../providers/AssignToFanProvider/AssignToFanProvider";

import { getPackageMap, updateSeatsInPackage } from "../../utilities/api";

import {
    fullHeightContainer,
    removeFullHeightContainer,
} from "../../utilities/helpers";

import { PageLoadingContainer } from "../PageLoadingContainer";
import { PackageMap } from "./PackageMap";
import { Navigation } from "./Navigation";
import { Panel } from "./Panel";
import useSafeAsync from "../../utilities/useSafeAsync";

export default function PackageSeatmapWrapper({ uuid }) {
    const safeSetState = useSafeAsync();
    const navigate = useNavigate();
    const location = useLocation();

    const { isAssigningToFan } = useFromPage();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext);

    const {
        setSelectedFanSeats,
        updatePackage,
        updateCanPublish,
        isPackagePublished,
        isPackageOnsale,
        hasPackageEnded,
        hasLastEventInPackageEnded,
        isPackageSoldout,
    } = useContext(PackageDetailsContext);

    const [eventPackage, setEventPackage] = useState();

    const [data, setData] = useState(null);

    const [background, setBackground] = useState(null);

    // Might not be so important for packagesMap but previously the ds for this was like:
    // { seats: {seatIds}, gaSeats: { sectionId: [seatIds]}}
    const [selectedSeatIds, setSelectedSeatIds] = useState({});
    const [seatsInPackage, setSeatsInPackage] = useState({});
    const [soldMap, setSoldMap] = useState(new Map());
    // These from purchaseLog
    const [sectionToGAPackageSalesMap, setSectionToGAPackageSalesMap] = useState({});
    const [sectionToHighestGAEventAttendanceMap, setSectionToHighestGAEventAttendanceMap] = useState({});

    // set initial seats to check whether button should be disabled
    const [initialState, setInitialState] = useState();

    const [isSaving, setIsSaving] = useState(false);

    const [isMapDisabled, setIsMapDisabled] = useState(false);

    // set html and body to full height
    useLayoutEffect(() => {
        ["html", "body"].forEach((el) =>
            document.querySelector(el).classList.add("full-height")
        );

        return () => {
            ["html", "body"].forEach((el) =>
                document.querySelector(el).classList.remove("full-height")
            );
        };
    }, [location.pathname]);

    useLayoutEffect(() => {
        // hide navbar
        const nav = document.getElementById("navbar");
        nav.style.display = "none";
        // hide event banner
        const banner = document.getElementById("banner");
        banner.style.display = "none";
        // hide sidebar
        const sidebarNav = document.getElementById("sidebarMenu");
        sidebarNav.style.display = "none";

        // modify container
        const container = document.getElementById("main-container");
        fullHeightContainer(container);

        // modify container
        const content = document.getElementById("main-content");
        content.classList.add("pb-0");
        content.classList.add("full-height-wrapper");
        content.classList.remove("spacer-md", "spacer-md--with-banner");

        return () => {
            nav.style.display = "";
            banner.style.display = "";
            sidebarNav.style.display = "";
            removeFullHeightContainer(container);
            content.classList.remove("pb-0");
            content.classList.remove("full-height-wrapper");
            content.classList.add("spacer-md", "spacer-md--with-banner");
        };
    }, []);

    useEffect(() => {
        showLoading();
        loadPackage();
    }, []);

    useEffect(() => {
        console.log("selectedSeatIds: ", selectedSeatIds)
    }, [selectedSeatIds])

    useEffect(() => {
        setSelectedFanSeats(selectedSeatIds);
    }, [selectedSeatIds]);

    // controls if seatmap can be changed
    useEffect(() => {
        if (isAssigningToFan)
            setIsMapDisabled(hasLastEventInPackageEnded || isPackageSoldout);
        else
            setIsMapDisabled(isPackageOnsale || isPackageSoldout || hasPackageEnded);
    }, [
        isAssigningToFan,
        isPackageOnsale,
        isPackageSoldout,
        hasPackageEnded,
        hasLastEventInPackageEnded,
    ]);

    const packageTicketsIntoLookup = (packageTickets) => {
        if (!packageTickets) return { seats: {}, gaSeats: {} };

        const result = {
            seats: {},    // for regular seats: seatId -> seatId
            gaSeats: {}   // for GA seats: sectionId -> [seatId1, seatId2, ...]
        };

        packageTickets.forEach((ticket) => {
            if (ticket.GA) {
                // Handle GA tickets - group by sectionId
                if (!result.gaSeats[ticket.sectionId]) {
                    result.gaSeats[ticket.sectionId] = [];
                }
                result.gaSeats[ticket.sectionId].push(ticket.seatId);
            } else {
                // Handle regular seats
                result.seats[ticket.seatId] = ticket.seatId;
            }
        });

        return result;
    }

    const sectionSoldCountsToState = (sectionSoldCounts) => {
        // Initialize objects to store results
        const packageSalesBySectionMap = {};
        const highestAttendanceBySectionMap = {};

        // Process each record in sectionSoldCounts
        sectionSoldCounts.forEach((record) => {
            const { sectionId, eventUUID, packageUUID, soldCount } = record;

            // Handle records with eventUUID (highest attendance per section)
            if (eventUUID) {
                // Initialize if this section hasn't been seen yet
                if (!highestAttendanceBySectionMap[sectionId]) {
                    highestAttendanceBySectionMap[sectionId] = 0;
                }

                // Update if current soldCount is higher than stored value
                if (soldCount > highestAttendanceBySectionMap[sectionId]) {
                    highestAttendanceBySectionMap[sectionId] = soldCount;
                }
            }

            // Handle records with packageUUID (total package sales per section)
            if (packageUUID) {
                // Initialize if this section hasn't been seen yet
                if (!packageSalesBySectionMap[sectionId]) {
                    packageSalesBySectionMap[sectionId] = 0;
                }

                // Add to the running total for this section
                packageSalesBySectionMap[sectionId] += soldCount;
            }
        });

        // Update both state variables with the new maps
        setSectionToGAPackageSalesMap(packageSalesBySectionMap);
        setSectionToHighestGAEventAttendanceMap(highestAttendanceBySectionMap);
    }

    const loadPackage = async () => {
        try {
            const res = await getPackageMap(uuid);
            const { eventPackage, sectionSoldCounts, soldSeatIds } = res.data;
            console.log("eventPackage: ", eventPackage)
            console.log("sectionSoldCounts: ", sectionSoldCounts)
            console.log("soldSeatIds: ", soldSeatIds)

            safeSetState(() => {
                setEventPackage(eventPackage);
                setData(eventPackage.seatmap?.mapping);
                setBackground(eventPackage.seatmap?.background);

                sectionSoldCountsToState(sectionSoldCounts);
                // Handle selecting and assigning to fan differently
                const soldSeatsForMap = Array.from(soldSeatIds).map(id => [id, true]);
                setSoldMap(new Map(soldSeatsForMap));

                if (!isAssigningToFan) {
                    const selectedSeats = packageTicketsIntoLookup(eventPackage.package_tickets)
                    setSelectedSeatIds(selectedSeats);
                    // Deep copy to prevent reference issues
                    setInitialState(JSON.parse(JSON.stringify(selectedSeats)));
                } else {
                    const seatsInPackage = packageTicketsIntoLookup(eventPackage.package_tickets)
                    console.log("seatsInPackage: ", seatsInPackage)
                    setSeatsInPackage(seatsInPackage);
                }
            });
        } catch (err) {
            console.error(err);
        } finally {
            hideLoading();
        }
    };

    useEffect(() => {
        console.log("-----sodlmap: ", soldMap)
    }, [soldMap])

    const saveSeatSelections = () => {
        console.log("saveSeatSelections....");
        let dataPack = {
            id: eventPackage.id,
            seatsInPackage: selectedSeatIds,
            mapping: data,
            price: eventPackage.price,
        };
        // Since we're sharing the same seatmap for different purposes change the action based on route
        if (isAssigningToFan) {
            setSelectedFanSeats(selectedSeatIds);
        } else {
            setIsSaving(true);
            console.log("updateSeatsInPackage....");
            updateSeatsInPackage(dataPack).then((res) => {
                setIsSaving(false);
                updatePackage(res?.data);
                updateCanPublish(res?.data?.name, res?.data?.package_tickets);
                if (!isSaving) {
                    navigate(`/mypackage/${uuid}/assign-packages`);
                }
            });
        }
    };

    const handleGoBack = () => {
        navigate("..");
    };

    const clearSelectedSeats = () => {
        setSelectedSeatIds({});
    };

    const checkDisabled = () => {
        return isEqual(initialState, selectedSeatIds);
    };

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style='without-sidebar' />
            ) : (
                <>
                    <AssignToFanProvider>
                        <Navigation
                            eventPackage={eventPackage}
                            isMapDisabled={isMapDisabled}
                            isPackagePublished={isPackagePublished}
                            hasPackageEnded={hasPackageEnded}
                            selectedSeatIds={selectedSeatIds}
                            saveSeatSelections={saveSeatSelections}
                            handleGoBack={handleGoBack}
                            isDisabled={checkDisabled()}
                            isSaving={isSaving}
                        />
                        {isAssigningToFan && (
                            <>
                                {selectedSeatIds && Object.keys(selectedSeatIds).length > 0 && (
                                    <Panel
                                        selectedSeatIds={selectedSeatIds}
                                        handleRemove={clearSelectedSeats}
                                    />
                                )}
                            </>
                        )}
                        <GAModalProvider
                            seatsInPackage={seatsInPackage}
                            setSelectedSeatIds={setSelectedSeatIds}
                            isAssigningToFan={isAssigningToFan}
                            sectionToGAPackageSalesMap={sectionToGAPackageSalesMap}
                            sectionToHighestGAEventAttendanceMap={sectionToHighestGAEventAttendanceMap}
                        >
                            {data && background && (
                                <PackageMap
                                    data={data}
                                    background={background}
                                    seatsInPackage={seatsInPackage}
                                    selectedSeatIds={selectedSeatIds}
                                    setSelectedSeatIds={setSelectedSeatIds}
                                    isMapDisabled={isMapDisabled}
                                    isAssigningToFan={isAssigningToFan}
                                    soldMap={soldMap}
                                />
                            )}
                        </GAModalProvider>
                    </AssignToFanProvider>
                </>
            )}
        </>
    );
}
