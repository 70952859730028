import React from 'react';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import { NewMapCard } from '../../NewMapCard';
import { ExistingTemplateCard } from '../../ExistingTemplateCard';

export default function ChooseMap({ handleClick }) {

    return (
        <Card body className='card--light'>
            <div className="card-body-heading--lg text-center">
                <Card.Title as="h5" className='card-title-lg'>Create tickets for this event</Card.Title>
                <Card.Subtitle as="h6">Choose how you want to create your tickets</Card.Subtitle>
            </div>
            <Stack direction="horizontal" gap={5} className='justify-content-center'>
                <NewMapCard handleClick={handleClick} />
                {/* <ExistingTemplateCard handleClick={handleClick} /> */}
            </Stack>
        </Card>
    );
}
