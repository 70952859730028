import { useParams, useSearchParams } from 'react-router-dom';

import { OffersWrapper } from "../../components";

export default function OffersPage() {

    const { uuid } = useParams()

    // get query from URL
    const [searchParams] = useSearchParams();

    const id = searchParams.get("id");

    return (
        <OffersWrapper eventId={uuid} id={id} />
    )
}