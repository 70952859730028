import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import EventDetailsContext from '../../context/EventDetails/EventDetails'
import { BannerProvider } from '../../providers/BannerProvider/BannerProvider';

import { getNowInTimezone, getTimezoneDate } from './../../utilities/helpers';
import { getEvent } from '../../utilities/api';

import { PageLoadingContainer, Sidenav, EventBanner } from '../../components';

export default function MyEventPage() {

    const { uuid } = useParams()

    const [windowSize, setWindowSize] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const [event, setEvent] = useState()

    const [eventContext, setEventContext] = useState()

    const [eventStart, setEventStart] = useState(null);

    const [eventVisibility, setEventVisibility] = useState(null);

    const [initialEventVisibility, setInitialEventVisibility] = useState(null);

    const [generalOnsale, setGeneralOnsale] = useState(null);

    const [standardAdmissionOfferHasInventory, setStandardAdmissionOfferHasInventory] = useState(false)

    const [canPublish, setCanPublish] = useState(false)

    const [isEventPublishing, setIsEventPublishing] = useState(false)

    const [isEventPublished, setIsEventPublished] = useState(false)

    const [isEventOnsale, setIsEventOnsale] = useState(false)

    const [isEventSoldout, setIsEventSoldout] = useState(false)

    const [hasEventEnded, setHasEventEnded] = useState(false)

    const [isGAOnlyEvent, setIsGAOnlyEvent] = useState(false)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            const el = document.querySelector("html")
            // Set window width/height to state
            setWindowSize(el.clientWidth);
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    })

    useLayoutEffect(() => {
        const el = document.querySelector("#banner")
        if (el) el.style.width = `${windowSize}px`
    }, [windowSize])

    useEffect(() => {
        setIsLoading(true)
        getEvent(uuid)
            .then((res) => {
                setEvent(res?.data)
                setIsLoading(false);
            }).catch((err) => {
                console.error(err)
                setIsLoading(false)
            })
    }, [uuid])

    useEffect(() => {
        // set state for event details context
        updateEvent(event)
        setEventStart(event?.start)
        const eventVisibility = event?.eventVisibility;
        setEventVisibility(eventVisibility ? eventVisibility : null)
        setInitialEventVisibility(eventVisibility ? eventVisibility : null)
        updateIsEventSoldout(event)
        updateHasEventEnded(event)
        updateIsEventPublishing(event)
        updateIsEventPublished(event)
        setGeneralOnsale(event?.generalOnsale)
        // check if all "seats" are assigned to price levels 
        // compare the seats in event pricing levels to the "seats" in seatmap 
        if (event?.offers && event?.offers.length > 0 && (event?.pricingLevels && event?.seatmap)) {
            let arr = []
            let pricingLevels = Object.values(event?.pricingLevels)
            let keys = Object.keys(pricingLevels)

            for (let key of keys) {
                let pricing = pricingLevels[key]
                let seats = pricing.seats;
                let gaSeats = Object.values(pricing?.gaSeats ?? {})?.flatMap(seats => seats) ?? []
                arr.push({ seats: [...seats, ...gaSeats] })
            }

            let seats = []

            seats.push({ seats: Object.keys(event?.seatmap?.mapping?.seats) })

            // get GA "seats"
            Object.values(event?.seatmap?.mapping?.sections).forEach((section) => {
                if (section?.spots) {
                    seats.push({ seats: section?.spots })
                }
            })

            const totalSeats = seats?.flatMap(arr => arr.seats)
            const allPriceLevelSeats = arr?.flatMap(level => level.seats)
            const standardAdmissionOfferHasInventory = allPriceLevelSeats?.length == totalSeats?.length
            updateCanPublish(event?.image?.id, standardAdmissionOfferHasInventory)
            setStandardAdmissionOfferHasInventory(standardAdmissionOfferHasInventory)
        }
        updateIsEventOnsale(event)

        setIsGAOnlyEvent(event?.seatmap ? event?.seatmap?.ga_only : false)
    }, [event])

    const updateEvent = (event) => {
        setEventContext(event)
    }

    // can publish when event is published or event is onsale or event settings are filled out and standard admission has completed inventory
    const updateCanPublish = (image = eventContext?.image?.id, hasInventory = standardAdmissionOfferHasInventory) => {
        setCanPublish(((eventContext?.status === 'published' || eventContext?.status === 'on_sale') || (Boolean(image && hasInventory))))
    }

    const getActiveAvailabilityPeriod = (offers) => {
        let activePeriods = [];
        offers?.map(offer => {
            activePeriods = [...activePeriods, ...offer?.availability?.filter(period => period.active)]
        })
        return activePeriods;
    }

    // switching automatically from published to onsale 
    // event is onsale when event is onsale or general onsale is same or before now or has at least one offer onsale 
    // have to check general onsale too because the event offers object does not update right away when general onsale changes (changing it from event details)
    const updateIsEventOnsale = (event = eventContext) => {
        const activePeriods = getActiveAvailabilityPeriod(event?.offers);

        // update only if package if onsale or published or ended
        if (event && event?.status === 'published' || event?.status === 'on_sale' || event?.status === 'complete') {
            // false -> event is sold out 
            // false -> event has ended
            // true -> event is onsale 
            // true -> general onsale is same or before now 
            // true -> at least one offer is onsale 
            setIsEventOnsale((!event?.soldOut && event?.status !== 'complete') ? (event?.status === 'on_sale' || getTimezoneDate(event.generalOnsale, event.timezone).isSameOrBefore(getNowInTimezone(event.timezone)) || activePeriods.some(period => getTimezoneDate(period.starts, event.timezone).isSameOrBefore(getNowInTimezone(event.timezone)))) : false);
            // if event is onsale or not onsale, it is published to prevent from publishing again
            setIsEventPublished(true)
            setIsEventPublishing(false)
        }
    }

    // switching automatically from publishing to published
    // event is publishing when event status is publishing and when visibility date is same or before now (flag is not set if event is scheduled)
    const updateIsEventPublishing = (event = eventContext) => {
        // update only if canPublish and event is not onsale and event is not published
        if (event && canPublish && event?.status !== 'on_sale' && event?.status !== 'published' && event?.eventVisibility) {
            // false -> event has ended
            // true -> event is publishing and event visibility is same or before now (so isPublishing is not true when event is scheduled)
            setIsEventPublishing(event?.status !== 'complete' ? (event?.status === 'publishing' && getTimezoneDate(event?.eventVisibility, event?.timezone).isSameOrBefore(getNowInTimezone(event?.timezone))) : false);
        }
    }

    // switching automatically from scheduled or publishing to published
    // event is published when event published or event visibility date is same or before now 
    const updateIsEventPublished = (event = eventContext) => {
        // update only if canPublish and event is not onsale and event is not publishing 
        if (event && canPublish && event?.status !== 'on_sale' && event?.status !== 'publishing' && event?.eventVisibility) {
            // false -> event has ended
            // true -> event is published
            // true -> visibility date is same or before now 
            setIsEventPublished(event?.status !== 'complete' ? (event?.status === 'published' || getTimezoneDate(event?.eventVisibility, event?.timezone).isSameOrBefore(getNowInTimezone(event?.timezone))) : false);
            setIsEventPublishing(false)
        }
    }

    // switching automatically from onsale to soldout 
    // event is soldout when event is soldout or has not ended
    const updateIsEventSoldout = (event = eventContext) => {
        // update only if event is onsale or ended
        if (event && ((event?.status === 'on_sale' || event?.status === 'complete'))) {
            // true -> event is soldout 
            // false -> event has ended 
            setIsEventSoldout(event?.status !== 'complete' && event?.soldout)
        }
    }

    // switching automatically from onsale to complete
    // event has ended when event has ended or end is same or before now  
    const updateHasEventEnded = (event = eventContext) => {
        // update only is event is onsale or ended
        if (event && (event?.status === 'on_sale' || event?.status === 'complete')) {
            const now = getNowInTimezone(event.timezone);
            const endTime = getTimezoneDate(event.end, event.timezone);

            // true -> event has ended 
            // true -> end time is same or before now  
            setHasEventEnded(event?.status === 'complete' || endTime.isSameOrBefore(now));
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidebar" />
            ) : (
                <>
                    <EventDetailsContext.Provider value={{
                        event: eventContext,
                        updateEvent,
                        isGAOnlyEvent,
                        setIsGAOnlyEvent,
                        eventStart,
                        storeEventStart: setEventStart,
                        eventVisibility,
                        storeEventVisibility: setEventVisibility,
                        initialEventVisibility,
                        updateInitialEventVisibility: setInitialEventVisibility,
                        generalOnsale,
                        storeGeneralOnsale: setGeneralOnsale,
                        standardAdmissionOfferHasInventory,
                        setStandardAdmissionOfferHasInventory,
                        canPublish,
                        updateCanPublish,
                        isEventPublishing,
                        updateIsEventPublishing,
                        isEventPublished,
                        updateIsEventPublished,
                        isEventOnsale,
                        updateIsEventOnsale,
                        isEventSoldout,
                        updateIsEventSoldout,
                        hasEventEnded,
                        updateHasEventEnded,
                    }}>
                        <BannerProvider>
                            <EventBanner eventId={uuid} event={event} />
                        </BannerProvider>
                        <Sidenav />
                        <div className='spacer-md spacer-md--with-banner' id="main-content">
                            <Outlet />
                        </div>
                    </EventDetailsContext.Provider>
                </>
            )}
        </>
    )
}