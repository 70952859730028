import React, { useEffect, useState, useContext } from 'react';
import io from 'socket.io-client';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import PackageDetailsContext from '../../context/PackageDetails/PackageDetails';

import { getPackage, getPackageStats, updatePackageVisibility } from "../../utilities/api";

import useSafeAsync from '../../utilities/useSafeAsync';

import { checkPermission } from '../../utilities/helpers';

import { Cards } from './Cards';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function PackageDashboardWrapper({ packageId }) {
    const safeSetState = useSafeAsync();
    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { isPackagePublished, updateIsPackageOnsale, updateIsPackagePublished, updatePackage, updateIsPackageSoldout, updateHasPackageEnded, hasPackageEnded } = useContext(PackageDetailsContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [isVisible, setIsVisible] = useState(true)

    const [eventPackage, setEventPackage] = useState()

    const [stats, setStats] = useState()

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 4));

    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        loadPackage();

        const socket = io(process.env.REACT_APP_AUTH);

        socket.on("connect", () => {
            socket.on(`newOrder:${packageId}`, (data) => {
                loadPackage()
            })
        });
    }, [])

    const loadPackage = async () => {
        try {
            const packageData = await getPackage(packageId);

            safeSetState(() => {
                setEventPackage(packageData.data);
                setIsVisible(packageData.data?.isVisible);
            });

            const statsData = await getPackageStats(packageId);

            safeSetState(() => {
                setStats(statsData?.data);
                // context updates
                updateIsPackageOnsale();
                updateHasPackageEnded();
                updateIsPackageSoldout(statsData.data);
                updateIsPackagePublished();
            });
        } catch (err) {
            console.error(err);
        } finally {
            hideLoading();
        }
    }

    const handleChange = (_, isVisible) => {
        setIsVisible(isVisible)
        // update context with changed isVisible 
        updatePackage({ ...eventPackage, isVisible })
        // update isVisible in db
        updatePackageVisibility({
            data: {
                id: eventPackage?.uuid,
                isVisible
            }
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <div className="position-relative">
                        <div className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                            <header className="section-header section-heading">
                                <h1>Dashboard</h1>
                            </header>
                            <Cards eventPackage={eventPackage} stats={stats} isVisible={isVisible} handleChange={handleChange} isDisabled={!isPackagePublished || hasPackageEnded} />
                        </div>
                        {!hasPermission && (
                            <NoPermissionsContainer />
                        )}
                    </div>
                </>
            )}
        </>
    );
}
