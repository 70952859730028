import React from 'react';

import { DateRangePicker } from 'react-dates'

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Spinner } from '../../LoadingContainer/Spinner';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export default function ExportModal({ show, opts, isSameAsInitial, dateRange, handleRange, startDate, endDate, handleDates, focusedInput, setFocusedInput, alert, isSaving, handleSave, handleClose }) {

    return (
        <Modal show={show} onHide={handleClose} backdrop={'static'} centered animation={false} fullscreen="md-down">
            <Modal.Body>
                <div className='modal-body-heading'>
                    <h1 className="modal-body-heading-title">Export report</h1>
                    <h2 className='subtitle subtitle--dark'>Choose the date range and report you wish to export.</h2>
                </div>
                {alert.show &&
                    <>
                        <Alert variant={alert.variant} className="mb-5">
                            <p>{alert.message}</p>
                        </Alert>
                    </>
                }
                <Form>
                    {opts?.map((option, idx) => (
                        <Form.Check
                            key={idx}
                            type='radio'
                            checked={dateRange === option.value}
                            name='date-range-radio'
                            id={`date-range-radio-${idx}`}
                            label={option.label}
                            onChange={handleRange}
                            value={option.value}
                        />
                    ))}
                    {dateRange === 'custom' && (
                        <DateRangePicker
                            startDateId="startDate"
                            endDateId="endDate"
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={({ startDate, endDate }) => { handleDates(startDate, endDate) }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => { setFocusedInput(focusedInput) }}
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={true}
                            daySize={32}
                            keepOpenOnDateSelect={true}
                            verticalSpacing={35}
                        />
                    )}
                </Form>
                <Stack direction="horizontal" className='btn-group-flex'>
                    <Button size="lg" variant="outline-light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button size="lg" className="btn-width btn-width-sm" onClick={handleSave} disabled={!dateRange || !startDate || !endDate || isSameAsInitial || isSaving}>
                        {isSaving ? (
                            <Spinner />
                        ) : 'Export'
                        }
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
