import React, { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export default function CreateOfferModal({ show, offerType, handleClose, handleCreate }) {

    const [choice, setChoice] = useState(offerType)

    useEffect(() => {
        if (!offerType) {
            setChoice(offerType)
        }
    }, [offerType])

    return (
        <Modal className="modal--lg" centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static">
            <Modal.Body>
                <div className="modal-body-heading">
                    <h1 className='modal-body-heading-title'>Create offer</h1>
                    <small className='subtitle'>What type of offer would you like to create?</small>
                </div>
                <Form.Check type='checkbox' className="form-check-display" id="create-new-offer-radio">
                    <Form.Check.Input
                        name="create-new"
                        type='radio'
                        value="new"
                        checked={choice === 'new'}
                        onChange={(e) => { setChoice(e.target.value) }} />
                    <div>
                        <Form.Check.Label id="create-new-offer-radio">Create new offer</Form.Check.Label>
                        <small className='form-check-text'>
                            Create a new offer that either pulls from Open Inventory or Exclusive Inventory
                        </small>
                    </div>
                </Form.Check>
                <Form.Check type='checkbox' className="form-check-display" id="create-specialty-offer-radio">
                    <Form.Check.Input
                        name="create-specialty"
                        type='radio'
                        value="specialty"
                        checked={choice === 'specialty'}
                        onChange={(e) => { setChoice(e.target.value) }} />
                    <div>
                        <Form.Check.Label id="create-specialty-offer-radio">Create specialty offer</Form.Check.Label>
                        <small className='form-check-text'>
                            Create an offer that is associated to an existing open offer
                        </small>
                    </div>
                </Form.Check>
                <Stack className='btn-group-flex'>
                    <Button size="lg" disabled={!choice} onClick={() => handleCreate(choice)}>Create</Button>
                    <Button variant="outline-light" size="lg" onClick={handleClose}>Close</Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
