import React, { useEffect, useState } from "react";

import { formatNumber } from "../../../../../../utilities/helpers";

import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import { Item } from "../Item";
import { RevenueCard } from "../RevenueCard";

export default function MoveSeatsModal({
    show,
    id,
    sumRevenue,
    sumNewRevenues,
    tab,
    selectedSeatIds,
    destinationId,
    handleClose,
    handleSubmit,
    getObjectTotalSeatCount,
    offers,
    kills,
    holds,
    priceLevels,
    unassignedSeats,
}) {
    const [toObject, setToObject] = useState(null);
    const [fromObjs, setFromObjs] = useState([]);

    const setMovingSeatFrom = () => {
        if (tab === "scaling") {
            scalingMovingSeatsFrom();
        } else {
            inventoryMovingSeatsFrom();
        }
    };

    const setMovingSeatsTo = () => {
        const toObjectSet = new Set();
        if (tab === "scaling") {
            processScalingSource(destinationId, toObjectSet);
        } else if (destinationId) {
            const [sourceType, sourceMainId, sourceCategoryId] =
                destinationId.split("-");
            processInventorySource(
                sourceType,
                sourceMainId,
                sourceCategoryId,
                toObjectSet
            );
        }

        const toObject = toObjectSet.size > 0 ? Array.from(toObjectSet)[0] : null;

        setToObject(toObject);
    };

    // Extracted process function for inventory moving
    const processInventorySource = (
        sourceType,
        sourceMainId,
        sourceCategoryId,
        inventoriesBeingMovedFrom
    ) => {
        let sourceObj;
        switch (sourceType) {
            case "o": // Offers
                sourceObj = offers[sourceMainId];
                break;
            case "h": // Holds
                sourceObj = sourceCategoryId
                    ? holds[sourceMainId].categories[sourceCategoryId]
                    : holds[sourceMainId];
                break;
            case "k": // Kills
                sourceObj = sourceCategoryId
                    ? kills[sourceMainId].categories[sourceCategoryId]
                    : kills[sourceMainId];
                break;
            default:
                console.error(`Unknown source type: ${sourceType}`);
                return;
        }
        if (sourceObj) {
            inventoriesBeingMovedFrom.add(sourceObj);
        }
    };

    // Extracted process function for scaling moving
    const processScalingSource = (sourcePriceLevelId, scalingBeingMovedFrom) => {
        if (sourcePriceLevelId === "unassigned") {
            // Moving from unassignedSeats, which only ever has one entry
            const firstUnassignedEntry = Object.values(unassignedSeats)[0];
            if (firstUnassignedEntry) {
                scalingBeingMovedFrom.add(firstUnassignedEntry);
            }
        } else {
            const pricingLevel = priceLevels[sourcePriceLevelId];
            if (pricingLevel) {
                scalingBeingMovedFrom.add(pricingLevel);
            }
        }
    };

    // Refactored inventoryMovingSeatsFrom function
    const inventoryMovingSeatsFrom = () => {
        const inventoriesBeingMovedFrom = new Set();

        if (selectedSeatIds.seats && selectedSeatIds.seats.length > 0) {
            Object.entries(selectedSeatIds.seats).forEach(([sourceId, seats]) => {
                const [sourceType, sourceMainId, sourceCategoryId] =
                    sourceId.split("-");
                processInventorySource(
                    sourceType,
                    sourceMainId,
                    sourceCategoryId,
                    inventoriesBeingMovedFrom
                );
            });
        }

        if (selectedSeatIds.gaSeats) {
            Object.entries(selectedSeatIds.gaSeats).forEach(
                ([sectionId, sourceToSeats]) => {
                    Object.entries(sourceToSeats).forEach(
                        ([inventorySourceId, gaSeats]) => {
                            if (gaSeats.length > 0) {
                                const [sourceType, sourceMainId, sourceCategoryId] =
                                    inventorySourceId.split("-");
                                processInventorySource(
                                    sourceType,
                                    sourceMainId,
                                    sourceCategoryId,
                                    inventoriesBeingMovedFrom
                                );
                            }
                        }
                    );
                }
            );
        }

        setFromObjs(Array.from(inventoriesBeingMovedFrom));
    };

    // Refactored scalingMovingSeatsFrom function
    const scalingMovingSeatsFrom = () => {
        const scalingBeingMovedFrom = new Set();

        if (selectedSeatIds?.seats) {
            Object.entries(selectedSeatIds.seats).forEach(
                ([sourcePriceLevelId, seats]) => {
                    processScalingSource(sourcePriceLevelId, scalingBeingMovedFrom);
                }
            );
        }

        if (selectedSeatIds?.gaSeats) {
            Object.entries(selectedSeatIds.gaSeats).forEach(
                ([sectionId, sourceToSeats]) => {
                    Object.entries(sourceToSeats).forEach(([sourcePLId, seats]) => {
                        processScalingSource(sourcePLId, scalingBeingMovedFrom);
                    });
                }
            );
        }

        setFromObjs(Array.from(scalingBeingMovedFrom));
    };

    const getTotalNewSeats = () => {
        // Calculate total seats in the destination object
        const toObjectTotalSeats = getObjectTotalSeatCount(toObject);

        // Calculate total seats being moved from source objects
        const totalSeatsBeingMoved = getTotalSeatsBeingMoved();

        // Sum up the total new seats
        const totalNewSeats = toObjectTotalSeats + totalSeatsBeingMoved;

        return totalNewSeats;
    };

    const getTotalSeatsBeingMoved = () => {
        let totalSeats = 0;

        if (selectedSeatIds?.gaSeats) {
            Object.values(selectedSeatIds.gaSeats).forEach(section => {
                Object.values(section).forEach(fromId => {
                    totalSeats += fromId.length;
                });
            });
        }

        if (selectedSeatIds?.seats) {
            Object.values(selectedSeatIds.seats).forEach(fromId => {
                totalSeats += fromId?.length || 0;
            });
        }

        return totalSeats;
    };

    useEffect(() => {
        setMovingSeatFrom();
        setMovingSeatsTo();
    }, [destinationId, selectedSeatIds, tab]);

    return (
        <Modal
            show={show}
            animation={false}
            onHide={handleClose}
            centered
            backdrop='static'
            className='modal-sm'
        >
            <Modal.Body>
                <div className='modal-body-heading modal-body-heading-sm'>
                    <h1 className='modal-body-heading-title'>
                        {!id ? "Move" : "Remove"} seats
                    </h1>
                </div>
                <div className='split-row split-row-lg mb-3'>
                    <span>You are about to move:</span>
                    <span className='num-seats num-seats-lg'>
                        {formatNumber(getTotalSeatsBeingMoved())}
                    </span>
                </div>
                <div className='list'>
                    <Stack gap={5} as='ul'>
                        {fromObjs?.length > 0 && (
                            <li>
                                <p className='small fw-medium mb-1'>From</p>
                                <Stack as='ul' gap={2}>
                                    {fromObjs.map((obj, idx) => (
                                        <li key={idx}>
                                            <Item
                                                el={obj}
                                                size='lg'
                                                weight='thin'
                                                getObjectTotalSeatCount={getObjectTotalSeatCount}
                                            />
                                        </li>
                                    ))}
                                </Stack>
                            </li>
                        )}
                        {toObject && (
                            <li>
                                <p className='small fw-medium mb-1'>To</p>
                                <Item
                                    el={toObject}
                                    size='lg'
                                    weight='thin'
                                    getObjectTotalSeatCount={getObjectTotalSeatCount}
                                />
                            </li>
                        )}
                    </Stack>
                </div>
                <div className='seperator seperator-sm split-row split-row-lg'>
                    <span className='normal'>Total new seats</span>
                    <span className='num-seats-lg'>
                        {formatNumber(getTotalNewSeats())}
                    </span>
                </div>
                {/* {toObj && isScaling && (
          <div className='mt-3'>
            <RevenueCard
              level={!id ? toObj : fromObjs?.map((fromObj) => fromObj)}
              fromObjs={fromObjs?.map((fromObj) => fromObj)}
              seatsToMove={seatsToMove}
              remove={Boolean(id)}
              sumRevenue={sumRevenue}
              sumNewRevenues={sumNewRevenues}
            />
          </div>
        )} */}
                <Stack direction='horizontal' className='btn-group-flex'>
                    <Button variant='outline-light' size='lg' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button size='lg' onClick={handleSubmit}>
                        {!id ? "Apply" : "Apply & Delete"}
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
