import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../../LoadingContainer/Spinner';

export default function ReleaseTicketsModal({ show, isReleasing, alert, handleClose, handleRelease }) {

    return (
        <Modal className="delete-modal" centered animation={false} fullscreen="md-down" show={show} onHide={handleClose} backdrop="static">
            <Modal.Body>
                {alert.show &&
                    <>
                        <Alert variant={alert.variant} className="mb-4">
                            <p>{alert.message}</p>
                        </Alert>
                    </>
                }
                <div className="modal-body-heading">
                    <h1 className="modal-body-heading-title">Are you sure you want to release tickets?</h1>
                    <p>Releasing tickets will remove the tickets from the fans' accounts and make them available for sale to the general public. This action is final and cannot be undone. Please confirm all details before proceeding.</p>
                </div>
                <Stack className="btn-group-flex">
                    <Button variant="outline-light" size="lg" className='btn-width text-danger' onClick={handleRelease} disabled={isReleasing}>
                        {isReleasing ? (
                            <Spinner variant="dark" />
                        ) :
                            "Release Tickets"
                        }
                    </Button>
                    <Button size="lg" onClick={handleClose}>
                        Cancel
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}
