import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { DoorsOpen } from './DoorsOpen';
import { EventVisibility } from '../../../EventVisibility';
import { GeneralOnsale } from '../../../GeneralOnsale';

const type = (eventAction, timezone, eventStart, doorsOpenOffset, handleDoorsOpen, eventVisibility, setEventVisibility, generalOnsale, setGeneralOnsale, generalOnsaleEnd, error, errorMsg) => {
    switch (eventAction) {
        case 'doorsTime':
            return <DoorsOpen doorsOpenOffset={doorsOpenOffset} handleChange={handleDoorsOpen} />;

        case 'visibility':
            return <EventVisibility timezone={timezone} eventVisibility={eventVisibility} setEventVisibility={setEventVisibility} generalOnsale={generalOnsale} eventStart={eventStart} error={error} errorMsg={errorMsg} />;

        case 'onsale':
            return <GeneralOnsale
                timezone={timezone} generalOnsale={generalOnsale} setGeneralOnsale={setGeneralOnsale} generalOnsaleEnd={generalOnsaleEnd} eventVisibility={eventVisibility} eventStart={eventStart} error={error} errorMsg={errorMsg} />;
        default:
            return;
    }
};
export default function EventModal({ show, setShow, eventAction, isDisabled, timezone, eventStart, doorsOpenOffset, handleDoorsOpen, eventVisibility, setEventVisibility, generalOnsale, setGeneralOnsale, generalOnsaleEnd, error, errorMsg, handleSubmit, handleCancel }) {

    return (
        <>
            <Modal show={show} animation={false} onHide={() => setShow(false)} className='modal-lg' centered backdrop='static'>
                <Modal.Body>
                    {type(eventAction, timezone, eventStart, doorsOpenOffset, handleDoorsOpen, eventVisibility, setEventVisibility, generalOnsale, setGeneralOnsale, generalOnsaleEnd, error, errorMsg)}
                    <Stack direction="horizontal" className='btn-group-flex'
                    >
                        <Button variant="outline-light"
                            size="lg"
                            onClick={() => handleCancel(eventAction)}>Cancel</Button>
                        <Button
                            disabled={isDisabled || error}
                            size="lg"
                            onClick={() => handleSubmit(eventAction)}
                        >
                            Apply
                        </Button>
                    </Stack>
                </Modal.Body>
            </Modal>



        </>
    );
}
