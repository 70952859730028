import React from "react"
import { useParams } from "react-router-dom";

import { FromPageProvider } from "../../providers/FromPageProvider";

import { PackageSeatmapWrapper } from "../../components/PackageSeatmapWrapper";

export default function PackageSeatmapPage() {
    const { uuid } = useParams()

    return (
        <FromPageProvider>
            <PackageSeatmapWrapper uuid={uuid} />
        </FromPageProvider>
    )
}