import React from "react";

import Stack from "react-bootstrap/Stack";

import { AddButton } from "../../AddButton";
import { InventoryItem } from "./InventoryItem";
import { ItemName } from "../../../../../../ItemName";

export default function ListItem({
    canEdit,
    title,
    data,
    handleRemove,
    handleAdd,
    getObjectTotalSeatCount,
    isDroppableInSelected
}) {
    return (
        <>
            <div className='heading--flex-space-between mb-2'>
                <span className='fw-semi-bold'>{title}</span>
                {handleAdd && (
                    <AddButton
                        entity={title.toLowerCase().replace("s", "")}
                        onClick={handleAdd}
                        isDisabled={!canEdit}
                    />
                )}
            </div>
            <Stack as='ul'>
                <>
                    {Object.values(data).map((el, index) => (
                        <li key={index}>
                            <InventoryItem
                                data={data}
                                index={`${title.toLowerCase().charAt(0)}-${el.id}`}
                                entity={title.toLowerCase().replace("s", "")}
                                el={el}
                                canAdd={canEdit || Boolean(el.categories)}
                                canRemove={canEdit || Boolean(el.categories)}
                                handleRemove={handleRemove}
                                handleAdd={handleAdd}
                                getObjectTotalSeatCount={getObjectTotalSeatCount}
                                isDroppableInSelected={isDroppableInSelected}
                            />
                            {el.categories && Object.keys(el.categories)?.length > 0 && (
                                <ul>
                                    {Object.values(el.categories).map((cat, idx) => (
                                        <li key={idx}>
                                            <InventoryItem
                                                index={`${title.toLowerCase().charAt(0)}-${el.id}-${cat.id
                                                    }`}
                                                el={cat}
                                                data={data}
                                                isSubItem={true}
                                                canRemove={canEdit}
                                                handleRemove={handleRemove}
                                                getObjectTotalSeatCount={getObjectTotalSeatCount}
                                                isDroppableInSelected={isDroppableInSelected}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {el.subOffers && (
                                <Stack as='ul' gap={2} className='my-1'>
                                    <li>
                                        <ItemName
                                            el={{ color: el?.color, name: "Standard Admission" }}
                                            isSubItem={true}
                                            weight='thin'
                                        />
                                    </li>
                                    {el.subOffers?.map((offer, idx) => (
                                        <li key={idx}>
                                            <ItemName el={offer} isSubItem={true} weight='thin' />
                                        </li>
                                    ))}
                                </Stack>
                            )}
                        </li>
                    ))}
                </>
            </Stack>
        </>
    );
}
